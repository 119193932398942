import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { ThemeProvider } from "@material-ui/styles";
import _ from 'lodash';
import PaymentStep from './components/PaymentStep';
import "./styles.scss"

import styled from 'styled-components';

const theme = {
  palette: {
    primary: {
      light: "#fff",
      main: "#acaac2",
      dark: "#fff",
      contrastText: "#fff",
    },
  },
};

const StyledModal = styled(Modal)`
    transform: unset;
    position: relative;
    top: 64px;
    left: unset;
    bottom: 64px;
`


const DonationDialog = ({open, handleClose, eventgoer, sendEmail, mobile, onClose, organizer, stage}) => {
    const [step, setStep] = useState(1);
    const [eventgoerContact, setEventgoerContact] = useState()

    // const setSaleId = () => {
    //     return;
    // }

    const renderPaymentStep = () => (
        <PaymentStep
          eventgoer={eventgoer}
          onEventgoersContactChange={(data) => setEventgoerContact({...data})}
          onSubmit={sendEmail}
          mobile={mobile}
          onClose={onClose}
          // setSaleId={setSaleId}
          // organizer={organizer}
          stage={stage}
          />
      );

    const renderContent = () => {
        switch (step) {
            case 1:
                return renderPaymentStep();
            default:
                return null;
        }
    }

  return (
    <ThemeProvider theme={(outerTheme) => _.merge(outerTheme, theme)}>
      <StyledModal
        className={`Modal DonationDialog DonationDialog-step${step}`}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={false}
        isOpen={open}
      >
        {renderContent()}
      </StyledModal>
    </ThemeProvider>
  );
};

export default DonationDialog;
