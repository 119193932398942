export const toggleHidePost = (
  db,
  demoEventfeed,
  channelsOrder,
  currentChannel,
  channelsIndexes,
  postObjects,
  idx,
  setPostObjects
) => {
  if (!postObjects[idx].notify) {
    db.collection("eventfeeds")
      .doc(demoEventfeed)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const channelIndex = channelsIndexes.findIndex(
            (channel) => channel === currentChannel
          );
          const channelIndex2 = channelsIndexes[channelIndex];
  
          let newPostObjects = doc.data().eventfeedUnpub[channelIndex2].posts;
  
          const newPostObject = {
            ...postObjects[idx],
            ...{ hide: !postObjects[idx].hide },
          };
  
          newPostObjects.splice(idx, 1, newPostObject);
  
          setPostObjects(newPostObjects);
  
          let update = {};
          update[`eventfeedUnpub.${channelIndex2}.posts`] = newPostObjects;
          db.collection("eventfeeds").doc(demoEventfeed).update(update);
        }
      });
  } else {
    console.log("Already a notify post.");
  }
};
