import {
  setCurrentStage,
  updateUser,
  setEventgoer,
  setSession,
  setEventgoersContact,
  setStar,
  setCurrentTimeOffset
} from "../../redux/app-redux";

export default {
  setCurrentStage,
  updateUser,
  setEventgoer,
  setSession,
  setEventgoersContact,
  setStar,
  setCurrentTimeOffset
};
