import React, { useState, useEffect } from "react";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from "styled-components";

const UnselectedContainer = styled.div`
  background: linear-gradient(
    224.72deg,
    rgba(67, 42, 116, 0.85) -15.01%,
    #432a74 7.46%,
    #292663 57.63%,
    #160f3e 90.27%,
    #000000 111.87%
  );
  color: #EFEFEF;
`;

const SelectedContainer = styled(UnselectedContainer)`
  background: linear-gradient(
    224.72deg,
    rgba(255, 255, 255, 0) -15.01%,
    #fbae17 6.14%,
    #ef4e23 57.63%,
    #c3161c 90.27%,
    #432a74 111.87%
  ); ;
`;

const DonorChannelContainer = ({
  channelName,
  channelIdx,
  recentlyUpdated,
  isCurrentChannel,
  changeChannel,
}) => {
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    if (recentlyUpdated) {
      setHighlighted(true);
      setTimeout(() => setHighlighted(false), 120000);
    } else {
      setHighlighted(false);
    }
  }, [recentlyUpdated]);

  // const tabletAndUp = useMediaQuery(theme => theme.breakpoints.up('tablet'));

  return (
    <div
      className='EventfeedScreen-channel rounded'
      onClick={() => !isCurrentChannel && changeChannel(channelIdx)}
    >
      {isCurrentChannel ? (
        <SelectedContainer
          className='EventfeedScreen-channelButton rounded'
          style={{
            border: highlighted && "3px solid #EF4E23",
            cursor: !isCurrentChannel && "pointer",
          }}
        >
          {channelName}
        </SelectedContainer>
      ) : (
        <UnselectedContainer
          className='EventfeedScreen-channelButton rounded'
          style={{
            border: highlighted && "3px solid #EF4E23",
            cursor: !isCurrentChannel && "pointer",
          }}
        >
          {channelName}
        </UnselectedContainer>
      )}

    </div>
  );
};

export default DonorChannelContainer;
