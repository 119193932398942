import firebase from "firebase/app";

export const saveLeaderboards = (db, id) => {
    console.log('saveLeaderboards')
    const donationsRef = db.firestore.collection("leaderboards").doc(id).collection("donations").doc();
    donationsRef.set({
      amount: -1,
      donationDate: firebase.firestore.FieldValue.serverTimestamp(),
      name: "placeholder",
      showAmount: false,
      showName: false
    }).then(() => console.log('donations doc created')).catch(e => console.error(e))
  }