import React, { useRef, useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
// import { v4 as uuidv4 } from "uuid";

const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KalturaPlayer = ({
  video,
  className,
  playing,
  muted,
  volume,
  onStart,
  onLoaded,
  onEnded,
  toggleBystanderMode,
  inBystanderMode,
  setIsPlaying,
}) => {
  const kalturaPlayer = useRef();
  const wasPlaying = useRef(false);
  const hasKalturaScript = useRef(false);
  // const htmlVideo = useRef()

  const [hasPlayer, setHasPlayer] = useState(false);
  const [buffering, setBuffering] = useState(false);
  // const [firstPlay, setFirstPlay] = useState(false);

  const { id, entryId } = video;

  const handleSeek = useCallback((newCurrentTime) => {
    const live = kalturaPlayer.current.isLive();
    // console.log("handleSeek isLive?", entryId, live);
    if (!live) {
      // console.log('seek', newCurrentTime)
      kalturaPlayer.current.currentTime = newCurrentTime;
    }
  }, []);

  const eventHandler = useCallback(
    (event) => {
      const { type } = event;
      const live = kalturaPlayer.current.isLive();
      switch (type) {
        case "playerstatechanged":
          const newState = event.payload.newState;
          // console.log("New state type is: " + newState.type, entryId);
          break;
        case "pause":
          // console.log("pause", entryId);
          // toggleBystanderMode()
          break;
        case "canplay":
          // console.log("can play", entryId);
          // playing && kalturaPlayer.current.paused && kalturaPlayer.current.play();
          break;
        case "firstplay":
          // console.log("first play", entryId);
          setIsPlaying(true);
          // setFirstPlay(true);
          // !hasPlayed && setHasPlayed(true)
          break;
        case "playing":
          // console.log("playing", entryId);
          setBuffering(false);
          break;
        case "seeked":
          // console.log("seeked", entryId);
          setBuffering(false);
          break;
        case "mutechange":
          // console.log("mute change", event);
          break;
        case "volumechange":
          // console.log('html video volume change', event)
          // console.log('video tag volume', htmlVideo.current.volume)
          break;
        case "waiting":
          // console.log("waiting");
          //Delete this - for testing only
          // const { Error } = window.KalturaPlayer.core;
          // const myError = new Error(
          //   Error.Severity.CRITICAL,
          //   Error.Category.NETWORK,
          //   Error.Code.HTTP_ERROR,
          //   { name: "my fake error" }
          // );

          // const { FakeEvent } = window.KalturaPlayer.core;
          // kalturaPlayer.current.dispatchEvent(
          //   new FakeEvent(kalturaPlayer.current.Event.Error, myError)
          // );
          //  end delete
          setBuffering(true);
          break;
        case "stalled":
          // console.log("stalled", entryId);
          break;
        case "ended":
          // console.log("ended, is live?", live);
          if (!live) {
            //loop video
            // console.log("hit play on ended");
            // kalturaPlayer.current.currentTime = 0;
            kalturaPlayer.current.play();
            return;
          }
          // onEnded();
          break;
        case "error":
          // console.log('hit error')
          // console.log("error", event);
          break;
        case "timeupdate":
          // console.log('time update', entryId)
          break;
        default:
          // console.log("eventhandler default", event);
          return;
      }
    },
    [setIsPlaying]
  );

  const errorHandler = (e) => {
    // console.log("errorHandler", e);
  };

  const setupKaltura = useCallback(async () => {
    try {
      kalturaPlayer.current = window.KalturaPlayer.setup({
        targetId: `kaltura-player-${id}`,
        provider: {
          partnerId: "3135653",
          uiConfId: "48190933",
        },
        playback: {
          autoplay: playing,
          allowMutedAutoPlay: true,
        },
        ui: {
          disable: true,
        },
        log: {
          level: "DEBUG",
        },
      });

      // document.getElementById(`kaltura-player-${video.id}`).querySelector('video').setAttribute('disablePictureInPicture', '')

      kalturaPlayer.current.addEventListener(
        kalturaPlayer.current.Event.PLAYER_STATE_CHANGED,
        eventHandler
      );

      const coreEventListeners = [
        "CAN_PLAY",
        "PAUSE",
        "FIRST_PLAY",
        "PLAYING",
        "ENDED",
        "STALLED",
        "SEEKED",
        "WAITING",
        "MUTE_CHANGE",
        "ERROR",
        "TIME_UPDATE",
      ];

      coreEventListeners.forEach((listener) => {
        kalturaPlayer.current.addEventListener(
          kalturaPlayer.current.Event.Core[listener],
          eventHandler
        );
      });

      kalturaPlayer.current.addEventListener(
        kalturaPlayer.current.Event.Error,
        errorHandler
      );

      // console.log("Event.Error", kalturaPlayer.current.Event.Error);
      // console.log("Event.ERROR", kalturaPlayer.current.Event.ERROR);

      try {
        await kalturaPlayer.current.loadMedia({ entryId });
        const videoTag = kalturaPlayer.current.getVideoElement();
        if (videoTag.nodeName === "VIDEO")
          videoTag.setAttribute("disablePictureInPicture", "");
        //for debugging only - DELETE
        videoTag.setAttribute("muted", "");
        videoTag.addEventListener("volumechange", eventHandler);
        // htmlVideo.current = videoTag
        //end deletion
      } catch (e) {
        console.error("loadMedia error", e);
      }

      try {
        kalturaPlayer.current.load();
        await kalturaPlayer.current.ready();

        setHasPlayer(true);
        onLoaded();
        // console.log("isLive", kalturaPlayer.current.isLive());
        kalturaPlayer.current.muted = muted;
        onStart(handleSeek);
      } catch (e) {
        // console.log("error", e);
      }
    } catch (e) {
      console.error(e.message);
    }
  }, [
    entryId,
    eventHandler,
    handleSeek,
    id,
    muted,
    onLoaded,
    onStart,
    playing,
  ]);

  //instantiate and play Kaltura player
  useEffect(() => {
    // console.log("initialize useEffect");

    let isCancelled = false;

    if (!hasKalturaScript.current) {
      try {
        var head = document.head || document.getElementsByTagName("head")[0];
        var scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.src =
          "https://cdnapisec.kaltura.com/p/3135653/embedPlaykitJs/uiconf_id/48190933";
        scriptElement.onload = () => {
          hasKalturaScript.current = true;
          !isCancelled && setupKaltura();
        };
        scriptElement.onerror = (e) => {
          console.warn("Failed to load kaltura player bundler script.", e);
          // console.log("error", e);
        };
        head.appendChild(scriptElement);
      } catch (e) {
        console.warn("Failed to load kaltura player bundler script.", e);
        // console.log("error");
      }
    }

    // call on unmount
    return () => {
      isCancelled = true;
    };
  }, [setupKaltura]);

  //handleMuting
  useEffect(() => {
    // console.log("muted useEffect");
    // console.log("muted prop", muted)
    if (kalturaPlayer.current) {
      // console.log('was muted', kalturaPlayer.current.muted)
      kalturaPlayer.current.muted = muted;
      // console.log('newly muted?', kalturaPlayer.current.muted)
      // console.log('volume', kalturaPlayer.current.volume)
    }
  }, [muted]);

  //handle segment start and stop
  useEffect(() => {
    if (kalturaPlayer.current) {
      // console.log("playing useEffect", kalturaPlayer);
      if (hasPlayer && wasPlaying.current && !playing) {
        kalturaPlayer.current.pause();
      } else if (hasPlayer && !wasPlaying.current && playing) {
        kalturaPlayer.current.play();
      }
    }
    wasPlaying.current = playing;
    // console.log("wasPlaying set to", wasPlaying.current, entryId);
  }, [entryId, hasPlayer, playing]);

  // const handlePlay = () => {
  //   if (kalturaPlayer.current.isLive()) kalturaPlayer.seekToLiveEdge();
  //   kalturaPlayer.current.play();
  //   // setPlaying(true)
  // };

  // const handlePause = () => {
  //   kalturaPlayer.current.pause();
  //   // setPlaying(false)
  // };

  // const handleMute = () => {
  //   kalturaPlayer.current.muted = !kalturaPlayer.current.muted;
  // };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        ref={kalturaPlayer}
        className={[className, "KalturaPlayer"].join(" ")}
        id={`kaltura-player-${id}`}
        style={{ width: "100%", height: "100%" }}
      />

      {!inBystanderMode && buffering ? (
        <StyledOverlay>
          <CircularProgress color='secondary' thickness={4} size={56} />
        </StyledOverlay>
      ) : null}
    </div>
  );
};

export default KalturaPlayer;
