import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import moment from "moment";
import * as queryString from "query-string";
import handleUserPresence from "./handleUserPresence";
import API from "../api";
import auth from "../auth";

const sessionPersistenceWindow = 7;

class EventgoerInitializer {
  constructor(firebase) {
    this.API = API(firebase);
    this.auth = auth(firebase);
    this.firebase = firebase;
  }

  initEventgoer = async (stageId) => {
    // logic for eventgoer and session
    let currentUser = firebase.auth().currentUser;
    const prevSessionSnapshot = await this.API.getSession(currentUser.uid);
    let prevSession = prevSessionSnapshot.data();
    let didReset;

    // gets eventgoer from preexisting session or creates new one
    const eventgoer = await this._getEventgoer(
      prevSession,
      currentUser,
      stageId
    );

    // when user is accessing private link
    if (this._eventgoerIdQuery()) {
      !eventgoer.confirmed && this._markEventgoerConfirmed(eventgoer);
    }

    // if confirmed eventgoer used without private link
    if (eventgoer.confirmed && !this._eventgoerIdQuery()) {
      await this._resetCurrentUser(currentUser);
      window.location.reload();
    }

    if (prevSession) {
      prevSession = await this._updateSessionIfRefreshed(prevSession);

      // reset firebase auth currentUser (unless viewing same stage in another tab)
      didReset = await this._resetIfSessionInvalid(
        prevSession,
        currentUser,
        stageId
      );
    }

    if (didReset || !prevSession) {
      // create a new Session doc
      currentUser = firebase.auth().currentUser;
      await this._createSession(currentUser.uid, eventgoer, stageId);
    }

    handleUserPresence(
      currentUser.uid,
      eventgoer.id,
      stageId,
      this.firebase
    );

    // update eventgoer with current sessionId and unlink old eventgoer session
    await this.API.linkEventgoerSessionId(eventgoer, currentUser.uid);

    // return eventgoer with updated session id
    const updatedEventgoerSnap = await this.API.getEventgoerById(eventgoer.id);
    return updatedEventgoerSnap.data();
  };

  unSetListeners = () => {
    this.firebase.database.ref(".info/connected").off("value");
  };

  // HELPERS ===============================================

  _markEventgoerConfirmed = (eventgoer) => {
    this.API.updateEventgoer(eventgoer.id, { confirmed: true });
  };

  _updateSessionIfRefreshed = async (sessionData) => {
    if (this._isLoadingAfterTempDisconnect(sessionData)) {
      await this._reinstateSession(sessionData);

      const updatedSessionSnapshot = await this.API.getSession(sessionData.id);

      return updatedSessionSnapshot.data();
    } else {
      return sessionData;
    }
  };

  _isLoadingAfterTempDisconnect = (sessionData) => {
    const sessionMarkedOffline = !sessionData.isOnline;
    const secondsSinceUpdate = moment
      .duration(moment().diff(sessionData.updatedAt.toDate()))
      .asSeconds();
    return (
      sessionMarkedOffline && secondsSinceUpdate < sessionPersistenceWindow
    );
  };

  _reinstateSession = (sessionData) => {
    return this.API.updateSession(sessionData.id, {
      isOnline: true,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  _resetIfSessionInvalid = async (sessionData, currentUser, stageId) => {
    const sessionMatchesStage = sessionData.stageId === stageId;
    const sessionMarkedOffline = !sessionData.isOnline;

    if (!sessionMatchesStage || sessionMarkedOffline) {
      // when user is NOT opening a new kontomo tab with a session open
      return this._resetCurrentUser(currentUser);
    }
  };

  _getEventgoer = async (prevSession, currentUser, stageId) => {
    const prevSessionId = (prevSession || {}).id;
    const query = this._eventgoerIdQuery();

    if (query) {
      const eventgoerSnap = await this.API.getEventgoerById(query);
      const eventgoer = eventgoerSnap.data();

      if (!eventgoer) this._redirectToOriginPath(); // invalid query

      return eventgoer;
    }

    if (prevSessionId && prevSession.stageId === stageId) {
      // has valid stored session
      const eventgoerSnap = await this._getEventgoerBySessionId(prevSessionId);

      if (eventgoerSnap && eventgoerSnap.data()) return eventgoerSnap.data();
    }

    return this._createEventgoer(currentUser.uid, stageId);
  };

  _createSession = (sessionId, eventgoer, stageId) => {
    // a cloud function - disableEventgoerSessions - will fire on session create
    // to disable all other sessions with the same eventgoerId
    return this.API.createSession(sessionId, eventgoer.id, stageId);
  };

  _resetCurrentUser = async (currentUser) => {
    await this.auth.logout(currentUser.uid); //	deactivate old session
    const authData = await this.auth.login(); // create new session
    return authData.user;
  };

  _redirectToOriginPath = () => {
    window.location.replace(window.location.origin);
  };

  _createEventgoer = async (sessionId, stageId) => {
    return await this.API.createEventgoer(sessionId, stageId);
  };

  _getEventgoerBySessionId = async (sessionId, stageId) => {
    // query eventgoer by stage additionally in case
    // user visits other stage with same session
    return this.API.getEventgoerBySessionId(sessionId, stageId);
  };

  _eventgoerIdQuery = () => {
    return queryString.parse(window.location.search).myUniquePassID;
  };
}

export default EventgoerInitializer;
