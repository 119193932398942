import React from "react";

import "./styles.scss";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import StageScreen from "../screens/StageScreen";
import BackstageScreen from "../screens/BackstageScreen";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import UnsubscribeScreen from "../screens/UnsubscribeScreen.js";
import TrapScreen from "../screens/Trap/TrapScreen.js";

import { ThemeProvider, StylesProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import { isMobileOnly } from "../utils/deviceDetection";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,

      tablet: 768,
    },
  },
  palette: {
    primary: {
      light: "#432a74",
      main: "#292663",
      dark: "#160f3e",
      contrastText: "#fff",
    },
    secondary: {
      light: " #fbae17",
      main: "#ef4e23",
      dark: "#c3161c",
      contrastText: "#000",
    },
  },
});

function App() {
  // Set prerenderReady to false before public stage and eventfeed are loaded
  window.prerenderReady = false;

  const tabletAndUp = useMediaQuery(theme.breakpoints.up("tablet"));
  const isMobile = isMobileOnly();

  const viewingBackstage = () => {

    //delete this - for testing backstage only
    // return true;
    //end delete

    return window.location.host.includes("backstage");
  };

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className='App'>
          <Router>
            <Switch>
              <Route
                path='/unsubscribe'
                render={(routeProps) => <UnsubscribeScreen {...routeProps} />}
              ></Route>
              <Route
                path='/404'
                render={(routeProps) => <TrapScreen {...routeProps} />}
              ></Route>
              <Route
                path='/logout'
                render={(routeProps) => <TrapScreen {...routeProps} />}
              ></Route>

              {viewingBackstage() ? (
                <BackstageScreen mobile={!tabletAndUp} />
              ) : (
                <StageScreen mobile={isMobile || !tabletAndUp} />
              )}
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
