import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import API from "./api";

class Auth {
  constructor(firebase) {
    this.API = API(firebase);
  }

  logout = (sessionId) => {
    return this.API.updateSession(
      sessionId,
      {
        isOnline: false,
        isLinked: false,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
    )
      .then(() => {
        return firebase.auth().signOut();
      })
      .catch(console.error);
  };

  login = () => {
    return firebase.auth().signInAnonymously();
  };
}

export default (firebase) => new Auth(firebase)
