import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "../../services/Firebase";
import Stage from "./Stage";
import stageActions from "./actions";

const mapStateToProps = ({ currentStage, user, eventgoer, eventgoersContact, star }, { firebase, isBackstage }) => {
  const stage = currentStage;

  return {
    eventgoer,
    eventgoersContact,
    star,
    stage,
    stageRef: stage && firebase.firestore.collection("stages").doc(stage.id),
    starRef: isBackstage && firebase.firestore.collection("stars").doc(user.uid),
    sessionRef: (sessionId) =>
      firebase.firestore.collection("sessions").doc(sessionId),
    currentUser: user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(stageActions, dispatch) };
};

const enhance = compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Stage);
