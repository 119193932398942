import { reorder } from "../shared/Reorder";

export const reorderPosts = (
  db,
  eventfeed,
  postIds,
  data,
  oldIndex,
  newIndex,
  currentChannel,
  channels,
  channelsOrder,
  channelsIndexes,
  setPostObjects
) => {
  let newData = {};

  const shiftedData = reorder([...data], data[oldIndex], newIndex);
  newData.data = shiftedData;

  const shiftedPostIds = reorder([...postIds], postIds[oldIndex], newIndex);
  newData.postIds = shiftedPostIds;

  const channelIndex = channelsIndexes.findIndex(
    (channel) => channel === currentChannel
  );
  const channelIndex2 = channelsIndexes[channelIndex];

  db.collection("eventfeeds")
    .doc(eventfeed)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        const postObjects = doc.data().eventfeedUnpub[channelIndex2].posts;

        const shiftedPostObjects = reorder(
          [...postObjects],
          postObjects[oldIndex],
          newIndex
        );

        let update = {};
        update[`eventfeedUnpub.${channelIndex2}.posts`] = shiftedPostObjects;
        db.collection("eventfeeds").doc(eventfeed).update(update);

        setPostObjects(shiftedPostObjects);
      }
    });

  return newData;
};