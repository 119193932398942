import firebase from "firebase/app";

export const createNewPost = (
  db,
  eventfeed,
  post,
  currentChannel,
  channels,
  setChannels,
  channelsOrder,
  channelsIndexes,
  setPostIds,
  postIds,
  setPostObjects,
  postObjects
) => {
  db.collection("posts")
    .add(post.data)
    .then(function (docRef) {
      const channelIndex = channelsIndexes.findIndex(
        (channel) => channel === currentChannel
      );
      const channelIndex2 = channelsIndexes[channelIndex];
      let newPostUpdate = {};
      newPostUpdate[
        `eventfeedUnpub.${channelIndex2}.posts`
      ] = firebase.firestore.FieldValue.arrayUnion({ id: docRef.id });

      setPostObjects([...postObjects, {id: docRef.id}]);

      db.collection("eventfeeds").doc(eventfeed).update(newPostUpdate);

      let update = { ...channels };
      update[currentChannel] = [...update[currentChannel], docRef.id];
      setChannels(update);

      setPostIds([...postIds, docRef.id]);

      return docRef.id;
    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
    });
};
