import React from "react";
import LazyLoad from "react-lazyload";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  skeleton: {
    marginBottom: "18px",
    // transform: 'scale(1,1)',
    borderRadius: '17px'
  },
});

const LazyPost = ({ children, containerRef }) => {

  const classes = useStyles();

  return (
    <LazyLoad
      scrollContainer={containerRef}
      placeholder={
        <Skeleton
          className={classes.skeleton}
          animation='wave'
          variant='rect'
          height='262px'
          width='266px'
        />
      }
      height={262}
      offset={280}
      throttle={300}
      overflow
      once
    >
      {children}
    </LazyLoad>
  );
};

export default LazyPost;
