export const deleteChannel = (
  channels,
  currentChannel,
  setChannels,
  channelsOrder,
  setChannelsOrder,
  db,
  demoEventfeed,
  setChannelsIndexes,
  channelsIndexes,
  changeChannel
) => {
  let channelUpdate = channels;
  delete channelUpdate[currentChannel];
  setChannels(channelUpdate);

  const orderRef = db.collection("eventfeeds").doc(demoEventfeed);
  orderRef
    .get()
    .then(function (doc) {
      if (doc.exists) {

        const channelIndex = channelsIndexes.findIndex(
          (index) => index === currentChannel
        );

        const channelOrder = doc.data().eventfeedUnpub.channelOrder;

        const editedChannelOrder = [
          ...channelOrder.slice(0, channelIndex),
          ...channelOrder.slice(channelIndex + 1),
        ];

        const newChannelIndexes = [
          ...channelsIndexes.slice(0, channelIndex),
          ...channelsIndexes.slice(channelIndex + 1),
        ];
        setChannelsIndexes(newChannelIndexes);

        const newOrder = [
          ...channelsOrder.slice(0, channelIndex),
          ...channelsOrder.slice(channelIndex + 1),
        ];
        setChannelsOrder(newOrder);

        orderRef.update({
          "eventfeedUnpub.channelOrder": editedChannelOrder,
        });
      } else {
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });

  changeChannel(0);
  localStorage.setItem("localStorageChannel", 0);
};
