import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import logger from 'redux-logger';
import * as _ from "lodash";

const initialState = {
  savedPlaybills: {},
  discreet: false,
  user: { userName: "New User", type: "goer", passCode: "" },
  eventgoer: null,
  session: null,
  star: {},
  starAccount: {},
  device: "",
  currentPlaybill: { order: [], sections: [] },
  currentPlaybillIndex: null,
  storedPlaybills: [],
  starredPlaybills: [],
  stages: [],
  collaborators: {},
  currentTimeOffset: 0,
  donationsOn: false,
  eventfeedChannel: 0,
  donorsChannel: null,
  preSalesEnabled: false
};

const reducer = (state = initialState, action) => {
  //this line is problematic. reducer needs to be rewritten to only use {...state} and not {...newState}
  let newState = _.merge({}, state);
  switch (action.type) {
    case "resetStore":
      return initialState;
    case "toggleDiscreet":
      return {...state, discreet: !state.discreet};
    case "setEventgoer":
      return { ...state, eventgoer: action.value };
    case "setEventgoersContact":
      return { ...state, eventgoersContact: action.value };
    case "setSession":
      return { ...state, session: action.value };
    case "setUser":
      return { ...state, user: action.value };
    case "updateUser":
      return { ...state, user: { ...state.user, ...action.value } };
    case "setDevice":
      return { ...state, device: action.value };
    case "setCurrentPlaybillOrder":
      const currentPlaybill = state.currentPlaybill
      return {...state, currentPlaybill: {...currentPlaybill, order: action.value}};
    case "setNewOrder":
      newState.savedPlaybills = _.merge({}, newState.savedPlaybills, {
        [action.value.event]: action.value,
      });
      newState.currentPlaybillIndex = action.value.event;

      return newState;
    case "setCurrentPlaybillSection":
      newState.currentPlaybill.sections = [
        ...newState.currentPlaybill.sections,
        action.value,
      ];
      return newState;
    case "setNewSection":
      if (!newState.savedPlaybills[action.value.event].sections) {
        newState.savedPlaybills[action.value.event].sections = [
          action.value.section,
        ];
      } else {
        newState.savedPlaybills[action.value.event].sections = [
          ...newState.savedPlaybills[action.value.event].sections,
          action.value.section,
        ];
      }
      return newState;
    case "sendNewSection":
      // Section
      if (!newState.savedPlaybills[action.value.event].sections) {
        newState.savedPlaybills[action.value.event].sections = [
          action.value.newSection,
        ];
        newState.currentPlaybill.sections = [action.value.newSection];
      } else {
        newState.savedPlaybills[action.value.event].sections = [
          ...newState.savedPlaybills[action.value.event].sections,
          action.value.newSection,
        ];
        newState.currentPlaybill.sections = [
          ...newState.savedPlaybills[action.value.event].sections,
          action.value.newSection,
        ];
      }
      // Order
      newState.currentPlaybill.order = [
        ...newState.currentPlaybill.order,
        newState.currentPlaybill.order.length,
      ];
      newState.savedPlaybills[action.value.event].order = [
        ...newState.savedPlaybills[action.value.event].order,
        newState.savedPlaybills[action.value.event].order.length,
      ];
      return newState;
    case "setBookmarks":
      newState.storedPlaybills = action.value;
      return newState;
    case "sendNewBookmark":
      newState.storedPlaybills.push(action.value);
      return newState;
    case "deleteBookmark":
      newState.storedPlaybills = newState.storedPlaybills.filter(
        (item) => item !== action.value
      );
      return newState;
    case "setStars":
      newState.starredPlaybills = action.value;
      return newState;
    case "sendNewStar":
      newState.starredPlaybills.push(action.value);
      return newState;
    case "deleteStar":
      newState.starredPlaybills = newState.starredPlaybills.filter(
        (item) => item !== action.value
      );
      return newState;
    case "clearCurrentPlaybill":
      let newPlaybill = { order: [], sections: [] };
      newState.currentPlaybill = newPlaybill;
      return newState;
    case "setStagehall":
      newState.stagehall = action.value;
      return newState;
    case "setCurrentStage":
      newState.currentStage = action.value;
      return newState;
    case "setStage":
      const stage = action.value;
      newState.stages[stage.id] = stage;
      return newState;
    case "setStar":
      return { ...newState, star: action.value };
    case "setStarAccount":
      return { ...newState, starAccount: action.value };
    case "setStarInfoSecure":
      return { ...newState, starInfoSecure: action.value };
    case "setStages":
      const sortedStages = _.sortBy(action.value, ["createdAt"]);
      const stagesById = _.keyBy(sortedStages, "id");
      return { ...newState, stages: stagesById };
    case "setCollaborativeStages":
      const sortedCollabStages = _.sortBy(action.value, ["createdAt"]);
      const collabStagesById = _.keyBy(sortedCollabStages, "id");
      return { ...newState, collaborativeStages: collabStagesById };
    case "setCollaborators":
      // set collaborators by stage id
      const { stageId, collaborators } = action.value;
      newState.collaborators[stageId] = collaborators;
      return newState;
    case "setCurrentTimeOffset":
      return { ...newState, currentTimeOffset: action.value };
    case "setSales":
      return { ...newState, sales: action.value };
    case "setDonationsOn":
      return { ...state, donationsOn: action.value };
    case "setEventfeedChannel":
      return {...state, eventfeedChannel: action.value};
    case "setDonorsChannel":
      return {...state, donorsChannel: action.value};
    case "setPreSalesEnabled":
      return {...state, preSalesEnabled: !state.preSalesEnabled};
    default:
      return state;
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunkMiddleware];

// if (process.env.NODE_ENV === `development`) {
 
//   middlewares.push(logger);
// }


const store = createStore(
  reducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middlewares))
);
export { store };

const resetStore = () => {
  return { type: "resetStore" };
};

const toggleDiscreet = () => {
  return { type: "toggleDiscreet" };
};

const setUser = (name, passCode = "") => {
  return { type: "setUser", value: name };
};

const updateUser = (attrs) => {
  return { type: "updateUser", value: attrs };
};

const setEventgoer = (value) => {
  return { type: "setEventgoer", value };
};

const setEventgoersContact = (value) => {
  return { type: "setEventgoersContact", value };
};

const setSession = (value) => {
  return { type: "setSession", value };
};

const setCurrentPlaybillOrder = (order) => {
  return {
    type: "setCurrentPlaybillOrder",
    value: order,
  };
};

const setNewOrder = (order) => {
  return {
    type: "setNewOrder",
    value: order,
  };
};

const setNewSection = (section) => {
  return {
    type: "setNewSection",
    value: section,
  };
};

const setCurrentPlaybillSection = (section) => {
  return {
    type: "setCurrentPlaybillSection",
    value: section,
  };
};

const clearCurrentPlaybill = () => {
  return { type: "clearCurrentPlaybill" };
};

const setDevice = (token = "") => {
  return { type: "setDevice", value: token };
};

const sendNewSection = (section) => {
  return {
    type: "sendNewSection",
    value: section,
  };
};

const setBookmarks = (bookmarks) => {
  return {
    type: "setBookmarks",
    value: bookmarks,
  };
};

const sendNewBookmark = (bookmark) => {
  return {
    type: "sendNewBookmark",
    value: bookmark,
  };
};

const deleteBookmark = (bookmark) => {
  return {
    type: "deleteBookmark",
    value: bookmark,
  };
};

const setStars = (stars) => {
  return {
    type: "setStars",
    value: stars,
  };
};

const sendNewStar = (star) => {
  return {
    type: "sendNewStar",
    value: star,
  };
};

const deleteStar = (star) => {
  return {
    type: "deleteStar",
    value: star,
  };
};

const setStagehall = (stagehall) => {
  return {
    type: "setStagehall",
    value: stagehall,
  };
};

const setCurrentStage = (currentStage) => {
  return {
    type: "setCurrentStage",
    value: currentStage,
  };
};

const setStage = (stage) => {
  return {
    type: "setStage",
    value: stage,
  };
};

const setStar = (star) => {
  return {
    type: "setStar",
    value: star,
  };
};

const setStarAccount = (starAccount) => {
  return {
    type: "setStarAccount",
    value: starAccount,
  };
};

const setStarInfoSecure = (starInfoSecure) => {
  return {
    type: "setStarInfoSecure",
    value: starInfoSecure,
  };
};

const setStages = (stages) => {
  return {
    type: "setStages",
    value: stages,
  };
};

const setCollaborativeStages = (collabStages) => {
  return {
    type: "setCollaborativeStages",
    value: collabStages,
  };
};

const setCollaborators = (stageId, collaborators) => {
  return {
    type: "setCollaborators",
    value: { stageId, collaborators },
  };
};

const setCurrentTimeOffset = (currentTime) => {
  return {
    type: "setCurrentTimeOffset",
    value: currentTime
  };
};

const setSales = (sales) => {
  return {
    type: "setSales",
    value: sales
  };
};

const setDonationsOn = (payload) => {
  return {
    type: "setDonationsOn",
    value: payload
  };
};

const setEventfeedChannel = (channel) => {
  return {
    type: "setEventfeedChannel",
    value: channel
  };
};

const setDonorsChannel = (channel) => {
  return {
    type: "setDonorsChannel",
    value: channel
  }
};

const setPreSalesEnabled = () => {
  return {
    type: "setPreSalesEnabled"
  }
}

const selectors = {
  getStages: (state) => _.toArray(state.stages),
  getCollaborativeStages: (state) => _.toArray(state.collaborativeStages),
  getDonorsChannel: (state) => state.donorsChannel,
};

export {
  selectors,
  resetStore,
  toggleDiscreet,
  setEventgoer,
  setEventgoersContact,
  setSession,
  setUser,
  updateUser,
  setCurrentPlaybillOrder,
  setCurrentPlaybillSection,
  clearCurrentPlaybill,
  setNewOrder,
  setNewSection,
  setDevice,
  sendNewSection,
  setBookmarks,
  sendNewBookmark,
  deleteBookmark,
  setStars,
  sendNewStar,
  deleteStar,
  setStagehall,
  setCurrentStage,
  setStage,
  setStar,
  setStarAccount,
  setStarInfoSecure,
  setStages,
  setCollaborativeStages,
  setCollaborators,
  setCurrentTimeOffset,
  setSales,
  setDonationsOn,
  setEventfeedChannel,
  setDonorsChannel,
  setPreSalesEnabled
};
