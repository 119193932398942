import React from "react";
import ReactModal from "react-modal";
import "./styles.scss";

ReactModal.setAppElement("#root");

export default function Modal(props) {
  return (
    <ReactModal
      closeTimeoutMS={200}
      contentLabel="Modal"
      overlayClassName="ReactModal__Overlay"
      {...props}>
      {props.children}
    </ReactModal>
  );
}
