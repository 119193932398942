import React, { Component, memo } from "react";
import cx from "classnames";
import _ from "lodash";
import "./styles.scss";

/*
props.initialHeight is the max height of the initial render in pixels and
corresponds to the height of the .CollapsibleContainer.truncated class
*/

class CollapsibleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOverflowContent: false,
      hasOverflow: false,
      showHighlight: false,
    };
  }

  componentDidMount() {
    this.initialize();
    this.reInitOnImagesLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.reInitOnChildrenUpdate(prevProps);
    this.overflowContentChanged(prevState) && this.handleOverflowToggle();
  }

  initialize = () => {
    const untruncatedHeight = this.getUntruncatedHeight();

    const storedLocalOverflows = localStorage.getItem("storedOverflows");

    let storedOverflows = storedLocalOverflows
      ? JSON.parse(storedLocalOverflows)
      : {};

    let showOverflowContent = false;
    if (storedOverflows[this.props.itemId]) {
      // User has clicked "read more" for this post before
      showOverflowContent = true;
    } else if (
      storedOverflows[this.props.itemProvenance] &&
      !storedOverflows.hasOwnProperty(this.props.itemId)
    ) {
      // User has clicked "read more" for a post that has been updated
      showOverflowContent = true;
      storedOverflows[this.props.itemId] = true;
      localStorage.setItem("storedOverflows", JSON.stringify(storedOverflows));
    }

    if (this.props.recentlyUpdated) {

      const nowDate = new Date();
      const timeElapsed = nowDate - this.props.recentlyUpdated;

      if (timeElapsed < 120000) {
        this.setState( { showHighlight: true } );
        setTimeout(
          () => this.setState({ showHighlight: false }),
          120000 - timeElapsed
        );
      }

    }

    if (this.hasOverflow(untruncatedHeight)) {
      this.setState({
        hasOverflow: true,
        showOverflowContent,
        untruncatedHeight,
      });
    } else {
      this.setState({
        hasOverflow: false,
        showOverflowContent,
        untruncatedHeight: null,
      });
    }
  };

  overflowContentChanged(prevState) {
    return (
      this.state.hasOverflow &&
      prevState.showOverflowContent !== this.state.showOverflowContent
    );
  }

  reInitOnChildrenUpdate = (prevProps) => {
    if (
      !_.isEqual(this.props.children.props, prevProps.children.props) ||
      !_.isEqual(this.props.itemId, prevProps.itemId)
    ) {
      this.initialize();
    }
  };

  reInitOnImagesLoaded = () => {
    const children = Array.from(
      document.querySelectorAll(".CollapsibleContainer img")
    );

    children.forEach((img) => {
      if (img.complete) {
        this.initialize();
      } else {
        img.addEventListener("load", this.initialize);
      }
    });
  };

  getUntruncatedHeight = () => {
    if (this.placeholderEl) {
      return this.placeholderEl.offsetHeight || 0;
    }
  };

  handleOverflowToggle = () => {
    if (this.state.showOverflowContent) {
      this.overlayEl.classList.add("invisible");
      this.fullEl.classList.remove("truncated");
    } else {
      this.overlayEl.classList.remove("invisible");
      this.fullEl.classList.add("truncated");
    }
  };

  hasOverflow = (untruncatedHeight) => {
    return (
      untruncatedHeight > this.props.initialHeight &&
      untruncatedHeight - this.props.initialHeight > 10
    );
  };

  toggleOverflow = () => {
    this.setState({
      showOverflowContent: !this.state.showOverflowContent,
    });
    this.props.onOverflowChange(
      this.props.itemId,
      !this.state.showOverflowContent,
      this.props.itemProvenance
    );
  };

  renderOverflowButton = () => {
    const buttonText = this.state.showOverflowContent
      ? "Read less"
      : "Read more";

    return (
      <div
        onClick={this.toggleOverflow}
        className={"CollapsibleContainer-overflowbutton"}
      >
        {buttonText}
      </div>
    );
  };

  renderOverlay = () => {
    return (
      <div
        ref={(el) => (this.overlayEl = el)}
        className="CollapsibleContainer-overlay"
        onClick={this.toggleOverflow}
      >
        {this.state.hasOverflow && this.renderOverflowButton()}
      </div>
    );
  };

  render() {
    const { className, children } = this.props;
    // allows height to be <= 20 pxls over the limit without truncating container
    const heightAllowance = 20;

    return (
      <div
        className={className + " CollapsibleContainer"}
        style={{
          border: this.state.showHighlight && "3px solid #EF4E23",
        }}
      >
        <div
          ref={(el) => (this.fullEl = el)}
          style={{
            height: this.state.hasOverflow
              ? this.state.untruncatedHeight + heightAllowance
              : "initial",
          }}
          className={cx("CollapsibleContainer-main", {
            truncated: this.state.hasOverflow,
          })}
        >
          {children}
        </div>

        <div
          ref={(el) => (this.placeholderEl = el)}
          className="CollapsibleContainer-placeholder"
        >
          {children}
        </div>
        {this.state.hasOverflow && this.renderOverlay()}
      </div>
    );
  }
}

export default memo(CollapsibleContainer);
