import firebaseApp from "firebase/app";
import "firebase/database";
import "firebase/firestore";

const handleUserPresence = async (
  sessionId,
  eventgoerId,
  stageId,
  firebase
) => {
  const realtimeStatusRef = firebase.database.ref(`stagehalls/${stageId}/status/${sessionId}`);

  // Mark user as online
  realtimeStatusRef.update({
    status: "online",
    startTimestamp: firebaseApp.database.ServerValue.TIMESTAMP,
  });

  // Mark user as offline on disconnect of `stagehalls/${stageId}/status/${sessionId}`
  realtimeStatusRef.onDisconnect().update({
    status: "offline",
    endTimestamp: firebaseApp.database.ServerValue.TIMESTAMP,
  });

  // handle marking users online and offline based on server connection
  const firestoreRef = firebase.firestore.collection("sessions").doc(sessionId);

  const databaseRef = firebase.database.ref(
    `stagehalls/${stageId}/sessions/${sessionId}`
  );

  const databaseSessionSnap = await databaseRef.once("value");
  const databaseSession = databaseSessionSnap.val() || {};

  const dataForRTDB = {
    date: firebaseApp.database.ServerValue.TIMESTAMP,
    id: sessionId,
    stageId,
    eventgoerId,
    ...databaseSession,
    updatedAt: firebaseApp.database.ServerValue.TIMESTAMP,
    isOnline: true,
    isLinked: true,
  };

  const dataForFirestore = {
    id: sessionId,
    eventgoerId,
    stageId,
    updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    date: firebaseApp.firestore.FieldValue.serverTimestamp(),
    isOnline: true,
    isLinked: true,
  };

  return firebase.database
    .ref(".info/connected")
    .on("value", async (snapshot) => {
      if (!snapshot.val()) {
        return firestoreRef.update({
          isOnline: false,
          updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        });
      }

      return await databaseRef
        .onDisconnect()
        .update({
          ...dataForRTDB,
          isOnline: false
        })
        .then(() => {
          firestoreRef
            .set(dataForFirestore, { merge: true })
            .catch(_onFail("createSession firestore set failed"));
          databaseRef
            .set(dataForRTDB)
            .catch(_onFail("createSession RTDB set failed"));
        })
        .catch(_onFail("createSession() - onDisconnect.set failed"));
    });
};

const _onFail = (message) => {
  return (error) => {
    console.error(message, error);
  };
};

export default handleUserPresence;
