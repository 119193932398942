import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Modal from "../../../../components/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import MediaSegmentForm from "../MediaSegmentForm";


const theme = createMuiTheme({
  palette: {
    type: "light"
  }
});

class MediaSegments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newMediaTitle: null,
      newMediaIdentifier: null,
      isSegmentModalOpen: false,
      isMediaModalOpen: false
    };
  }

  addMedia = (segmentId) => {
    const data = {
      // TODO change url key to identifier?
      url: this.state.newMediaIdentifier,
      title: this.state.newMediaTitle
    };

    this.props.addMedia(data, segmentId);

    this.toggleMediaModal(segmentId);
  };

  addMediaSegment = (data) => {
    this.toggleSegmentModal("newSegment");

    return this.props.addMediaSegment(data);
  };

  updateMediaSegment = (data) => {
    this.toggleSegmentModal(data.id);

    return this.props.updateMediaSegment(data);
  };

  destroySegment = (segmentId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this segment?"
    );

    confirmed && this.props.destroyMediaSegment(segmentId);
  };

  openSegmentModal = (focusedSegment) => {
    this.setState({ focusedSegment, isSegmentModalOpen: true })
  }

  toggleMediaModal = (segmentId) => {
    this.setState({
      [`isMediaModalOpen-${segmentId}`]: !this.state[`isMediaModalOpen-${segmentId}`],
    });
  };

  toggleSegmentModal = (segmentId) => {
    this.setState({
      [`isSegmentModalOpen-${segmentId}`]: !this.state[`isSegmentModalOpen-${segmentId}`],
    });
  };

  handleControlledInputChange(key) {
    return (event) => this.setState({ [key]: event.target.value });
  }

  renderAddMediaForm(segment) {
    const isIncomplete =
      !(this.state.newMediaTitle && this.state.newMediaIdentifier) ||
      (this.state.newMediaIdentifier.match(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/) &&
        segment.paywallData
        );

    return (
      <div>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={this.state[`isMediaModalOpen-${segment.id}`]}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <div>
            <TextField
              onChange={this.handleControlledInputChange("newMediaTitle")}
              label="Title"
            />
            <TextField
              onChange={this.handleControlledInputChange("newMediaIdentifier")}
              label="URL/kaltura ID"
            />
            <Button
              color="primary"
              variant="contained"
              disabled={isIncomplete}
              onClick={() => this.addMedia(segment.id)}
            >
              Save
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.toggleMediaModal(segment.id)}
            >
              Cancel
            </Button>
          </div>
        </Modal>

        <Button
          color="primary"
          onClick={() => this.toggleMediaModal(segment.id)}
        >
          Add media
        </Button>
      </div>
    );
  }

  renderMediaTitles = (segment) => {
    return segment.mediaIds.map((id) => {
      return <div key={id}>{this.props.getMedia(id).title}</div>;
    });
  };

  renderSegmentEditModal = (segment) => {
    return (
      <Modal
        isOpen={this.state[`isSegmentModalOpen-${segment.id}`]}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <MediaSegmentForm
          onSubmit={this.updateMediaSegment}
          onClose={() => this.toggleSegmentModal(segment.id)}
          mediaSegment={segment} />
      </Modal>
    )
  }

  renderMediaSegment = (segment) => {
    return (
      <tr key={segment.id}>
        <td>{segment.display.startAt}</td>
        <td>{segment.display.endAt}</td>
        <td>{this.renderMediaTitles(segment)}</td>
        <td>
          {segment.canAddMedia === undefined && <div><CircularProgress /></div>}
          {segment.canAddMedia && this.renderAddMediaForm(segment)}
          <div>
            <Button color="primary" onClick={() => this.toggleSegmentModal(segment.id)}>Edit</Button>
          </div>

          <div>
            <Button color="secondary" onClick={() => this.destroySegment(segment.id)}>Delete</Button>
          </div>

          {this.renderSegmentEditModal(segment)}
        </td>
      </tr>
    );
  };

  renderMediaSegmentList() {
    return this.props.mediaSegments.map(this.renderMediaSegment);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="MediaSegments">
          <h3>Media Segments</h3>
          <table>
            <thead>
              <tr>
                <th>Start</th>
                <th>End</th>
                <th>Media</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>{this.renderMediaSegmentList()}</tbody>
          </table>

          <Modal
            isOpen={this.state["isSegmentModalOpen-newSegment"]}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            <MediaSegmentForm
              onSubmit={this.addMediaSegment}
              onClose={() => this.toggleSegmentModal("newSegment")} />
          </Modal>

          <Button
            color="primary"
            variant="contained"
            onClick={() => this.toggleSegmentModal("newSegment")}
          >
            Add media segment
          </Button>
        </div>
      </ThemeProvider>
    );
  }
}

MediaSegments.propTypes = {
  mediaSegments: PropTypes.array.isRequired,
  getMedia: PropTypes.func.isRequired,
  addMedia: PropTypes.func.isRequired,
  addMediaSegment: PropTypes.func.isRequired,
};

export default MediaSegments;
