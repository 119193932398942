import React, { Component, ReactElement } from "react";
import API from "./api";
import firebase from "firebase/app";
import * as _ from "lodash";
import "firebase/auth";
import TrapScreen from "../Trap/TrapScreen";
import AliasSwitcher from "./components/AliasSwitcher/component";
import HelmetHeaders from "./components/HelmetHeaders";
import EventgoerInitializer from "./services/eventgoerInitializer";
import EventfeedScreen from "../EventfeedScreen/container";
import auth from "./auth";
import Fire from "../../services/Fire";
import Banner, { BannerRight, BannerLeft } from "../../components/Banner";
import Avatar from "../../components/Avatar";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Button from "@material-ui/core/Button";
import { ReactComponent as LeftPickIcon } from "../../assets/icons/Stage/pick-right.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { withTheme } from "@material-ui/core/styles";
import "swiper/swiper.scss";
import "./styles.scss";
import styled from "styled-components";
import { ThemeProvider } from "@material-ui/styles";
import StagehallScreen from "../StagehallScreen";
import Slide from "@material-ui/core/Slide";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import Bowser from "bowser";
// import isIOS from "../../utils/isIOS";
// import isiPadOS from "../../utils/isiPadOS";
import IOSWarning from "./components/IOSWarning/IOSWarning";
import BackstageChat from "../../components/BackstageChat/BackstageChat";
import { saveLeaderboards } from "../../services/Firebase/utils";

// https://code-boxx.com/detect-browser-with-javascript/
// iOS and iPadOS browsers often are built in WebKit
// As a result, it's difficult to determine the browsers
const browser = Bowser.getParser(window.navigator.userAgent);
const isChrome = navigator.userAgent.indexOf("Chrome") > -1;
let isSafari = browser.getBrowserName() === "Safari";
if (isChrome && isSafari) isSafari = false;
const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
if (isFirefox && isSafari) isSafari = false;
// const shouldShowIOSWarning = isiPadOS() && isSafari;
const shouldShowIOSWarning = false;

const theme = {
  palette: {
    type: "dark",
    primary: {
      light: "#432a74",
      main: "#292663",
      dark: "#160f3e",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fbae17",
      main: "#ef4e23",
      dark: "#c3161c",
      contrastText: "#000",
    },
    tertiary: {
      light: "#fdd992",
      main: "#acaac2",
      dark: "#4e496d",
      contrastText: "#000",
    },
  },
};

const StyledStageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 100%;
`;

/**
 * @instance of Fire class
 */

Fire.shared = new Fire();

/**
 * <p>Creates container for HelmetHeaders, Backstage Chat, EventfeedScreen, and StagehallScreen</p>
 * <p>The Stage component is a child of either BackstageScreen or StageScreen. 
 * It attaches firebase listeners for updates to user, auth, session, stage, star, eventgoer, collaborator, eventfeed, and donations.
 * It also listens for changes to window size and screen orientation and renders a view for either mobile or table and up.
 * Handles redirect to "404" page under some conditions.
 * </p>
 * @requires firebase/app
 * @requires @material-ui/core
 */

class Stage extends Component {
  /**
   * Receives props spread in from parent BackstageScreen or StageScreen
   * @param {boolean} isBackstage
   * @param {boolean} mobile
   * @param {object} firebase - received from parent
   * @param {object} routeProps - passed in from ReactRouter
   */
  constructor(props) {
    super(props);

    this.API = API(props.firebase);
    this.auth = auth(props.firebase);

    this.state = {
      selectedEvent: {},
      eventfeed: {},
      isPublishing: false,
      warningOpen: false,
      bookmarkWarningOpen: false,
      stageLoaded: false,
      activeIndex: 0,
      windowHeight: window.innerHeight,
    };
  }

  /**
   *
   * <p>Initialize listeners for screen orientation, window resize.</p>
   * <p>Firebase listeners for user auth and current star. Get time offset from firebase. Set event in component state.</p>
   */

  componentDidMount() {
    this.initScreenOrientationListener();
    (isSafari || isFirefox) && this.initResizeListener();

    if (!this.props.isBackstage) this.initiateAuth();
    if (this.props.isBackstage) this.initStarListener();

    this.setCurrentTimeOffset();
    this.setEvent();
  }

  /**
   * Remove all listeners
   */

  componentWillUnmount() {
    _.isFunction(this.removeStageListener) && this.removeStageListener();
    _.isFunction(this.removeStarListener) && this.removeStarListener();
    _.isFunction(this.removeAuthListener) && this.removeAuthListener();
    _.isFunction(this.removeSessionListener) && this.removeSessionListener();
    _.isFunction(this.removeEventgoerListener) &&
      this.removeEventgoerListener();
    _.isFunction(this.removeEventgoersContactListener) &&
      this.removeEventgoersContactListener();
    _.isFunction(this.removeCollaboratorListener) &&
      this.removeCollaboratorListener();
    _.isFunction(this.removeEventfeedListener) &&
      this.removeEventfeedListener();
    _.isFunction(this.removeDonationsListener) &&
      this.removeDonationsListener();

    this.eventgoerInitializer && this.eventgoerInitializer.unSetListeners();

    Fire.shared.off();
  }

  /**
   * <p>Handle updates to stage component</p>
   * <ul>
   *  <li>update URL for confirmed eventgoer</li>
   *  <li>initialize stage listener if user logged in for first time or no stage loaded yet</li>
   *  <li>handle eventgoer, eventgoerContact, and session listener</li>
   *  <li>initiailize eventfeed and donations listeners</li>
   * </ul>
   *
   *  @param {object} prevProps
   */

  componentDidUpdate(prevProps) {
    const { stage, currentUser, eventgoer, eventgoersContact, isBackstage } =
      this.props;

    const onUserLogIn = currentUser.uid && !prevProps.currentUser.uid;
    const onStageChange = stage && !prevProps.stage;
    const userAndStageLoaded =
      (onUserLogIn && stage) || (onStageChange && currentUser.uid);
    const onEventgoerMarkedConfirmed =
      eventgoer &&
      eventgoer.confirmed &&
      prevProps.eventgoer &&
      !prevProps.eventgoer.confirmed;

    if (onEventgoerMarkedConfirmed) {
      this.replaceLocationWithPrivateLink();
    }

    if (onUserLogIn || (isBackstage && !stage)) {
      this.initStageListener();
    }

    if (userAndStageLoaded && !isBackstage) {
      this.handleEventgoer();
    }

    if (isBackstage && stage) {
      this.initCollaboratorListener();
      if (onStageChange) {
        this.initEventfeedListener();
        this.initDonationsListener();
      }
    }

    if (eventgoer && eventgoer.id) {
      if (!eventgoersContact) {
        this.initEventgoersContactListener(eventgoer);
      }
    }
  }

  /**
   * listen to `orientationchange` events on window and update state
   * @function
   */

  initScreenOrientationListener = () => {
    window.addEventListener("orientationchange", () => {
      const afterOrientationChange = () => {
        this.setState({ windowHeight: window.innerHeight });
        window.removeEventListener("resize", afterOrientationChange);
      };

      window.addEventListener("resize", afterOrientationChange);
    });
  };

  /**
   * listen to `resize` events on window and update state
   * @function
   */

  initResizeListener = () => {
    window.addEventListener("resize", () => {
      this.setState({ windowHeight: window.innerHeight });
    });

    window.addEventListener("scroll", () => {
      if (this.state.windowHeight !== window.innerHeight) {
        this.setState({ windowHeight: window.innerHeight });
      }
    });
  };

  /**
   * redirect to signin page if not collaborator or creator
   * @param {array} snapshots
   * @function
   */

  redirectIfNotCollaborator = (snapshots) => {
    const isCollaborator = snapshots.docs.some((doc) => {
      return doc.data().id === this.props.currentUser.uid;
    });

    if (!isCollaborator && !this.isCreator) {
      this.props.history.push("/signin");
    }
  };

  /**
   * append PassID link to URL
   * @function
   */

  replaceLocationWithPrivateLink = () => {
    const { eventgoer, history } = this.props;
    history.replace({
      search: "?myUniquePassID=" + eventgoer.id,
    });
  };

  /**
   * calls redux action to set time offset
   * @function
   */

  setCurrentTimeOffset = () => {
    // https://firebase.google.com/docs/database/web/offline-capabilities#clock-skew
    this.props.firebase.database
      .ref("/.info/serverTimeOffset")
      .once("value")
      .then(
        (data) => {
          this.props.actions.setCurrentTimeOffset(data.val());
        },
        (err) => {
          return err;
        }
      );
  };

  /**
   * initialize eventgoer, listen for changes to eventgoer and session
   * @function
   */

  handleEventgoer = () => {
    const { firebase, stage } = this.props;

    if (!this.eventgoerInitializer) {
      this.eventgoerInitializer = new EventgoerInitializer(firebase);
      this.eventgoerInitializer.initEventgoer(stage.id).then((eventgoer) => {
        this.initEventgoerListener(eventgoer);
        this.initSessionListener(eventgoer.sessionId);
      });
    }
  };

  /**
   * calls redux setStar action and listens for changes
   * @function
   */

  initStarListener = () => {
    const listener = this.props.starRef.onSnapshot((doc) => {
      this.props.actions.setStar(doc.data());
    });

    this.removeStarListener = this.removeStarListener || listener;
  };

  /**
   *
   * <ul>
   *  <li>sets `warningOpen` state prop for displaying cookie banner</li>
   *  <li>sets `bookmarkWarningOpen` state prop for displaying bookmark banner</li>
   *  <li>listens for updates to eventgoer doc</li>
   * </ul>
   * @param {object} eventgoer
   * @function
   */

  initEventgoerListener = async (eventgoer) => {
    const cookieOK = eventgoer.cookieOK;
    this.setState({ warningOpen: !cookieOK });
    const bookmarkOK = eventgoer.bookmarkOK;
    this.setState({ bookmarkWarningOpen: !bookmarkOK });

    const uniquePassIdParam = window.location.search.match(
      "/?myUniquePassID=" + eventgoer.id
    );
    if (!uniquePassIdParam) {
      this.setState({ bookmarkWarningOpen: false });
    }

    const listener = this.props.firebase.firestore
      .collection("eventgoers")
      .doc(eventgoer.id)
      .onSnapshot((eventgoerSnap) => {
        const data = eventgoerSnap.data();
        this.props.actions.setEventgoer(data);
      });

    this.removeEventgoerListener = this.removeEventgoerListener || listener;
  };

  /**
   * atttaches firebase listener for `eventgoersContact`
   * @param {object} eventgoer
   * @function
   */

  initEventgoersContactListener = async (eventgoer) => {
    const listener = this.props.firebase.firestore
      .collection("eventgoersContact")
      .doc(eventgoer.id)
      .onSnapshot((snap) => {
        this.props.actions.setEventgoersContact(snap.data());
      });

    this.removeEventgoersContactListener =
      this.removeEventgoersContactListener || listener;
  };

  /**
   * attaches firebase listener for stage
   * @function
   */

  initStageListener = async () => {
    const slugParam = this.props.match.params.stageSlug;
    const stageRef = await this.API.getStageRefFromSlug(slugParam);
    const listener = stageRef.onSnapshot(this.onStageUpdate);

    this.removeStageListener = this.removeStageListener || listener;
  };

  /**
   * attaches firebase listener for collaborators
   * @function
   */

  initCollaboratorListener = async () => {
    const listener = this.props.stageRef
      .collection("collaborators")
      .onSnapshot(this.redirectIfNotCollaborator);

    this.removeCollaboratorListener =
      this.removeCollaboratorListener || listener;
  };

  /**
   * attaches firebase listener for `eventfeeds`
   * @function
   */

  initEventfeedListener = async () => {
    const listener = this.props.firebase.firestore
      .collection("eventfeeds")
      .doc(this.props.stage.id)
      .onSnapshot(this.onEventfeedUpdate);

    this.removeEventfeedListener = this.removeEventfeedListener || listener;
  };

  /**
   * attaches firebase listener for `donations` in `leaderboards`
   * @function
   */

  initDonationsListener = async () => {
    const listener = this.props.firebase.firestore
      .collection("leaderboards")
      .doc(this.props.stage.id)
      .collection("donations")
      .onSnapshot(this.onDonationsUpdate);

    this.removeDonationsListener = this.removeLDonationsListener || listener;
  };

  /**
   * checkes user starId against stage starId
   * @type {boolean}
   */

  get isOwner() {
    return (
      this.props.isBackstage && this.props.star.id === this.props.stage.starId
    );
  }

  /**
   * Updates `eventfeed` in state. Redirects to 404 page if stage is not published.
   * @param {*} doc - firebase doc
   * @function
   */

  onEventfeedUpdate = (doc) => {
    if (!doc.data().eventfeedPub && !this.props.isBackstage) {
      // Redirect to 404
      window.location.href = window.location.origin + "/404";
    }

    this.setState({ eventfeed: doc.data() });
  };

  /**
   * sets `donations` array in state
   * @param {array} collection - array of firebase docs
   * @function
   */

  onDonationsUpdate = (collection) => {
    let donationsArray = [];

    collection.docs.forEach((doc) => {
      if (doc.data().amount > 0) {
        donationsArray.push(doc.data());
      }
    });

    this.setState({ donations: donationsArray });
  };

  /**
   * checks currentUser against stage starId
   * @type {boolean}
   */

  get isCreator() {
    return this.props.currentUser.uid === this.props.stage.starId;
  }

  /**
   * updates legacy stages and calls redux action setCurrentStage
   * @function
   * @param {*} doc - firebase doc
   */

  onStageUpdate = (doc) => {
    this.updateLegacyStage(doc.data().id);

    return this.props.actions.setCurrentStage(doc.data());
  };

  /**
   * checks user auth and listens for updates
   * @returns {Promise} resolves if user successful signs in anonymously
   * @function
   */

  initiateAuth = () => {
    const listener = firebase
      .auth()
      .onAuthStateChanged(this.onAuthStateChanged);
    this.removeAuthListener = this.removeAuthListener || listener;

    return this.signInAnonymously();
  };

  /**
   * signs user in anonymously with firebase auth()
   * @function
   */

  signInAnonymously() {
    firebase.auth().signInAnonymously().catch(alert);
  }

  /**
   * calls redux action `updateUser`
   * @function
   * @param {object} user
   */

  onAuthStateChanged = (user) => {
    user && this.props.actions.updateUser({ uid: user.uid });
  };

  /**
   * listens to session updates
   * <ul>
   *  <li>logs out and redirects if multiple tabs or browsers open</li>
   *  <li>calls redux action `setSession`</li>
   *  <li>initializes eventfeed and donations listeners</li>
   * </ul>
   * @param {string} sessionId
   * @function
   */

  initSessionListener(sessionId) {
    const listener = this.props
      .sessionRef(sessionId)
      .onSnapshot((sessionSnapshot) => {
        this.logoutWhenMarkedUnlinked(sessionSnapshot.data());
        this.props.actions.setSession(sessionSnapshot.data());
        this.initEventfeedListener();
        this.initDonationsListener();
      });

    this.removeSessionListener = this.removeSessionListener || listener;
  }

  /**
   * logs out session when offline or multiple tabs/browsers are open for same eventgoer
   * @param {object} session
   * @function
   */

  logoutWhenMarkedUnlinked = (session) => {
    // logout if session is marked offline
    // * for cases where multiple tabs are open and the one
    // controlling user presence is closed or
    // another browser initializes the same eventgoer
    if (!session.isLinked) {
      this.auth.logout(session.id);
      // redirect to path without eventgoerId in query
      this.redirectToLogoutPath();
    }
  };

  /**
   * redirect to "/logout"
   * @function
   */

  redirectToLogoutPath = () => {
    window.location.replace(window.location.origin + "/logout");
  };

  /**
   * sets `event-metadata` in Fire instance and sets selectedEvent in state
   * @param {string} eventName
   * @function
   */

  setEvent = (eventName = "Welcome to Kontomo") => {
    Fire.shared.set(`event-metadata/${eventName}`);
    Fire.shared.onCustom((snapshot) => {
      this.setState({ selectedEvent: snapshot });
    });
  };

  /**
   * updates legacy stages with new colletions and properties if not existing
   * <ul>
   *  <li>leaderboards and donations collections</li>
   *  <li>preSalesEnabled</li>
   * </ul>
   * @param {string} stageId
   * @function
   */

  updateLegacyStage = (stageId) => {
    const donationsRef = this.props.firebase.firestore
      .collection("leaderboards")
      .doc(stageId)
      .collection("donations");

    donationsRef.get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        saveLeaderboards(this.props.firebase, stageId);
      }
    });

    const stageRef = this.props.firebase.firestore
      .collection("stages")
      .doc(stageId);

    stageRef.get().then((querySnapshot) => {
      if (querySnapshot.data().preSalesEnabled === undefined) {
        stageRef.update({
          preSalesEnabled: false,
        });
      }
    });
  };

  /**
   * renders TrapScreen
   * @function
   * @returns {ReactElement}
   */

  renderLoading() {
    return (
      <div>
        <TrapScreen />
      </div>
    );
  }

  /**
   * returns true if eventfeed empty and not backstage, no currentUser id, no stage, or no eventgoer
   * @returns {boolean}
   * @function
   */

  isLoading() {
    const { currentUser, stage, eventgoer, eventgoersContact, isBackstage } =
      this.props;
    return (
      (_.isEmpty(this.state.eventfeed) && !isBackstage) ||
      !currentUser.uid ||
      !stage ||
      ((!eventgoer || !eventgoersContact) && !isBackstage)
    );
  }

  /**
   * renders Snackbar prompting user to accept cookies
   * @returns {ReactElement}
   */

  renderCookieBanner = () => {
    const { mobile } = this.props;

    return (
      <Snackbar
        open={this.state.warningOpen}
        style={{ width: "95%", bottom: 0, opacity: 0.82, zIndex: 1000 }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#292663",
            color: "#FDD992",
            fontFamily: "Montserrat",
            fontSize: mobile ? 12 : 18,
            borderRadius: "20px 20px 0px 0px",
          }}
          message={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <div>
                  This webapp uses anonymous tracking to deliver essential
                  privacy-focused features.
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    By continuing to use this webapp, you agree to our{" "}
                    <a
                      href='https://kontomo.com/terms-of-use/'
                      style={{
                        color: "#160f3e",
                        textDecoration: "none",
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href='https://kontomo.com/privacy-policy/'
                      style={{
                        color: "#FDD992",
                        textDecoration: "none",
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>
                  {mobile && (
                    <Button
                      style={{
                        backgroundColor: "#FBAE17",
                        height: 36,
                        width: 120,
                        borderRadius: 18,
                      }}
                      onClick={() => {
                        this.props.firebase.firestore
                          .collection("eventgoers")
                          .doc(this.props.eventgoer.id)
                          .update({ cookieOK: true });

                        this.setState({ warningOpen: false });
                      }}
                    >
                      OK
                    </Button>
                  )}
                </div>
              </div>
              {!mobile && (
                <Button
                  style={{
                    backgroundColor: "#FBAE17",
                    marginLeft: "4rem",
                    marginRight: "3rem",
                    height: 36,
                    width: 120,
                    borderRadius: 18,
                  }}
                  onClick={() => {
                    this.props.firebase.firestore
                      .collection("eventgoers")
                      .doc(this.props.eventgoer.id)
                      .update({ cookieOK: true });

                    this.setState({ warningOpen: false });
                  }}
                >
                  OK
                </Button>
              )}
            </div>
          }
        />
      </Snackbar>
    );
  };

  /**
   * sets `stageLoaded` in component stage
   * @function
   */

  stagehallLoaded = () => {
    this.setState({ stageLoaded: true });
  };

  /**
   * renders element with slide animation
   * @param {*} props
   * @returns {ReactElement}
   * @function
   */

  slideTransition = (props) => {
    return <Slide {...props} direction='down' />;
  };

  /**
   * renders Snackbar with bookmark banner message
   * @returns {ReactElement}
   * @function
   */

  renderBookmarkBanner = () => {
    const { mobile } = this.props;

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={this.state.stageLoaded && this.state.bookmarkWarningOpen}
        style={{ width: "95%", top: 0 }}
        TransitionComponent={this.slideTransition}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#EF4E23",
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: mobile ? 12 : 18,
            borderRadius: "0px 0px 20px 20px",
          }}
          message={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <div>
                  Bookmark this page now so you can return later in your
                  logged-in state!
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>One active login per Pass ID!</div>
                  {mobile && (
                    <Button
                      style={{
                        backgroundColor: "#FFF",
                        color: "#EF4E23",
                        height: 36,
                        width: 120,
                        borderRadius: 18,
                      }}
                      onClick={() => {
                        this.props.firebase.firestore
                          .collection("eventgoers")
                          .doc(this.props.eventgoer.id)
                          .update({ bookmarkOK: true });

                        this.setState({ bookmarkWarningOpen: false });
                      }}
                    >
                      Got It!
                    </Button>
                  )}
                </div>
              </div>
              {!mobile && (
                <Button
                  style={{
                    backgroundColor: "#FFF",
                    color: "#EF4E23",
                    marginLeft: "4rem",
                    marginRight: "3rem",
                    height: 36,
                    width: 120,
                    borderRadius: 18,
                  }}
                  onClick={() => {
                    this.props.firebase.firestore
                      .collection("eventgoers")
                      .doc(this.props.eventgoer.id)
                      .update({ bookmarkOK: true });

                    this.setState({ bookmarkWarningOpen: false });
                  }}
                >
                  Got It!
                </Button>
              )}
            </div>
          }
        />
      </Snackbar>
    );
  };

  /**
   * renders stage header
   * @param {object} stage
   * @returns {ReactElement}
   * @function
   */

  renderHeader(stage) {
    return (
      <header>
        <Avatar
          imageUrl={
            stage.organizer && stage.organizer.alias.avatarStoragePaths.md
          }
        />
        <div className='Stage-headerText'>
          <h1>{stage.title}</h1>
          <h2>{stage.dateTimeLocation}</h2>
        </div>
      </header>
    );
  }

  /**
   * renders mobile header
   * @param {object} stage
   * @returns {ReactElement}
   * @function
   */

  renderMobileHeader(stage) {
    return (
      <div>
        <MobileMenu
          handleIconClick={this.handlePickClick}
          shouldShowIOSWarning={shouldShowIOSWarning}
        />
        {this.props.mobile && window.innerWidth < window.innerHeight ? (
          <header className='Stage-mobile-header'>
            <div className='Stage-mobile-header-upper'>
              <Avatar
                imageUrl={
                  stage.organizer && stage.organizer.alias.avatarStoragePaths.md
                }
              />
              <h1>{stage.title}</h1>
            </div>
            <h2>{stage.dateTimeLocation}</h2>
          </header>
        ) : null}
      </div>
    );
  }

  /**
   * renders Backstage Banner
   * @returns {ReactElement}
   * @function
   */

  renderBackstageBanner = () => {
    const { stage, star } = this.props;

    return (
      <Banner>
        <BannerRight>
          {this.isCreator && (
            <Button
              variant='contained'
              onClick={() => {
                this.setState({ isPublishing: true }, () => {
                  this.setState({ isPublishing: false });
                });
              }}
            >
              Publish
            </Button>
          )}
        </BannerRight>

        <BannerLeft>
          <AliasSwitcher
            aliases={star.aliases}
            organizer={stage.organizer}
            isOwner={this.isOwner}
            onChange={() => {}}
          />
        </BannerLeft>
      </Banner>
    );
  };

  /**
   * <strong>deprecated</strong>
   * <p>rotates "PickIcon" element</p>
   * @param {*} param0
   * @function
   * @deprecated
   */

  handleSliderProgress = ({ progress }) => {
    const deg = progress * 180 * -1;
    const el = document.getElementById("Stage-LeftPickIcon");

    if (el) {
      el.style.transform = `rotate(${deg}deg)`;
    }
  };

  /**
   * <p>sets `activeIndex` for current slide in component state</p>
   * @param {*} param0
   * @deprecated
   * @function
   */

  handleSlideChange = ({ activeIndex }) => {
    this.setState({ activeIndex });
  };

  /**
   * <p>changes slides</p>
   * @deprecated
   * @function
   */

  handlePickClick = () => {
    if (this.state.activeIndex === 0) {
      this._swiper.slideNext(500);
    } else {
      this._swiper.slidePrev(500);
    }
  };

  /**
   * render Pick Icon button
   * @returns {ReactElement}
   * @function
   * @deprecated
   */

  renderLeftPick = () => {
    return (
      <LeftPickIcon
        id='Stage-LeftPickIcon'
        className='LeftPickIcon'
        onClick={this.handlePickClick}
      />
    );
  };

  /**
   * renders mobile stage
   * @returns {ReactElement}
   * @function
   */

  renderMobileStage = () => {
    const { stage, isBackstage, location, currentUser, mobile, firebase } =
      this.props;

    return (
      <Swiper
        onSwiper={(swiper) => (this._swiper = swiper)}
        onSlideChange={this.handleSlideChange}
        onProgress={this.handleSliderProgress}
        allowTouchMove={false}
        resistanceRatio={0}
        watchSlidesVisibility
      >
        <SwiperSlide>
          <EventfeedScreen
            id={stage.id}
            isBackstage={isBackstage}
            currentUser={currentUser}
            eventfeed={this.state.eventfeed}
            isPublishing={this.state.isPublishing}
            stage={stage}
            location={location}
            firebase={firebase}
            donations={this.state.donations}
            mobile={mobile}
          />
        </SwiperSlide>

        <SwiperSlide>
          {({ isVisible }) =>
            isVisible ? (
              <StagehallScreen
                mobile={mobile}
                isSafari={isSafari}
                shouldShowIOSWarning={shouldShowIOSWarning}
                isBackstage={isBackstage}
                renderLeftPick={this.renderLeftPick}
                stagehallLoaded={this.stagehallLoaded}
              />
            ) : null
          }
        </SwiperSlide>
      </Swiper>
    );
  };

  /**
   * renders tablet and up stage
   * @returns {ReactElement}
   * @function
   */

  renderTabletAndUpStage = () => {
    const { stage, isBackstage, location, currentUser, mobile, firebase } =
      this.props;

    return (
      <>
        {isBackstage ? (
          <BackstageChat
            id={stage.id}
            isBackstage={isBackstage}
            currentUser={currentUser}
            isPublishing={this.state.isPublishing}
            stage={stage}
            location={location}
            firebase={firebase}
          />
        ) : null}

        <EventfeedScreen
          id={stage.id}
          isBackstage={isBackstage}
          currentUser={currentUser}
          eventfeed={this.state.eventfeed}
          isPublishing={this.state.isPublishing}
          stage={stage}
          location={location}
          firebase={firebase}
          donations={this.state.donations}
        />

        <StagehallScreen
          mobile={mobile}
          isBackstage={isBackstage}
          renderLeftPick={this.renderLeftPick}
          stagehallLoaded={this.stagehallLoaded}
          shouldShowIOSWarning={shouldShowIOSWarning}
        />
      </>
    );
  };

  /**
   * main render method
   * @returns {ReactElement}
   */

  render() {
    if (this.isLoading()) return this.renderLoading();

    const { stage, mobile } = this.props;

    const isTouchscreen = navigator.maxTouchPoints > 0;

    return (
      <ThemeProvider theme={(outerTheme) => _.merge(outerTheme, theme)}>
        <div
          className='Stage'
          style={{
            height: isSafari || isFirefox ? this.state.windowHeight : "100vh",
          }}
        >
          <HelmetHeaders stage={stage} />

          <div style={{ height: "100%" }}>
            <StyledStageWrapper>
              {this.renderBookmarkBanner()}
              {this.renderCookieBanner()}

              {this.props.isBackstage && this.renderBackstageBanner()}

              {mobile
                ? this.renderMobileHeader(stage)
                : this.renderHeader(stage)}

              <div className='Stage-lower'>
                {mobile
                  ? this.renderMobileStage()
                  : this.renderTabletAndUpStage()}
              </div>
              {shouldShowIOSWarning && <IOSWarning />}
              {/* {!this.state.pane && <ChatScreen event={this.state.selectedEvent} />} */}
            </StyledStageWrapper>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withTheme(Stage);
