import React, {
    useState,
    useEffect,
    useRef,
    memo,
    lazy,
    Suspense,
  } from 'react';
import {Link} from 'react-router-dom'
import autosize from "autosize";
import './styles.scss';
import {useAuthState} from 'react-firebase-hooks/auth'
import {useCollectionData} from 'react-firebase-hooks/firestore'
import { useList } from "react-firebase-hooks/database";
import {ReactComponent as SendChat} from '../../assets/icons/Back_Stage_Chat/sendChat.svg'
import {ReactComponent as RightArrow} from '../../assets/icons/Back_Stage_Chat/collapse-arrowRight.svg'
import {ReactComponent as LeftArrow} from '../../assets/icons/Back_Stage_Chat/collapse-arrowLeft.svg'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import Collapse from 'react-bootstrap/Collapse'
import TextareaAutosize from 'react-textarea-autosize';

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();


const BackstageChat = ({
    id,
    isBackstage,
    currentUser,
    eventfeed,
    isPublishing,
    stage,
    location,
  })=>{


    const [textValue,setTextValue] = useState('')
    const [stageId,setStageId] = useState(id)
    const [starUsername,setStarUserName]=useState('')
    const [open,setOpen]=useState(true)
    const[query,setQuery]=useState("")
    const dummy= useRef()

    //check to see if there is a backstagechat for stageID
    

  useEffect(()=>{
      if(isBackstage){
          firestore.collection('starAccounts')
          .doc(currentUser.uid)
          .get()
          .then((doc)=>{

          setStarUserName(doc.data().username)
          const backStageChatsRef = firestore.collection('backStageChats')
          
          backStageChatsRef.doc(stageId).get()
          .then((docSnapshot)=>{
              if(docSnapshot.exists){
                  console.log("Chat exists!")
              }else{
                  console.log("Doesn't exist...making a document")
                  backStageChatsRef.doc(stageId).set({
                      allowedStars:{
                          starId:"QTTiyJ0daTIDhWuvGBiQ"
                          },
                   })    
                      const messRef = backStageChatsRef.doc(stageId)
           
                   }})    
                   setQuery(backStageChatsRef.where('__name__','==',stageId))
        
        })
          setStageId(id)          
      }
    },[stageId])
    
    const [chats] = (useCollectionData(firestore.collection('backStageChats').doc(stageId).collection("messages").orderBy('createdAt',"desc"), { idField: 'id' }));

    useEffect(()=>{
        //if chats get entered, scroll to the bottom.
        dummy.current.scrollIntoView();
    },[chats,open])
    
    useEffect(() => {
        //allow enter to be presed
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
              if(!event.shiftKey){
                event.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
              }else{
                  setTextValue(textValue+"\n")
              }
            

            event.preventDefault();
            // callMyFunction();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      }, [textValue]);

    const handleChange=(e)=>{
        //when the text box changes, this changed textValue
        setTextValue(e.target.value)
    }


    const sendMessage = async(e)=>{
        e.preventDefault();
        //create message data
        const message={
            "author" : starUsername,
            "text": textValue,
            "createdAt":firebase.firestore.Timestamp.now()
        }
        if(message.text !== "")
        {
            const chatRef = firestore.collection('backStageChats')
            .doc(stageId).collection('messages')
            chatRef.add(
            message
            )
        }
        setTextValue('')
        dummy.current.scrollIntoView({ behavior: 'smooth' });
    }
    


    function ChatMessage(props){
        let {text,author} = props.message;
        const timeStamp = props.message.createdAt.toDate()
        function isValidURL(string) {
            const res = string.match(/(http(s)?:\/\/.)/g);
            return (res !== null)
          };
      
        return (
        <>
        <div className="messageContainer">
            <div className="author">
                <p>{author}</p>
            </div>
            <div className="message">
                {isValidURL(text) ?
                <p><a href={text} target="_blank">{text}</a></p>
                :
                <p>{text}</p>
            }
            </div>
            <div className="time">
                <p>{timeStamp.toDateString()} @ {timeStamp.toLocaleTimeString()}</p>
            </div>
        </div>
        </>
        )
    }

    function handleChatOpen(){
        const chatBox = document.getElementById("backstageChat")

        if(open){
            document.getElementById("backstageChatContainer").style.width="3rem"
            document.getElementById("chatForm").style.transform="translate(-10rem)"
            document.getElementById("chatBoxHeader").style.width="3rem"
            document.getElementById("chatContainer").style.opacity=0
            document.getElementById("inputMessage").style.opacity=0
            document.getElementById("inputButton").style.opacity=0
            document.getElementById("backstageChat").style.overflowY="hidden"
        }else{
            document.getElementById("backstageChatContainer").style.width="16rem"
            document.getElementById("chatBoxHeader").style.transform="translate(0rem)"
            document.getElementById("chatBoxHeader").style.width="16rem"
            document.getElementById("chatContainer").style.opacity=100
            document.getElementById("backstageChat").style.overflowY="scroll"
            document.getElementById("inputMessage").style.opacity=100
            document.getElementById("inputButton").style.opacity=100
            document.getElementById("chatForm").style.transform="translate(0rem)"
            
        }
        setOpen(!open)

    }
//  backstage collection has to have StageID to get StarID
    return(
        <>
        <div id="backstageChatContainer">
            <div id="chatBoxHeader" className="header">
                
                {open ?
                    <div id="leftArrow">
                        <LeftArrow id="collapseArrow" onClick={handleChatOpen}
                        aria-controls="chatContainer"
                        aria-expanded={open}/>
                    </div>
                    
                    :
                    <div id="leftBar">
                        <div id="rightArrow">
                            <RightArrow id="collapseArrow" onClick={handleChatOpen}
                            aria-controls="chatContainer"
                            />
                        </div>
                    </div>
                    
                }
            </div>      
            
            <div id="backstageChat" className="backstageChat">
            
                <div id="chatContainer">
                    <div className="messagesContainer"> 
                        {chats && chats.map(msg=>
                        <ChatMessage key={msg.id} message={msg}/>).reverse()}
                    </div>  
                    
                
                <span ref={dummy}></span> 
            
                
                    <form id="chatForm" onSubmit={sendMessage}>
                            <TextareaAutosize id="inputMessage"  value={textValue} cacheMeasurements="true"
                                // onChange={(e)=>setTextValue(e.target.value)} 
                                onChange={handleChange}
                                placeholder="Message Collaborators"
                                />
                            <button id="inputButton" className="sendBox" type="submit"><SendChat/></button>
                            
                    </form>
                </div>
                
            </div>
            
        </div>
        
        
        
        
        
        
            
        </>
    )
}


export default BackstageChat