import React, { memo, useReducer, useState, useEffect } from "react";
import { withFirebase } from "../../../../../services/Firebase";
import MuiTextField from "@material-ui/core/TextField";
import StripeInput from "./StripeInput";
import CountrySelect from "./CountrySelect";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import NumberFormat from "react-number-format";

import autoId from "../../../../../utils/autoId";
import {
  Elements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import Promise from "promise-polyfill";
import { CircularProgress, TextField } from "@material-ui/core";

// import mastercard from "../../../../../assets/icons/Paywall/white_Mastercard.png";
// import visa from "../../../../../assets/icons/Paywall/white_Visa.png";
import paymentOptions from "../../../../../assets/icons/Paywall/only-stripe.svg";
import checkIcon from "../../../../../assets/icons/Paywall/checkIcon.png";
import closeIcon from "../../../../../assets/icons/Paywall/close.svg";
import paid from "../../../../../assets/icons/Paywall/paid.svg";
import checkCircle from "../../../../../assets/icons/Paywall/check-circle.svg";

import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { ErrorSharp } from "@material-ui/icons";

import { paymentTypes } from "../../../../../services/Firebase/constants";

// global themes that overrides the fixed themes in material UI
const theme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: "#CBC9EA",
      disabled: "#160F3E",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        paddingLeft: 10,
        marginBottom: 8,
      },
    },
  },
  props: {
    MuiTextField: {
      InputLabelProps: {
        className: "InputLabel",
        shrink: true,
        required: false,
      },
    },
  },
});

//pay what you wish input component that automatically formats currencies
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale='2'
      isNumericString
      prefix='$' //this will need to be refactored to be dynamic; for now, only USD is okay
    />
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
// Stripe Test Key
// const stripePromise = loadStripe(
//   "pk_test_51HXSMCAs2jYzfMVmDd9NwtNByJacB21eFfm9rP3lMnd3p8VFsiPeA7bPTsYiBpDTnquRwuSe3KRkVmSsc6gjgW0R00GozZAvuJ"
// );

const PaymentStep = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentSubmitting, setPaymentSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ec, setEc] = useState(null);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState("");
  // handle form errors
  const [errors, setErrors] = useState({});

  const mobile = props.mobile;
  const setSaleId = props.setSaleId;

  // load ec on render
  useEffect(() => {
    props.firebase.firestore
      .collection("ec")
      .doc(props.eventgoer.stageId)
      .get()
      .then((ecSnap) => {
        setEc(ecSnap.data());
        setLoading(false);
      });
  }, [props.eventgoer.stageId, props.firebase.firestore]);

  // call on submit once submitted is set to true
  useEffect(() => {
    submitted && setTimeout(props.onSubmit, 3000);
  }, [submitted, props.onSubmit]);

  // handle user input
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { notificationsOn: true }
  );

  async function createEventgoersPayInfo() {
    const { email, name, country } = userInput;

    await props.firebase.firestore
      .collection("eventgoersPayInfo")
      .doc(props.eventgoer.id)
      .set({
        id: props.eventgoer.id,
        stageId: props.eventgoer.stageId,
        email,
        name,
        country,
      });

    return new Promise((resolve, reject) => {
      const unsubscribe = props.firebase.firestore
        .collection("eventgoersPayInfo")
        .doc(props.eventgoer.id)
        .onSnapshot((snap) => {
          const data = snap.data()
          if (data.error) {
            unsubscribe();
            reject(data.error)
          } else if (snap.data().stripeCustomerId) {
            unsubscribe();
            resolve(snap.data());
          } else {
            return;
          }
        });
    });
  }

  async function createSale(eventgoersPayInfo) {
    const id = autoId(25);
    const { amount, currencyAbv, chargeRuleAlgo, chargeRule } =
      props.paywallData;

    const saleRef = props.firebase.firestore
      .collection("ec")
      .doc(eventgoersPayInfo.stageId)
      .collection("sales")
      .doc(id);
    const timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;

    /**
     * add document to sales collection
     * data will be converted into a Stripe PaymentIntent object
     */
    await saleRef.set({
      type: paymentTypes.SALE,
      id,
      amount: userInput.payWhatYouWishAmount
        ? userInput.payWhatYouWishAmount
        : amount,
      currency: currencyAbv,
      stripeCustomerId: eventgoersPayInfo.stripeCustomerId,
      eventgoerId: eventgoersPayInfo.id,
      paywallId: props.paywallData.id,
      chargeRuleAlgo,
      chargeRule,
      timezoneOffset,
    });

    return new Promise((resolve, reject) => {
      const unsubscribe = saleRef.onSnapshot((snap) => {
        if (snap.data().paymentIntentId) {
          console.log(snap.data());
          unsubscribe();
          // return a stripe Payment Intent
          resolve(snap.data());
        }
      });

      setTimeout(reject, 30000);
    });
  }
  function confirmCardPayment(sale, eventgoersPayInfo) {
    const { country, email, name } = eventgoersPayInfo;
    console.log("SALE STATUS", sale.status);
    const cardNumberElement = elements.getElement(CardNumberElement);

    return stripe.confirmCardPayment(sale.client_secret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          address: {
            country: country,
          },
          email: email,
          name: name,
        },
      },
    });
  }
  function handleChange({ currentTarget: { name, value, type, checked } }) {
    setUserInput({ [name]: type === "checkbox" ? checked : value });
  }

  function handleCountryChange(e, country) {
    setUserInput({ country: country ? country.code : null });
  }

  function handleStripeElementChange(stripeChangeObj) {
    setUserInput({ [stripeChangeObj.name]: stripeChangeObj.value });
  }

  function handleAmount(event) {
    const payWhatYouWishAmount = event.target.value;
    setValues(payWhatYouWishAmount);

    //must be in cents for the stripe charge object, so multiply user input by 100
    //this is also written for USD only, will need to refactor for other currency in the future
    const payWhatYouWishAmountInCents = Math.round(payWhatYouWishAmount * 100);
    setUserInput({
      payWhatYouWishAmount: payWhatYouWishAmountInCents.toString(),
    });
  }

  function onPaymentConfirmed(
    { error, paymentIntent },
    sale,
    eventgoersPayInfo
  ) {
    if (error || paymentIntent.status !== "succeeded") {
      console.log(error);
      if (error.message) {
        setMessage(
          "There was an issue with your payment. You have not been charged. Please try again"
        );
      }
      // Delete sale created from createSale(eventgoersPayInfo);
      // Delete will happen if 1) there is an error 2)the payment was not a success, which is when the paymentIntent.status is NOT "succeeded"
      const saleRef = props.firebase.firestore
        .collection("ec")
        .doc(eventgoersPayInfo.stageId)
        .collection("sales")
        .doc(sale.id);
      saleRef.delete();
      // Allow for resubmitting by changing to false
      setPaymentSubmitting(false);
    } else if (paymentIntent.status === "succeeded") {
      //the initial document created will only be updated if the payment status is "succeeded"

      const saleRef = props.firebase.firestore
        .collection("ec")
        .doc(eventgoersPayInfo.stageId)
        .collection("sales")
        .doc(sale.id);

      saleRef.update({
        ...paymentIntent,
        id: sale.id,
        paymentSuccess: true,
        paidAmount: sale.amount,
      });
      console.log(saleRef);
      setSubmitted(true);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    //stop form submission if pwyw value is less than $2.00
    if (values && parseFloat(values) <= 2.0) {
      setErrors({ ...errors, ...{ payWhatYouWishAmount: true } });
      return;
    }

    if (paymentSubmitting) return;

    // Stripe.js has not loaded yet
    if (!stripe || !elements) return;
    setPaymentSubmitting(true);

    props.onEventgoersContactChange({
      email: userInput.email,
      notificationsOn: userInput.notificationsOn,
      //maybe add stageId here so it can be stored in the document
    });

    let eventgoersPayInfo;
    try {
      eventgoersPayInfo = await createEventgoersPayInfo();
    } catch (e) {
      setMessage(
        "There was an issue with your payment. You have not been charged. Please check your payment info and try again."
      );
      setPaymentSubmitting(false);
      return;
    }

    const sale = await createSale(eventgoersPayInfo);

    setSaleId(sale.id);

    confirmCardPayment(sale, eventgoersPayInfo)
      .then((res) => {
        console.log("confirmed");
        onPaymentConfirmed(res, sale, eventgoersPayInfo);
      })
      .catch((e) => {
        console.log(e);
        setMessage("There was an issue with your payment. You have not been charged. Please check your payment info and try again.");
      });
  }

  const stripeOptions = {
    iconStyle: "solid",
    style: {
      base: {
        // iconColor: '#c4f0ff',
        color: "#292663",
        fontWeight: 500,
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        // fontSmoothing: 'antialiased',
        ":-webkit-autofill": { color: "#292663", borderRadius: 17 },
        "::placeholder": { color: "rgba(98, 93, 193, 1)" },
      },
      invalid: {
        // iconColor: '#ffc7ee',
        color: "#red",
      },
    },
    disabled: paymentSubmitting,
  };

  const renderForm = () => {
    return (
      <div>
        <div></div>
        <form
          onSubmit={handleSubmit}
          style={{ maxHeight: "100%", bottom: "4rem" }}
        >
          <ThemeProvider theme={theme}>
            <MuiTextField
              label='Cardholder Name'
              name='name'
              value={userInput.name}
              onChange={handleChange}
              color='primary'
              fullWidth
              placeholder='Cardholder Name'
              required
              disabled={paymentSubmitting}
            />

            <MuiTextField
              label='Card number'
              name='ccnumber'
              value={userInput.ccnumber}
              onChange={handleStripeElementChange}
              color='primary'
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  options: stripeOptions,
                  component: CardNumberElement,
                },
              }}
              placeholder='Card number'
              disabled={paymentSubmitting}
            />

            <div style={{ display: "flex" }}>
              <MuiTextField
                label='Expiration'
                name='ccexp'
                value={userInput.ccexp}
                onChange={handleStripeElementChange}
                color='primary'
                required
                fullWidth
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    options: stripeOptions,
                    component: CardExpiryElement,
                  },
                }}
              />

              <div style={{ width: 40 }} />

              <MuiTextField
                label='CVC'
                name='cvc'
                value={userInput.cvc}
                onChange={handleStripeElementChange}
                color='primary'
                fullWidth
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    options: stripeOptions,
                    component: CardCvcElement,
                  },
                }}
                placeholder='CVC'
                disabled={paymentSubmitting}
              />
            </div>

            <CountrySelect
              label='Country'
              name='country'
              value={userInput.country}
              InputLabelProps={{
                shrink: true,
                className: "InputLabel",
                required: false,
              }}
              onChange={handleCountryChange}
              color='primary'
              fullWidth
              placeholder='Country'
              required
              disabled={paymentSubmitting}
            />

            <MuiTextField
              label='Email for receipt & Pass ID'
              name='email'
              value={userInput.email}
              onChange={handleChange}
              color='primary'
              fullWidth
              placeholder='Email for receipt & Pass ID'
              error={errors.email}
              required
              disabled={paymentSubmitting}
            />

            <FormControlLabel
              color='primary'
              className='Checkbox-label'
              type='checkbox'
              name='notificationsOn'
              onChange={handleChange}
              value='notificationsOn'
              control={
                <Checkbox
                  className='Checkbox'
                  disabled={paymentSubmitting}
                  checked={userInput.notificationsOn}
                  icon={
                    <FiberManualRecordRoundedIcon
                      style={{
                        transform: "scale(1.43)",
                        color: "#CBC9EA",
                        fontSize: 32,
                      }}
                    />
                  }
                  checkedIcon={
                    <img
                      src={checkCircle}
                      style={{
                        border: "solid 4px #fbae17",
                        borderRadius: 17,
                        background: "#fbae17",
                        height: 32,
                        width: 32,
                      }}
                    />
                  }
                />
              }
              label={
                <span
                  style={{ fontWeight: 500, fontSize: 16, color: "#160f3e" }}
                >
                  Get email updates from this Stage (recommended)
                </span>
              }
              labelPlacement='end'
            />

            <FormControlLabel
              color='primary'
              className='Checkbox-label'
              value={userInput.termsAgreed}
              type='checkbox'
              name='termsAgreed'
              onChange={handleChange}
              control={
                <div style={{ display: "table-cell" }}>
                  <Checkbox
                    required
                    disabled={paymentSubmitting}
                    className='Checkbox'
                    icon={
                      <FiberManualRecordRoundedIcon
                        style={{
                          transform: "scale(1.43)",
                          color: "#CBC9EA",
                          fontSize: 32,
                        }}
                      />
                    }
                    checkedIcon={
                      <img
                        src={checkCircle}
                        style={{
                          border: "solid 4px #fbae17",
                          borderRadius: 17,
                          background: "#fbae17",
                          height: 32,
                          width: 32,
                        }}
                      />
                    }
                  />
                </div>
              }
              label={
                <span
                  id='termsAgreed'
                  style={{ fontSize: "14px", color: "#160f3e" }}
                >
                  I have read and agreed to the Kontomo{" "}
                  <a href='https://kontomo.com/payment-terms/' target='blank'>
                    payment terms
                  </a>
                  ,{" "}
                  <a href='https://kontomo.com/terms-of-use/' target='blank'>
                    terms of use
                  </a>{" "}
                  and{" "}
                  <a href='https://kontomo.com/privacy-policy' target='blank'>
                    privacy policy
                  </a>
                </span>
              }
              labelPlacement='end'
              style={{ display: "table" }}
            />
          </ThemeProvider>

          <div></div>

          <ThemeProvider theme={theme}>
            {message && <p style={{ color: "red", fontSize: 14 }}>{message}</p>}
            <Button
              disabled={paymentSubmitting}
              variant='contained'
              className='btn'
              id='paymentSubmit'
              type='submit'
              style={{
                marginBottom: 0,
                marginTop: mobile ? 15 : 18,
                width: "100%",
                height: 36,
              }}
            >
              {paymentSubmitting ? (
                <div style={{ position: "relative" }}>
                  <span
                    style={{
                      paddingRight: 10,
                      fontSize: 15,
                      fontWeight: 500,
                      verticalAlign: "middle",
                    }}
                  >
                    PROCESSING
                  </span>
                  <CircularProgress
                    thickness={6}
                    size={25}
                    style={{ color: "#fff", verticalAlign: "middle" }}
                  />
                </div>
              ) : (
                <span
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                    verticalAlign: "middle",
                  }}
                >
                  PAY{" "}
                  {!props.paywallData.payWhatYouWish
                    ? props.paywallData.currencySymbol +
                      props.paywallData.amount +
                      " " +
                      `(${props.paywallData.currencyAbv})`
                    : !values
                    ? ""
                    : props.paywallData.currencySymbol +
                      values +
                      " " +
                      `(${props.paywallData.currencyAbv})`}
                </span>
              )}
            </Button>
          </ThemeProvider>
        </form>
        <div></div>
      </div>
    );
  };

  if (loading) {
    return <CircularProgress color='secondary' />;
  }

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        {!paymentSubmitting && (
          <img
            alt='Close Dialog'
            onClick={props.onClose}
            src={closeIcon}
            className='close-icon'
            style={{
              marginTop: 0,
              top: mobile ? 9 : 20,
              width: 32,
              height: 32,
              background: "#CBC9EA",
              borderRadius: "50%",
            }}
          />
        )}
      </div>
      {!submitted && (
        <div>
          {!props.paywallData.payWhatYouWish ? (
            <h1 style={{ marginTop: 0, marginBottom: 0 }}>
              {props.paywallData.currencySymbol + props.paywallData.amount}
            </h1>
          ) : (
            <h1 style={{ marginTop: 0, marginBottom: 0 }}>Pay what you wish</h1>
          )}
          <div>
            <div
              className='PassIdDialog-paywallDescription'
              style={{ fontSize: 22 }}
            >
              {props.paywallData.description}
            </div>
            <hr />
          </div>
          {props.paywallData.payWhatYouWish ? (
            <div style={{ marginTop: 20 }}>
              <form onSubmit={handleSubmit} style={{ width: 282 }}>
                <ThemeProvider theme={theme}>
                  <MuiTextField
                    label='Any amount greater than $2 (USD)'
                    fullWidth
                    color='primary'
                    required
                    placeholder='Pay what you wish ( > $2 USD)'
                    name='payWhatYouWishAmount'
                    value={values}
                    variant='filled'
                    onChange={handleAmount}
                    error={!values ? false : values <= 2.0}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      style: {
                        backgroundColor: "#FEEFD1",
                        borderRadius: "17px 17px 0 0",
                        paddingTop: "2rem",
                        color: values <= 2.0 ? "red" : "black",
                      },
                    }}
                    InputLabelProps={{
                      className: "InputLabel",
                      shrink: true,
                      required: false,
                      style: { paddingLeft: 0 },
                    }}
                    disabled={paymentSubmitting}
                  />
                </ThemeProvider>
              </form>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: mobile ? 5 : 10,
              marginBottom: mobile ? 5 : 10,
              width: 288,
              marginTop: 20,
            }}
          >
            <img src={paymentOptions} style={{ marginBottom: 10 }} />
          </div>
        </div>
      )}

      {!submitted && renderForm()}
      {submitted && (
        <div>
          <h1>Payment Successful</h1>
          <div className='text-large'>{props.paywallData.description}</div>
          <hr style={{ width: 288, marginTop: 14 }} />
          <div style={{ margin: "auto", textAlign: "center", marginTop: 28 }}>
            <img src={paid} style={{ width: 86, height: 86 }} />
          </div>
        </div>
      )}
    </div>
  );
};

const withElements = (Component) => {
  return (props) => (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  );
};

export default withFirebase(withElements(memo(PaymentStep)));
