export const editChannel = (
  editChannelName,
  currentChannel,
  db,
  demoEventfeed,
  postIds,
  channels,
  setChannels,
  channelsOrder,
  setChannelsOrder,
  channelsIndexes,
  setEditChannel,
  setChannelsIndexes,
  setChannelsCount,
  setCurrentChannel,
  postObjects
) => {
  if (editChannelName !== channelsOrder[currentChannel]) {
    const ref = db.collection("eventfeeds").doc(demoEventfeed);

    ref
      .get()
      .then(function (doc) {
        if (doc.exists && currentChannel !== 0) {
          const chCount = doc.data().channelMap.channelNames.length;

          const editChannelUpdate = {
            "channelMap.channelNames": [
              ...doc.data().channelMap.channelNames,
              editChannelName,
            ],
          };
          
          let channelContentsUpdate = {};
          channelContentsUpdate[
            `eventfeedUnpub.${chCount}.posts`
          ] = postObjects;

          let channelUpdate = channels;
          delete channelUpdate[currentChannel];
          channelUpdate[chCount] = postIds;
          setChannels(channelUpdate);

          const channelIndex = channelsIndexes.findIndex(
            (idx) => idx === currentChannel
          );
          let newOrder = [...channelsOrder];
          newOrder.splice(channelIndex, 1, editChannelName);
          setChannelsOrder(newOrder);

          const newChannelIndexes = [
            ...channelsIndexes.slice(0, channelIndex),
            chCount,
            ...channelsIndexes.slice(channelIndex + 1),
          ];
          setChannelsIndexes(newChannelIndexes);

          let update = {};
          update[`eventfeedUnpub.channelOrder`] = newChannelIndexes;

          let channelMapUpdate = {};
          
          const channelNameUpdates = doc.data().channelMap.channelNameUpdates ? doc.data().channelMap.channelNameUpdates : {};
          const channelNameArrays = Object.values(channelNameUpdates);
          const channelNameKeys = Object.keys(channelNameUpdates);
          const channelNameIndex = channelNameArrays.findIndex((array) => array.includes(currentChannel));

          if (channelNameIndex !== -1) {
            channelMapUpdate[
              `channelMap.channelNameUpdates.${channelNameKeys[channelNameIndex]}`
            ] = [
              ...channelNameUpdates[channelNameKeys[channelNameIndex]],
              chCount,
            ];
          } else {
            channelMapUpdate[
              `channelMap.channelNameUpdates.${currentChannel}`
            ] = [currentChannel, chCount];
          }

          ref.update({
            ...editChannelUpdate,
            ...channelContentsUpdate,
            ...update,
            ...channelMapUpdate,
          });

          setCurrentChannel(chCount);
          localStorage.setItem("localStorageChannel", chCount);
          setEditChannel("");
          setChannelsCount(chCount + 1);
          
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

  } else {
    console.log("No change in channel name")
    setEditChannel("");
  }
};
