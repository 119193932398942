import { bindActionCreators } from "redux";
import { setDonationsOn, setDonorsChannel, setEventfeedChannel } from "../../redux/app-redux";
import { connect } from "react-redux";
import EventfeedScreen from "./EventfeedScreen";

const mapStateToProps = (state) => {
  const {
    donationsOn,
    eventfeedChannel,
    eventgoer
  } = state;

  return {
    donationsOn,
    eventfeedChannel,
    eventgoer
  };
};

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators({ setDonationsOn, setDonorsChannel, setEventfeedChannel }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventfeedScreen);