import * as _ from "lodash";
export const updatePosts = (
  db,
  demoEventfeed,
  setChannelsCount,
  setChannelsOrder,
  setChannelsIndexes,
  setChannels,
  setCurrentChannel,
  setPostIds,
  setData,
  setPostObjects,
  isBackstage,
  eventfeed,
  channel,
  postRef,
  eventfeedData,
  setChannelsRecentlyUpdated,
  prevChannelsIndexes,
  prevPostIds,
  dialog,
  oldIndex,
  setOldIndex,
  prevChannelsRecentlyUpdated,
  prevPostsRecentlyUpdated,
  setPostsRecentlyUpdated
) => {
  // Set a reference to the demo eventfeed
  const docRef = db.collection("eventfeeds").doc(demoEventfeed);

  let postIdArray = [];

  docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        const channelNames = doc.data().channelMap.channelNames;
        setChannelsCount(channelNames.length);
        const channelsIndexes = isBackstage
          ? eventfeed.eventfeedUnpub.channelOrder
          : eventfeed.eventfeedPub.channelOrder;
        const channelOrderArray = channelsIndexes.map(
          (index) => channelNames[index]
        );

        
        let channelsRecentlyUpdated = {};

        channelsIndexes.forEach(
          (index) => {
            const isNewChannelIndex = !prevChannelsIndexes.includes(index);
            const prevChannelIndexDate = prevChannelsRecentlyUpdated[index];

            const prev = isBackstage
              ? eventfeedData.eventfeedUnpub[index]
              : eventfeedData.eventfeedPub[index];

            const next = isBackstage
              ? eventfeed.eventfeedUnpub[index]
              : eventfeed.eventfeedPub[index];

            // Use previous channel if it exists and compare the difference in
            // array of post ids
            const channelChanged = !_.isEqual(
              prev && prev.posts.map((post) => post.id),
              next.posts.map((post) => post.id)
            );

            if (isNewChannelIndex || channelChanged) {
              channelsRecentlyUpdated[index] = new Date();
            } else if (prevChannelIndexDate) {
              channelsRecentlyUpdated[index] = prevChannelIndexDate;
            } else {
              channelsRecentlyUpdated[index] = false;
            }
          }
        );

        // Move out postsRecentlyUpdated from channels forEach so it does not
        // get reset for every channel
        let postsRecentlyUpdated = {};

        channelsIndexes.forEach((index, idx) => {
          const posts = isBackstage
            ? eventfeed.eventfeedUnpub[index].posts
            : eventfeed.eventfeedPub[index].posts;


          const nowDate = new Date();

          posts.forEach(
              (post, postIdx) =>
                {

                  let isNewPost = false;

                  if (
                    isBackstage
                      ? eventfeedData.eventfeedUnpub[index]
                      : eventfeedData.eventfeedPub[index]
                  ) {
                    const previousPosts = isBackstage
                      ? eventfeedData.eventfeedUnpub[index].posts
                      : eventfeedData.eventfeedPub[index].posts;
                      
                    isNewPost = !previousPosts.some((prevPost) =>
                      // Compare post id
                      prevPost.id === post.id
                    );

                  } else if (!isBackstage) {
                    // Channel is new and does not exist
                    isNewPost = true;
                  }

                  const isPreviouslyUpdated =
                    prevPostsRecentlyUpdated[post.id] &&
                    nowDate - prevPostsRecentlyUpdated[post.id] < 120000;

                  postsRecentlyUpdated[post.id] = isNewPost || isPreviouslyUpdated
                    ? isPreviouslyUpdated
                      ? prevPostsRecentlyUpdated[post.id]
                      : new Date()
                    : false;
                }
            )

          });
          
        setPostsRecentlyUpdated(postsRecentlyUpdated);

        setChannelsRecentlyUpdated(channelsRecentlyUpdated);
        setChannelsOrder(channelOrderArray);
        setChannelsIndexes(channelsIndexes);

        let channelObject = {};

        channelsIndexes.forEach((channelIndex) => {
          channelObject[channelIndex] = isBackstage
            ? doc
                .data()
                .eventfeedUnpub[channelIndex].posts.map((post) => post.id)
            : doc
                .data()
                .eventfeedPub[channelIndex].posts.map((post) => post.id);
        });

        let localStorageChannel = localStorage.getItem("localStorageChannel");

        const channelNameUpdates = doc.data().channelMap.channelNameUpdates
          ? doc.data().channelMap.channelNameUpdates
          : {};
        const channelNameArrays = Object.values(channelNameUpdates);
        const channelNameIndex = channelNameArrays.findIndex((array) => 
          array.includes(parseInt(localStorageChannel))
        );
        if (localStorageChannel && channelNameIndex !== -1) {
          const historyArray = channelNameArrays[channelNameIndex];
          localStorageChannel = historyArray[historyArray.length - 1];
        }

        const channelPropertyIndex =
          localStorageChannel &&
          channelsIndexes.includes(parseInt(localStorageChannel))
            ? localStorageChannel
            : 0;

        const postObjects = isBackstage
          ? doc.data().eventfeedUnpub[channelPropertyIndex].posts
          : eventfeed.eventfeedPub[channelPropertyIndex].posts;
        setPostObjects(postObjects);

        setChannels(channelObject);
        // TODO: update posts remain the same
        setCurrentChannel(parseInt(channelPropertyIndex));
        postIdArray = channelObject[channelPropertyIndex];
        setPostIds(postIdArray);

        if (dialog === "reorder" || dialog === "delete" || dialog === "edit") {
          const newOldIndex = postIdArray.indexOf(
            prevPostIds[oldIndex]
          );
          setOldIndex(newOldIndex);
        } 

        const posts = await Promise.all(
          postIdArray.map(async (postId, idx) => {
            let newPostObject = {};
            await db
              .collection("posts")
              .doc(postId)
              .get()
              .then(async (doc) => {
                newPostObject = doc.data();
                newPostObject.id = doc.id;
                const channelExists = channelsIndexes.includes(
                  parseInt(localStorageChannel)
                );
                if (
                  (!prevPostIds.includes(doc.id) && channelExists)
                ) {
                  newPostObject.recentlyUpdated = new Date();
                } else if (
                  postsRecentlyUpdated[doc.id]
                ) {
                  newPostObject.recentlyUpdated =
                    postsRecentlyUpdated[doc.id];
                }
              });
            return newPostObject;
          })
        );
                

        setData(posts);
      } else {
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
};
