import styled, { css } from "styled-components";

import { Button } from "@material-ui/core";
export const StyledButton = styled(Button)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #292663;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 17px;
  position: relative;
  height: 36px;
  border: 2px solid #fbae17;
  margin: 14px;

  ${(props) => {
    switch (props.variety) {
      case "primary":
        return css`
          background-color: #fbae17;
          border-color: #fbae17;
          &:hover {
            background-color: #fcbe45;
          }
        `;
      case "secondary":
        return css`
          background-color: #292663;
          color: #fbfbfb;
          border-color: #292663;
          &:hover {
            background-color: #3f3b99;
          }
        `;
      case "tertiary":
        return css`
          background-color: #cbc9ea;
          border-color: #cbc9ea;
          &:hover {
            background-color: #d5d4ee;
          }
        `;
      case "special":
        return css`
          background-color: #ef4e23;
          color: #fbfbfb;
          border-color: #ef4e23;
          &:hover {
            background-color: #f2714f;
          }
        `;
      case "closedIcon":
        return css`
          background-color: #cbc9ea;
          border-radius: 50%;
          color: #625dc1;
          font-size: 14px;
          border: none;
          padding: 24px;
          height: 32px;
          width: 32px;
          margin: 0;
          &:hover {
            background-color: #f2714f;
          }
          &.MuiButton-root-20 {
            min-width: 0px;
          }
        `;
      default:
        break;
    }
  }};

  &.Mui-disabled {
    background-color: #a1a1a1;
    color: #292663;
  }
`;
