export const getPosts = (
      db,
      demoEventfeed,
      setChannelsCount,
      setChannelsOrder,
      setChannelsIndexes,
      setChannels,
      setCurrentChannel,
      setPostIds,
      setData,
      setPostObjects,
      isBackstage,
      eventfeed,
      channel,
      postRef,
      postsContainerRef,
      setChannelScrollPositions,
      currentUser,
      firebase
    ) => {
    // Set a reference to the demo eventfeed
    const docRef = db.collection("eventfeeds").doc(demoEventfeed);

    let postIdArray = [];

    docRef
      .get()
      .then(async function (doc) {
        if (doc.exists) {

          // Check to see if current star is updating a channel or post even though they are not
          // Delete that channel or post for current star
          // This accounts for editing a channel or post and exiting the input box without clicking cancel button, which leaves the user still updating
          const usersEditing = doc.data().usersEditing;
          if (isBackstage) {

            db.collection("starAccounts")
              .doc(currentUser.uid)
              .get()
              .then((doc) => {

                const starUsername = doc.data().username;
                const usersEditingPosts = Object.values(usersEditing.posts);
                const usersEditingPostsIndex = usersEditingPosts.indexOf(
                  starUsername
                );
                
                if (usersEditingPostsIndex !== -1) {
                  const deletePostKey = Object.keys(usersEditing.posts)[
                    usersEditingPostsIndex
                  ];

                  let update = {};
                  update[
                    `usersEditing.posts.${deletePostKey}`
                  ] = firebase.firestore.FieldValue.delete();

                  docRef.update(update);
                }

                const usersEditingChannels = Object.values(usersEditing.channels);
                const usersEditingChannelsIndex = usersEditingChannels.indexOf(
                  starUsername
                );
                
                if (usersEditingChannelsIndex !== -1) {
                  const deletePostKey = Object.keys(usersEditing.channels)[
                    usersEditingChannelsIndex
                  ];

                  let update = {};
                  update[
                    `usersEditing.channels.${deletePostKey}`
                  ] = firebase.firestore.FieldValue.delete();

                  docRef.update(update);
                }

              });
          }


          const channelNames = doc.data().channelMap.channelNames;
          setChannelsCount(channelNames.length);
          const channelsIndexes = isBackstage
            ? doc.data().eventfeedUnpub.channelOrder
            : eventfeed.eventfeedPub.channelOrder;
          const channelOrderArray = channelsIndexes.map(
            (index) => channelNames[index]
          );

          setChannelsOrder(channelOrderArray);
          setChannelsIndexes(channelsIndexes);

          let channelObject = {};

          channelsIndexes.forEach((channelIndex) => {
            channelObject[channelIndex] = isBackstage
              ? doc
                  .data()
                  .eventfeedUnpub[channelIndex].posts.map((post) => post.id)
              : doc
                  .data()
                  .eventfeedPub[channelIndex].posts.map((post) => post.id);
          });

          let localStorageChannel = localStorage.getItem("localStorageChannel");

          if (channel) {
            localStorageChannel = channel;
          }

          // FIXME: Need to specify eventfeed then localStorageChannel. like "GtQn3jPngSNiode18Fsi - 0" 
          // Otherwise index might be mismatched across eventfeeds
          const channelPropertyIndex =
            localStorageChannel &&
            channelsIndexes.includes(parseInt(localStorageChannel))
              ? localStorageChannel
              : 0;

          const postObjects = isBackstage
            ? doc.data().eventfeedUnpub[channelPropertyIndex].posts
            : eventfeed.eventfeedPub[channelPropertyIndex].posts;
          setPostObjects(postObjects);

          setChannels(channelObject);
          setCurrentChannel(parseInt(channelPropertyIndex));
          postIdArray = channelObject[channelPropertyIndex];
          setPostIds(postIdArray);
          
          const posts = await Promise.all(
            postIdArray.map(async (postId) => {
              let newPostObject = {};
              await db
              .collection("posts")
              .doc(postId)
              .get()
              .then(async (doc) => {
                newPostObject = doc.data();
                newPostObject.id = postId;
              });
              return newPostObject;
          }));

          setData(posts);

          const storedScrolls = localStorage.getItem("storedScrolls");
          if (storedScrolls) {
            const channelScrollPositions = JSON.parse(storedScrolls);
            setChannelScrollPositions(channelScrollPositions);
            const channelScrollPosition =
              channelScrollPositions[channelPropertyIndex];
            if (channelScrollPosition) {
              postsContainerRef.current.scrollTop = channelScrollPosition;
            }
          }
          
          // Scroll to post given post params after half a second
          setTimeout(() => {
            if (postRef.current) {
              postRef.current.scrollIntoView({ behavior: "smooth" });
            }
          }, 500);

          // Set prerenderReady to true for Netlify prerendering
          window.prerenderReady = true;
          
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };