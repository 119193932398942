export default {
    fontFamily: `"Montserrat", sans-serif`,
    borderRadius: '17px',
    bannerHeight: '60px',
    stageHeaderHeight: '5.125rem', // 82px
    buttonWidth: '7.5rem', // 120px
    scrollPadding: '4px', // distance from scrollbar to edge of container
    boxPadding: '0.625rem', // 10px
    
    // Base colors
    
    colorPrimary: '#292663',
    colorPrimaryDark: '#160f3e',
    colorPrimaryLight: '#432a74',
    
    colorSecondary: '#ef4e23',
    colorSecondaryDark: '#c3161c',
    colorSecondaryLight: '#fbae17',
    
    colorTertiary: '#cbc9ea',
    colorTertiarydark: '#4e496d',
    colorTertiaryLight: '#fdd992',
    
    colorGreyscaleWhite: '#ffffff',
    colorGreyscaleCeramic: '#f6f6f9',
    colorGreyscaleClay: '#c8c8c8',
    colorGreyscaleBlack: '#000000',
    
    // Text colors
    
   get colorTextHighlight() {
    return this.colorSecondaryLight
   },
   get colorTextDefault() {
       return this.colorGreyscaleWhite
   },
    get colorTextLight() {
        return this.colorTertiaryLight
    },
    get colorTextStencil() {
        return this.colorPrimaryDark
    },
    get colorTextMuted() {
        return this.colorTertiary
    },
    get colorTextStrong() {
        return this.colorSecondary
    },
    
    // Text sizes
    textHeroFontSize: '1.5rem', // 24px
    textHeroLineHeight: 1.209, // 29px
    
    // Breakpoints
    xs: '0px',
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
    
    tablet: '768px',
}