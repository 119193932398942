import React, { Component, ReactElement } from "react";
import PropTypes from "prop-types";
import { flatMap, findIndex } from "lodash";
import firebase from "firebase/app";
import cx from "classnames";
import ReactPlayer from "react-player/youtube";
import * as m from "moment";
import bcrypt from "bcryptjs";
import screenfull from "screenfull";
import * as Yup from "yup";
import _ from "lodash";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { scheduleSegments, schedule, clearTimeouts } from "./utils/scheduler";
import MediaSegments from "./components/MediaSegments";
import Fallback from "./components/Fallback";
import VolumeSlider from "./components/VolumeSlider";
import Toggle from "../../components/Toggle";
import PassIdDialog from "./components/PassIdDialog";
import KalturaPlayer from "./components/KalturaPlayer";
import { ReactComponent as MutedIcon } from "../../assets/icons/Stagehall/mute.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/icons/Stagehall/fullscreen.svg";
import { ReactComponent as FullScreenExitIcon } from "../../assets/icons/Stagehall/fullscreenExit.svg";
import NowPlaying from "./components/NowPlaying";
import PlayerHeader from "./components/PlayerHeader";

import "./styles.scss";

import { diff } from "deep-diff";
import isIOS from "../../utils/isIOS";
import isiPadOS from "../../utils/isiPadOS";

/**
 * <description>Stagehall is the parent component of PlayerHeader, MediaSegments, PassIdDialog, and all ReactPlayers and KalturaPlayers </description>
 * <p>Creates a "schedule" of callback functions to set the currentVideo and currentSegment in component state. This relies on the moment.js library and uses the Web Workers API to run timeouts when the window is not in focus.</p>
 * <p>For stagehalls with multiple segments or multiple mediaIds, a distinct player component is rendered for each piece of media. This creates a stack of video players with CSS of `display: none` or `display: block` depending on whether videoId matches this.state.currentVideo. 
 * This is accomplished by conditionally rendering an HTML class of `active`</p>
 * @requires {@link scheduleSegments}
 * @requires screenfull
 */

class Stagehall extends Component {
  /**
   * Receives props redux connect and withFirebase wrapper in {@link enhance container.js}
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isPlaying: false,
      inBystanderMode: true,
      showFallback: false,
      eventgoerCount: "...",
      showClaimForm: props.eventgoer && props.eventgoer.confirmed,
      linkClaimed: false,
      volume: 100,
      isFullscreen: false,
      videosStarted: false,
      buffering: false,
      videoTransitioning: false,
      // currentSegment: null,
      // currentVideo: null
    };

    this.videoRef = React.createRef();
  }

  /**
   * Initializes listeners for Stagehall updates, sales, eventgoer status, and full screen
   */

  componentDidMount() {
    this.initStagehallUpdateListener();
    !this.props.isBackstage && this.initSalesListener();
    this.initEventgoerStatusListener();
    this.initFullScreenListener();
  }

  /**
   * Clears timeouts and detaches listeners
   */

  componentWillUnmount() {
    clearTimeouts();
    this.removeStagehallUpdateListener();
    this.props.eventgoerCountRef.off("value");
    this.props.eventgoerStatusRef.off();
    screenfull.isEnabled && screenfull.off("change", this.screenfullCallback);
    this.removeSalesListener && this.removeSalesListener();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { stagehall } = nextProps;
    const scheduleUpdated =
      stagehall &&
      stagehall.scheduleUpdatedAt &&
      Date(stagehall.scheduleUpdatedAt) !== Date(stagehall.updatedAt);

    if (scheduleUpdated) {
      // dont update if stagehall was updated but schedule hasnt been updated yet
      return false;
    } else {
      return true;
    }
  }
  
  /**
   * Handles updates to the stagehall componenet. 
   * <p>if mediaSegmentChanged:</p>
   *  <ul>
   *  <li>Toggles on Bystander Mode in case of viewing restrictions</li>
   *  <li>Restarts videos if segment data updated</li>
   *  <li>Calls launch()</li>
   * </ul>
   * <p>if moved to next video:</p>
   *  <ul>
   *  <li>Sets transitioning</li>
   *  <li>Rewinds previous video.</li>
   * </ul>
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    // const propsDiff = diff(prevProps, this.props)
    // const stateDiff = diff(prevState, this.state)

    // if (propsDiff || stateDiff) {
    //   const time = new Date()
    //   console.groupCollapsed('changes')
    //     console.log(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
    //     propsDiff && console.log('props', propsDiff, 'prevProps', prevProps)
    //     stateDiff && console.log('state', stateDiff, 'prevState', prevState)
    //   console.groupEnd('changes')
    // }

    if (this.mediaSegmentChanged(prevState)) {
      this.setState({
        showingPassIdDialog: false,
      });

      if (
        !this.props.isBackstage &&
        !this.state.inBystanderMode &&
        this.handleViewingRestrictions()
      ) {
        this.setState({ inBystanderMode: true });
      }

      if (this.segmentDataUpdated(prevState)) {
        // current segment data was updated
        // console.log('videosStarted: false')

        this.setState({
          videosStarted: false, // restart videos
        });
      }

      this.launch();
    }

    if (this.movedToNextVideo(prevState)) {
      this.setVideoTransitioning();
      this.rewindVideo(prevState.currentVideo);
    }
  }

  setVideoTransitioning = () => {
    this.onVideoTransitionStart();
    schedule("setVideoTransitioning", this.onVideoTransitionEnd, 2000);
  };

  initFullScreenListener() {
    this.screenfullCallback = () => {
      this.setState({ isFullscreen: screenfull.isFullscreen });
    };

    if (screenfull.isEnabled) {
      screenfull.on("change", this.screenfullCallback);
    }
  }

  /**
   * Sets listener for eventgoer count.
   * @deprecated
   * @method
   */

  initEventgoerCountListener = () => {
    return this.props.eventgoerCountRef.on("value", (countSnapshot) => {
      this.setState({
        eventgoerCount: !countSnapshot.val()
          ? 0
          : countSnapshot.val() - (this.props.isBackstage ? 0 : 1),
      });
    });
  };

  /**
   * Listens for status changes for each eventgoer and sets eventgoerCount.
   * Replaces initEventgoerCountListener
   * @method
   */

  initEventgoerStatusListener = () => {
    this.props.eventgoerStatusRef.on("value", (snapshot) => {
      let eventgoerCount = 0;

      snapshot.forEach((childSnapshot) => {
        const key = childSnapshot.key;
        const childData = childSnapshot.val();

        if (childData.status === "online") {
          eventgoerCount = eventgoerCount + 1;
        }
      });

      this.setState({ eventgoerCount });
    });
  };

  initSalesListener = () => {
    this.removeSalesListener = this.props.firebase.firestore
      .collectionGroup("sales")
      .where("metadata.stage_id", "==", this.props.stage.id)
      .where("metadata.eventgoer_id", "==", this.props.eventgoer.id)
      .onSnapshot((snap) => {
        const sales = snap.docs.map((doc) => doc.data());
        this.props.actions.setSales(sales);
      });
  };

  /**
   * Calls onStagehallUpdate() on updates to stagehall
   * @method
   */

  initStagehallUpdateListener = () => {
    this.removeStagehallUpdateListener =
      this.removeStagehallUpdateListener ||
      this.props.stagehallRef.onSnapshot(
        _.debounce(this.onStagehallUpdate, 1000)
      );
  };

/**
 * Sets stagehall in redux, calls launch(), and calls scheduleSegments()
 * @method
 * @param {*} doc firebase docRef
 */

  onStagehallUpdate = (doc) => {
    // cases where media segments are updated while viewing
    this.props.actions.setStagehall(doc.data());

    if (this.props.stagehall) {
      // console.log('onStagehallUpdate, scheduleSegments()')

      this.launch();
      this.scheduleSegments();
    }
  };

  movedToNextVideo = (prevState) => {
    return (
      prevState.currentVideo &&
      this.state.currentVideo &&
      prevState.currentVideo.id !== this.state.currentVideo.id
    );
  };

  onlyTimeRemainingChanged = (nextProps, nextState) => {
    const sameProps = _.isEqual(nextProps, this.props);
    const sameNonTimeRemainingState = _.isEqual(
      _.omit(nextState, "timeRemaining"),
      _.omit(this.state, "timeRemaining")
    );

    return sameProps && sameNonTimeRemainingState;
  };

  segmentDataUpdated = (prevState) => {
    return (
      prevState.currentSegment &&
      prevState.currentSegment.id === (this.state.currentSegment || {}).id &&
      prevState.currentSegment === this.state.currentSegment
    );
  };

  videoWillEndPrematurely(videoId, segment, opts) {
    // Default to false
    return false;

    const video = this.props.stagehall.getMedia(videoId);

    if (!video || !segment) return false;

    const remainderOfSegment = m.duration(m(segment.endAt).diff()).asSeconds();

    if (opts.videoWillSync) {
      // remainder === remainder of vid if triggered on initial load
      const data = this.props.stagehall.getActiveVideoData();
      const videoRemainder = data.timeUntilNext;
      return videoRemainder > remainderOfSegment;
    } else {
      // remainder === entire duration if triggered after end of another vid
      const videoRemainder = m.duration(video.duration).asSeconds();

      console.log(
        "video remainder, remainderOfSegment",
        videoRemainder,
        remainderOfSegment
      );
      return videoRemainder > remainderOfSegment;
    }
  }

  rewindVideo(video) {
    const player = this.getReactPlayer(video.id);
    video && player && player.seekTo(0);
  }

  mediaSegmentChanged = (prevState) => {
    const segmentChanged = !_.isEqual(
      prevState.currentSegment,
      this.state.currentSegment
    );
    // console.log('mediasegmentchanged:', segmentChanged)
    return segmentChanged;
  };

  /**
   * Debounced function that gets active video data and either plays the first video of a new segment or plays based on schedule calculation.
   * Sets currentVideo and currentSegment to null if there is nothing scheduled.
   * @function
   */

  launch = _.debounce(
    () => {
      const data = this.props.stagehall.getActiveVideoData();
      if (data) {
        this.setNextVideoState(data);
      } else {
        this.setNoCurrentVideoState();
      }
    },
    500,
    { leading: true }
  );

  setNextVideoState = (data) => {
    const { getActiveSegment, getMedia } = this.props.stagehall;

    const activeSegment = getActiveSegment();

    if (
      this.videoWillEndPrematurely(data.video.id, activeSegment, {
        videoWillSync: true,
      })
    ) {
      this.setState({ showFallback: true });
    } else {
      if (this.state.videosStarted) {
        // this will happen when a new segment is triggered
        // should play first video of this new segment
        // console.log('set currentSegment: activeSegment')

        this.setState({
          currentSegment: activeSegment,
          showFallback: false,
          currentVideo: getMedia(activeSegment.mediaIds[0]),
          loading: false,
        });
        this.props.stagehallLoaded();
      } else {
        // this will happen on initial load or after a stagehall update
        // should play video based on schedule calculation
        // console.log('set currentSegment: activeSegment')

        this.setState({
          currentSegment: activeSegment,
          showFallback: data.video.fallback,
          currentVideo: getMedia(data.video.id),
          loading: false,
        });
        this.props.stagehallLoaded();
      }
    }
  };

  setNoCurrentVideoState = () => {
    // console.log('set currentSegment: null')

    this.setState({
      currentSegment: null,
      currentVideo: null,
      loading: false,
    });
    this.props.stagehallLoaded();
  };

  /**
   * Defines onStart and onStop callback functions and calls scheduleSegments()
   * @function
   */
  scheduleSegments() {
    const onStart = (segment) => {
      // console.log('scheduled onStart function called')

      if (isIOS() || isiPadOS()) {
        this.setState({
          inBystanderMode: true,
          currentSegment: segment,
          loading: false,
          isPlaying: false,
        });
      } else {
        this.setState({
          currentSegment: segment,
          loading: false,
          isPlaying: false,
        });
      }
      this.props.stagehallLoaded();
    };

    const onStop = (segment) => {
      // delay this so it fires after another segment's
      // onStart cb (if applicable) which would be scheduled at the same time
      // if this fires before there is a temporary moment of lack of an active segment
      // which affects display

      if ((isIOS() || isiPadOS()) && this.state.isFullscreen) {
        this.toggleFullscreen();
      }

      schedule(
        "onStopDelay-" + segment.id,
        () => {
          if (_.isEqual(this.state.currentSegment, segment)) {
            // console.log('scheduled segment end, set currentSegment: null')
            // console.log('videosStarted: false')

            this.setState({
              currentSegment: null,
              videosStarted: false,
            });
          }
        },
        100
      );
    };

    // console.log('onstart and onstop timeouts scheduled')
    scheduleSegments(
      this.props.stagehall.orderedMediaSegments,
      onStart,
      onStop
    );
  }

  currentVideo() {
    const { currentVideo } = this.state;
    return currentVideo && this.props.stagehall.getMedia(currentVideo.id);
  }

  playNext = () => {
    this.setState({ showFallback: true });
  };

  /**
   * For ReactPlayer only - Preloads videos in order by starting next video then stopping it once it's loaded. When this method is called on a player, it has already loaded. Syncs video to schedule.
   * @param {string} videoId
   * @function
   */

  onStart = (videoId) => {
    // console.log('onStart', videoId)
    const allMediaIds = flatMap(
      this.props.stagehall.orderedMediaSegments,
      (mediaSegment) => mediaSegment.mediaIds
    );
    const currentIndex = allMediaIds.indexOf(videoId);
    const mediaIdsToLoad = allMediaIds
      .slice(currentIndex + 1)
      .concat(allMediaIds.slice(0, currentIndex));
    const nextVideoId = mediaIdsToLoad[0];

    // indicate that the next video should start playing if hasnt been loaded or marked as needing loading
    if (!(typeof this.state[nextVideoId + "needsLoading"] === "boolean")) {
      this.setState({ [nextVideoId + "needsLoading"]: true });
    }

    // seek to correct spot
    this.syncVideoToSchedule(videoId);

    // console.log('videosStarted: true', 'needsLoading: false')

    // mark current video as already loaded
    this.setState({
      [videoId + "needsLoading"]: false,
      videosStarted: true,
      isPlaying: true,
    });
  };

  /**
   * For KalturaPlayer only - syncs video with schedule
   * @param {function} seek function from KalturaPlayer component
   * @function
   */

  onKalturaStart = (seek) => {
    const data = this.props.stagehall.getActiveVideoData();
    const startTime = data.timeIntoVideo;

    // Skip seeking if the video is a Kaltura Streaming video
    // if (!data.video.isLivestream) {
    // console.log('seek on start', startTime)
    seek(startTime);
    // }
    // console.log('videosStarted: true')
    this.setState({
      videosStarted: true,
    });
  };

  onKalturaLoaded = (videoId) => {
    this.setState({ [videoId + "needsLoading"]: false });
  };

  setIsPlaying = (bool) => {
    this.setState({ isPlaying: bool });
  };

  videosAlreadyStarted = () => {
    // this function should only run when videos initially start
    if (!this.currentVideo() || this.state.videosStarted) return true;

    // console.log('videosStarted: true')

    // this.setState({ videosStarted: true });

    return false;
  };

  isCurrentScheduledVideo = (videoId) => {
    const data = this.props.stagehall.getActiveVideoData();
    const isScheduled = data.video.id === videoId;

    return this.isCurrentVideo(videoId) && isScheduled;
  };

  reactPlayerSeek = (videoId) => {
    // if (this.isCurrentScheduledVideo(videoId)) {
    // splice into correct spot
    const data = this.props.stagehall.getActiveVideoData();
    const player = this.getReactPlayer(videoId);
    if (player !== null) {
      // console.log('reactPlayerSeek', videoId)
      return player.seekTo(Math.round(data.timeIntoVideo));
    }
    // }
  };

  syncVideoToSchedule = (videoId) => {
    // this function should only run when videos initially start
    // if (this.videosAlreadyStarted()) return;

    this.reactPlayerSeek(videoId);
  };

  togglePassIdDialog = () => {
    this.setState({ showingPassIdDialog: !this.state.showingPassIdDialog });
  };

  isPaidFor = (segment) => {
    return this.props.sales.some(
      (sale) => sale.paywallId === segment.paywallData.id
    );
  };

  handleViewingRestrictions = () => {
    const segment = this.state.currentSegment;

    if (!segment) return;

    if (
      (segment.passIdRequired && !this.hasPassId) ||
      (segment.paymentRequired && !this.isPaidFor(segment))
    ) {
      this.togglePassIdDialog();
      return true;
    }
  };

  toggleBystanderMode = () => {
    if (!this.state.isPlaying) return;

    const { stagehall, eventgoer, isBackstage } = this.props;
    const data = stagehall.getActiveVideoData();
    const { video } = data || {};

    // console.log('video (toggleBystander)', video)

    if (!isBackstage) {
      if (this.handleViewingRestrictions()) {
        return false;
      }

      const willBeUnmuted = this.state.inBystanderMode;
      stagehall.recordUnmutings(eventgoer.sessionId, willBeUnmuted);
    }

    if (this.state.inBystanderMode) {
      video.youtubeId && this.reactPlayerSeek(video.id);
      if (video.entryId) {
        const kalturaPlayer = document
          .getElementById(`kaltura-player-${video.id}`)
          .querySelector("video");
        if (kalturaPlayer) {
          // console.log('toggle bystander')
          if (!video.isLivestream) {
            // console.log('should seek')
            kalturaPlayer.currentTime = data.timeIntoVideo;
          }
          kalturaPlayer.paused && kalturaPlayer.play();
        }
      }
    }
    this.state.videosStarted &&
      this.setState({ inBystanderMode: !this.state.inBystanderMode });
  };

  onExitFullscreenIOS = () => {
    this.setState({ isFullscreen: false, inBystanderMode: true });
  };

  toggleFullscreen = () => {
    //screenfull not supported on iOS devices
    if (isIOS() || isiPadOS()) {
      const { isFullscreen } = this.state;
      const data = this.props.stagehall.getActiveVideoData();
      const { video } = data || {};
      if (isFullscreen) {
        try {
          // console.log('webkitDisplayingFullscreen', this.videoRef.current.webkitDisplayingFullscreen)
          this.videoRef.current.webkitExitFullscreen();
          this.setState({ isFullscreen: !isFullscreen });
        } catch {
          console.error("fullscreen error");
        }
      } else if (video && video.entryId) {
        const player = document
          .getElementById(`kaltura-player-${video.id}`)
          .querySelector("video");
        try {
          if (isFullscreen) {
          } else {
            player.webkitEnterFullscreen();
            player.addEventListener(
              "webkitendfullscreen",
              this.onExitFullscreenIOS,
              false
            );
            this.videoRef.current = player;
          }
          this.setState({ isFullscreen: !isFullscreen });
        } catch {
          console.error("fullscreen error");
        }
      }
      return;
    }
    if (screenfull.isEnabled) {
      if (this.state.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request(this.players);
      }
    }
  };

  handleVideoControlVisibility = () => {
    if (this.state.isFullscreen) {
      this.setState({ mouseActive: true });

      // clear and set timeout hide video controls
      clearTimeout(this.onMouseMoveTimeout);
      this.onMouseMoveTimeout = setTimeout(() => {
        this.setState({ mouseActive: false });
      }, 3000);
    }
  };

  isCurrentVideo = (videoId) => {
    return (
      (this.state.currentVideo || {}).id === videoId && !this.state.showFallback
    );
  };

  onVideoRemainderUpdated = (timeRemaining, videoId) => {
    if (videoId === this.state.currentVideo.id) {
      // when non-active players are preloading, onPlay will be
      // called but onVideoRemainderUpdated shouldn't be called
      this.setState({ timeRemaining });
    }
  };

  getAllSegments = () => {
    return _.flatMap(
      _.values(this.props.stagehall.mediaSegments),
      (segment) => {
        return segment.subSegments.length ? segment.subSegments : segment;
      }
    );
  };

  getNextSegment = _.memoize(
    (currentSegment = null) => {
      const allSegments = this.getAllSegments();
      const startsAfter = currentSegment ? currentSegment.endAt : Date.now();

      const sorted = _.sortBy(allSegments, "startAt");

      return _.find(sorted, ({ startAt }) => startAt >= startsAfter);
    },
    (currentSegment) => {
      if (currentSegment && this.props.stagehall.updatedAt) {
        return currentSegment.id + this.props.stagehall.updatedAt.toDate();
      } else {
        return Date.now();
      }
    }
  );

  getNextVideo = _.memoize(
    (currentVideo, currentSegment) => {
      if (!currentSegment) {
        const nextVideoId = this.getNextSegment().mediaIds[0];
        return nextVideoId ? this.props.stagehall.getMedia(nextVideoId) : null;
      } else {
        const nextVideoIdx = this.getNextVideoIdx(currentSegment, currentVideo);
        const nextVideo = currentSegment.schedule[nextVideoIdx];

        if (!nextVideo) return null;

        return this.props.stagehall.getMedia(nextVideo.id) || {};
      }
    },
    (currentVideo) => {
      if (!this.props.stagehall.updatedAt) {
        // TODO figure out why this happens
        return currentVideo && currentVideo.id;
      } else {
        return (
          currentVideo &&
          currentVideo.id + this.props.stagehall.updatedAt.toDate()
        );
      }
    }
  );

  /**
   * Renders NowPlaying component
   * @returns {ReactElement} NowPlaying
   * @function
   */

  renderScheduleDisplay = () => {
    const { currentSegment, currentVideo, timeRemaining } = this.state;

    const nextVideo = this.getNextVideo(currentVideo, currentSegment);
    const nextSegment = this.getNextSegment(currentSegment);

    let isBehindSchedule;
    if (timeRemaining) {
      const timeAtEndOfVid = Date.now() + timeRemaining * 1000;
      const data = this.props.stagehall.getActiveVideoData(timeAtEndOfVid);
      isBehindSchedule = this.isBehindSchedule(currentVideo, data);
    }

    const nextFallbackDurationMs = this.nextFallbackDuration(
      currentSegment,
      isBehindSchedule
    );

    return (
      <NowPlaying
        stagehall={this.props.stagehall}
        currentSegment={currentSegment}
        buffering={this.state.buffering}
        videoTransitioning={this.state.videoTransitioning}
        nextSegment={nextSegment}
        currentVideo={currentVideo}
        nextVideo={nextVideo}
        videoTimeRemaining={timeRemaining}
        showingFallback={this.state.showingFallback}
        nextFallbackDurationMs={nextFallbackDurationMs}
      />
    );
  };

  /**
   * Renders video controls, including mute button, volume slider, bystander shade, and fullscreen buttons.
   * @returns {ReactElement} Stagehall_videoControls
   * @function
   */

  renderVideoControls = () => {
    return (
      <>
        <div
          className={cx("Stagehall_videoControls", {
            "Stagehall_videoControls-active":
              (!this.state.inBystanderMode && !this.state.isFullscreen) ||
              (this.state.isFullscreen && this.state.mouseActive),
          })}
        >
          <div
            className='Stagehall_videoControls-left'
            onClick={this.toggleBystanderMode}
          >
            <MutedIcon />
            <span className='text-medium'>Toggle Bystander</span>
          </div>
          {!isIOS() && !isiPadOS() && (
            <div className='Stagehall_videoControls-right'>
              <VolumeSlider
                defaultVolume={this.state.volume}
                maxVolume={100}
                onChange={(volume) => this.setState({ volume })}
              />
            </div>
          )}
        </div>

        <div
          className={cx("Stagehall_bystanderShade", {
            active: this.state.inBystanderMode,
          })}
          onClick={
            this.state.inBystanderMode ? this.toggleBystanderMode : () => {}
          }
        >
          {this.state.isPlaying ? (
            <MutedIcon />
          ) : (
            <CircularProgress color='secondary' thickness={4} size={56} />
          )}
          {this.props.mobile && this.state.isPlaying && (
            <div className='text-large'>Moving picture? Tap on in!</div>
          )}
        </div>

        <FullScreenIcon
          className={cx("Stagehall_fullscreenControl", {
            "Stagehall_videoControls-active":
              !this.state.inBystanderMode && !this.state.isFullscreen,
          })}
          onClick={this.toggleFullscreen}
        />

        <FullScreenExitIcon
          className={cx(
            "Stagehall_fullscreenControl Stagehall_fullscreenExit",
            {
              "Stagehall_videoControls-active":
                this.state.isFullscreen && this.state.mouseActive,
            }
          )}
          onClick={this.toggleFullscreen}
        />
      </>
    );
  };
  getNextVideoIdx = (currentSegment, currentVideo) => {
    if (!currentVideo) return -1;

    const currentVideoIdx = findIndex(currentSegment.schedule, {
      id: currentVideo.id,
    });
    const indexOffset = currentVideo.fallback ? 1 : 2;
    return (currentVideoIdx + indexOffset) % currentSegment.schedule.length;
  };

  shouldTriggerNextVideo = (currentVideo, origSegment, nextVideo) => {
    // determines if next video should be triggered from Fallback ending callback

    const videoWithinSegment = currentVideo
      ? origSegment.mediaIds.includes(currentVideo.id)
      : true;
    // this could be false if segment updates while fallback is showing but state.currentVideo hasn't updated

    const segmentUnchanged = origSegment === this.state.currentSegment;
    const videoShorterThanSegmentRemainder = !this.videoWillEndPrematurely(
      nextVideo.id,
      origSegment,
      { videoWillSync: false }
    );

    return (
      videoWithinSegment &&
      segmentUnchanged &&
      videoShorterThanSegmentRemainder &&
      this.state.currentSegment
    );
  };

  nextFallbackDuration = (segment, isBehindSchedule) => {
    if (isBehindSchedule) {
      return 1000;
    } else if (segment) {
      return segment.videoPadding * 1000;
    } else {
      return 5000; // minimum video padding
    }
  };

  isBehindSchedule = (video, activeVideoData) => {
    return !!(
      video &&
      activeVideoData &&
      activeVideoData.video.id !== video.id
    );
  };

  onBufferStart = () => {
    this.setState({ buffering: true });
  };

  onBufferEnd = () => {
    this.setState({ buffering: false });
  };

  onVideoTransitionStart = () => {
    this.setState({
      videoTransitioning: true,
    });
  };

  onVideoTransitionEnd = () => {
    this.setState({ videoTransitioning: false });
  };

  /**
   * Renders ReactPlayer with fallback image and no play button and a callback to trigger the next video.
   * @returns {ReactElement} Fallback
   * @function
   */
  renderInterVideoFallback = () => {
    const { currentVideo, currentSegment } = this.state;
    const { getActiveVideoData, getMedia } = this.props.stagehall;
    const data = getActiveVideoData();

    let nextVideo, nextVideoIdx;

    if (currentVideo && currentSegment) {
      // if already playing(ed) current video, play next video.
      nextVideoIdx = this.getNextVideoIdx(currentSegment, currentVideo);
      let nextScheduleItem = currentSegment.schedule[nextVideoIdx];
      if (nextScheduleItem.fallback) {
        nextScheduleItem = currentSegment.schedule[nextVideoIdx + 1];
      }

      nextVideo = getMedia(nextScheduleItem.id);
    } else if (data) {
      // if no current video calculate next vid from schedule
      nextVideo = getMedia(data.nextVideoId);
    }

    const isBehindSchedule = this.isBehindSchedule(currentVideo, data);

    const onEnded = () => {
      if (
        this.shouldTriggerNextVideo(currentVideo, currentSegment, nextVideo)
      ) {
        this.setState({
          showFallback: nextVideo ? false : true,
          currentVideo: nextVideo,
        });
      }
    };

    return (
      <Fallback
        imageUrl={this.props.stage.fallbackImageUrl}
        duration={this.nextFallbackDuration(currentSegment, isBehindSchedule)}
        onEnded={onEnded}
        nextVideoId={(nextVideo || {}).id}
      />
    );
  };

  /**
   * Calls renderPlayer() for each segment.
   * @function
   * @returns {function} renderPlayer function for each segment
   */

  renderPlayers = () => {
    const mediaIds = flatMap(
      this.props.stagehall.mediaSegments,
      (mediaSegment) => mediaSegment.mediaIds
    );

    return mediaIds.map((id, idx) => {
      const video = this.props.stagehall.getMedia(id);
      return this.renderPlayer(video, idx);
    });
  };

  renderKalturaPlayer = (video, isCurrentVideo) => {
    const { volume, inBystanderMode, videosStarted } = this.state;

    return (
      <KalturaPlayer
        video={video}
        playing={isCurrentVideo && !this.state[video.id + "needsLoading"]}
        muted={inBystanderMode || !isCurrentVideo || !videosStarted}
        volume={volume / 100}
        onStart={this.onKalturaStart}
        onLoaded={() => this.onKalturaLoaded(video.id)}
        onEnded={this.playNext}
        className='Stagehall_player'
        onVideoRemainderUpdated={(timeRemaining) =>
          this.onVideoRemainderUpdated(timeRemaining, video.id)
        }
        onBufferStart={this.onBufferStart}
        onBufferEnd={this.onBufferEnd}
        toggleBystanderMode={this.toggleBystanderMode}
        setIsPlaying={this.setIsPlaying}
        inBystanderMode={inBystanderMode}
      />
    );
  };

  getReactPlayer = (videoId) => {
    return this[videoId];
  };

  setReactPlayer = (videoId, player) => {
    return (this[videoId] = player);
  };

  onPlay = (videoId) => {
    const player = this.getReactPlayer(videoId);
    if (player) {
      const duration = player.getDuration();
      const playedSecs = player.getCurrentTime();

      this.onVideoRemainderUpdated(duration - playedSecs, videoId);
      if (!this.state.isPlaying) {
        this.setState({ isPlaying: true });
      }
    }
  };

  renderReactPlayer = (video, isCurrentVideo) => {
    const { volume, inBystanderMode, videosStarted } = this.state;

    return (
      <ReactPlayer
        ref={(player) => this.setReactPlayer(video.id, player)}
        className='Stagehall_player'
        // in case of blocking Safari
        // playing={
        //   (isCurrentVideo || this.state[video.id + 'needsLoading']) &&
        //   ((this.props.isSafari ? this.state.viewingStagehallOnMobile : true) ||
        //     (this.props.isSafari ? !this.state.inBystanderMode : true))
        // }
        playing={isCurrentVideo && !this.state[video.id + "needsLoading"]}
        // onProgress={(event) => console.log('onprogress', event)}
        onPlay={() => this.onPlay(video.id)}
        onBuffer={this.onBufferStart}
        onBufferEnd={this.onBufferEnd}
        url={video.url}
        volume={volume / 100}
        muted={inBystanderMode || !isCurrentVideo || !videosStarted}
        onEnded={this.playNext}
        onReady={() => this.onStart(video.id)}
        config={{
          youtube: {
            playerVars: {
              origin: window.location.origin,
              controls: 0,
              modestbranding: 1,
              rel: 0,
            },
          },
        }}
        width='100%'
        height='100%'
        onError={console.error}
      />
    );
  };

  /**
   * Renders video controls and ReactPlayer or KalturaPlayer as appropriate.
   * @function
   * @param {object} video 
   * @param {number} idx 
   * @returns {ReactElement} Stagehall_playerWrapper
   */

  renderPlayer = (video, idx) => {
    const isCurrentVideo = this.isCurrentVideo(video.id);

    return (
      <div
        onMouseMove={this.handleVideoControlVisibility}
        key={video.id + String(idx)}
        className={cx("Stagehall_playerWrapper", {
          active: isCurrentVideo,
        })}
      >
        {this.renderVideoControls()}
        {video.youtubeId && this.renderReactPlayer(video, isCurrentVideo)}
        {video.entryId && this.renderKalturaPlayer(video, isCurrentVideo)}
      </div>
    );
  };

  /**
   * Renders a ReactPlayer with fallback image only without callback.
   * @returns {ReactElement} ReactPlayer
   * @function
   */

  renderInterSegmentFallback() {
    const hasScheduledMedia = _.some(
      this.props.stagehall.mediaSegments,
      (segment) => {
        return segment.mediaIds;
      }
    );

    return (
      <div>
        <div className='Stagehall_fallbackWrapper active'>
          <ReactPlayer
            className='Stagehall_player'
            playIcon={<p></p>}
            playing={false}
            url={"https://www.youtube.com"}
            light={this.props.stage.fallbackImageUrl}
            width='100%'
            height='100%'
          />
        </div>
        {this.props.isBackstage && !hasScheduledMedia && (
          <p>Schedule some media to initialize player.</p>
        )}
      </div>
    );
  }

  onClaim = async (values) => {
    const { eventgoer, eventgoersContactRef, stage } = this.props;

    // create eventgoersContact
    eventgoersContactRef.set({
      email: values.email,
      notificationsOn: true,
    });

    const hash = await bcrypt.hash(eventgoer.id + "unsubscribe", 8);

    let aliasUrl =
      "https://kontomo.com/wp-content/uploads/2019/12/Kontomo_logo_w_wordmark_on_transparent_bgd.png";

    if (stage.organizer && stage.organizer.alias) {
      const storage = firebase.storage();
      const ref = storage.ref(stage.organizer.alias.avatarStoragePaths.md);
      aliasUrl = await ref.getDownloadURL();
    }

    // send email
    const mailData = {
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),

      to: values.email,
      template: {
        name: "eventgoerClaim",
        data: {
          privateLink: window.location.href + "?myUniquePassID=" + eventgoer.id,
          unsubscribeLink: `${window.location.origin}/unsubscribe?token=${hash}&eid=${eventgoer.id}`,
          email: values.email,
          stageTitle: stage.title,
          stageDateLocation: (stage.organizer && stage.organizer.address) || "",
          organizer: (stage.organizer && stage.organizer.alias.name) || "",
          organizerImageLink: aliasUrl,
        },
      },
    };

    this.setState({ linkClaimed: true });

    return this.props.mailRef
      .doc("claim_eventgoer-" + Date.now())
      .set(mailData);
  };

  renderEventgoerIdClaim() {
    const claimId = () => this.setState({ showClaimForm: true });
    return (
      <div>
        {/* <Button
          variant="contained"
          disabled={this.state.showClaimForm}
          onClick={claimId}
        >
          Get Pass ID
        </Button> */}
        {this.state.showClaimForm && (
          <Formik
            initialValues={{ email: "" }}
            onSubmit={this.onClaim}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
            })}
          >
            {({ isSubmitting }) => (
              <Form className='Stagehall-claimForm'>
                <div>
                  <Field
                    color='secondary'
                    disabled={isSubmitting || this.props.eventgoer.confirmed}
                    component={TextField}
                    type='email'
                    name='email'
                    label='email'
                  />
                </div>

                <br />
                <Button
                  variant='contained'
                  type='submit'
                  disabled={
                    isSubmitting ||
                    this.props.eventgoer.confirmed ||
                    this.state.linkClaimed
                  }
                >
                  {this.state.linkClaimed ? "Claimed!" : "Claim"}
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => this.setState({ showClaimForm: false })}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className='Stagehall'>
        <CircularProgress />
      </div>
    );
  }

  onNotifToggleChange = (value) => {
    return this.props.eventgoersContactRef.set(
      { notificationsOn: value },
      { merge: true }
    );
  };

  get hasPassId() {
    return window.location.search.match(
      "/?myUniquePassID=" + this.props.eventgoer.id
    );
  }

  segmentMediaTitles = (segment) => {
    if (!segment) return;

    return segment.mediaIds.map((id) => {
      return <div key={id}>{this.props.stagehall.getMedia(id).title}</div>;
    });
  };

  hasUpcomingSegment = () => {
    // returns true if a segment is scheduled in the future
    // doesn't apply to
    return _.some(this.getAllSegments(), (segment) => {
      return segment.startAt > Date.now() && segment.mediaIds.length;
    });
  };

  /**
   * <description>Main render function - Renders PlayerHeader, players, MediaSegments (if backstage), Toggle for notifications, and PassIdDialogue for ticket sales.</description>
   * <p><b>Note:</b> Players are rendered indivdually for each segment/subsegment. So a schedule with multiple segments renders a stack of video player elements, and is very expensive.</p> 
   * @returns {ReactElement} Stagehall-wrapper
   */

  render() {
    if (this.state.loading) return this.renderLoading();

    const {
      stage,
      stagehall: {
        getMedia,
        addMedia,
        addMediaSegment,
        updateMediaSegment,
        destroyMediaSegment,
      },
      eventgoersContact,
      eventgoer,
      firebase,
      isBackstage,
      mobile,
    } = this.props;

    const {
      currentVideo,
      currentSegment,
      viewingStagehallOnMobile,
      eventgoerCount,
      isFullscreen,
      showFallback,
      showingPassIdDialog,
    } = this.state;

    const hasCurrentMedia = currentSegment && currentSegment.mediaIds.length;

    const queryingEventgoerId =
      window.location.search.match("/?myUniquePassID=");

    const shouldShowFallback =
      showFallback || (currentVideo && currentVideo.id.match("fallback"));


    return (
      <div className='Stagehall-wrapper'>
        {!this.props.mobile &&
          this.props.renderLeftPick(() =>
            this.setState({
              viewingStagehallOnMobile: !viewingStagehallOnMobile,
            })
          )}

        <div className='Stagehall'>
          {this.props.shouldShowIOSWarning &&
            isiPadOS() &&
            this.renderInterVideoFallback()}

          {!this.props.shouldShowIOSWarning &&
            (!mobile || window.innerHeight > window.innerWidth ? (
              <PlayerHeader
                eventgoerCount={eventgoerCount}
                eventgoer={eventgoer}
                organizer={stage.organizer}
                isBackstage={isBackstage}
                handleDonationsOn={this.props.actions.setDonationsOn}
                donationsOn={this.props.donationsOn}
                firebase={this.props.firebase}
                stageId={stage.id}
                mobile={mobile}
                stage={stage}
                stagehall={this.props.stagehall}
                handlePreSalesEnabled={this.props.actions.setPreSalesEnabled}
              />
            ) : null)}

          {!this.props.shouldShowIOSWarning && (
            <div className='Stagehall_viewboxWrapper'>
              <div
                ref={(el) => (this.players = el)}
                className={cx("Stagehall_players", {
                  "Stagehall_players-fullscreen": isFullscreen,
                })}
              >
                {hasCurrentMedia
                  ? this.renderPlayers()
                  : this.renderInterSegmentFallback()}
              </div>

              {hasCurrentMedia &&
                shouldShowFallback &&
                this.renderInterVideoFallback()}
            </div>
          )}

          {!mobile || window.innerHeight > window.innerWidth ? (
            <div className='Stagehall-lower'>
              {hasCurrentMedia &&
                !this.props.shouldShowIOSWarning &&
                this.renderScheduleDisplay()}

              {isBackstage && (
                <MediaSegments
                  mediaSegments={this.props.stagehall.orderedMediaSegments}
                  getMedia={getMedia}
                  addMedia={(data, segmentId) =>
                    addMedia(data, segmentId, stage.id)
                  }
                  addMediaSegment={(data) => addMediaSegment(data, stage.id)}
                  destroyMediaSegment={(segmentId) =>
                    destroyMediaSegment(segmentId, stage.id)
                  }
                  updateMediaSegment={(data) =>
                    updateMediaSegment(data, stage.id)
                  }
                />
              )}

              {!isBackstage && queryingEventgoerId && (
                <Toggle
                  checked={eventgoersContact.notificationsOn}
                  onChange={this.onNotifToggleChange}
                  name='notification-toggle'
                  label={`Email notifications ${
                    eventgoersContact.notificationsOn ? "on" : "off"
                  }`}
                />
              )}

              {!this.props.isBackstage &&
                !queryingEventgoerId &&
                this.renderEventgoerIdClaim()}
            </div>
          ) : null}

          {!this.props.isBackstage && (
            <PassIdDialog
              open={showingPassIdDialog}
              onClose={this.togglePassIdDialog}
              mediaSegment={currentSegment}
              segmentMediaTitles={this.segmentMediaTitles(currentSegment)}
              eventgoer={eventgoer}
              passIdLink={`${window.location.href}?myUniquePassID=${eventgoer.id}`}
              firebase={firebase}
              stage={stage}
              mobile={mobile}
            />
          )}
        </div>
      </div>
    );
  }
}

Stagehall.defaultProps = {
  eventgoer: {},
};

Stagehall.propTypes = {
  stagehall: PropTypes.object,
  stage: PropTypes.object.isRequired,
  eventgoer: PropTypes.object,
  stagehallRef: PropTypes.object.isRequired,
  eventgoerCountRef: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
};

export default Stagehall;
