import FirebaseModule from 'firebase/app';

export const createNewChannel = (
  channels,
  newChannel,
  channelsOrder,
  setChannels,
  setChannelsOrder,
  setChannelsIndexes,
  channelsIndexes,
  channelsCount,
  db,
  demoEventfeed,
  firebase,
  setNewChannel,
  setChannelsCount,
  setCurrentChannel,
  setPostIds,
  setData,
  setPostObjects
) => {
  if (newChannel === "") {
    console.log("Empty channel. No channel created.")
    return;
  }

  let update = { ...channels };
  update[channelsCount] = [];

  let orderUpdate = [...channelsOrder, newChannel];

  setChannels(update);
  setChannelsOrder(orderUpdate);

  setChannelsIndexes([...channelsIndexes, channelsCount]);

  const ref = db.collection("eventfeeds").doc(demoEventfeed);

  ref
    .get()
    .then(function (doc) {
      if (doc.exists) {
        ref.update({
          "channelMap.channelNames": [
            ...doc.data().channelMap.channelNames,
            newChannel,
          ],
          "eventfeedUnpub.channelOrder": FirebaseModule.firestore.FieldValue.arrayUnion(
            channelsCount
          ),
        });
        let channelContentsUpdate = {};
        channelContentsUpdate[
          `eventfeedUnpub.${channelsCount}.posts`
        ] = [];
        ref.update(channelContentsUpdate);

        setPostIds([]);
        setData([]);
        setPostObjects([]);
        setCurrentChannel(channelsCount);
        localStorage.setItem("localStorageChannel", channelsCount);
        setChannelsCount(channelsCount + 1);
        setNewChannel("");
      } else {
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });

};
