import React, { PureComponent } from "react";
import firebase from "firebase/app";
import CircularProgress from "@material-ui/core/CircularProgress";
import DefaultAvatar from "../../assets/icons/Stage/default-avatar.png";

import "firebase/storage";
import "./styles.scss";

class Avatar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      aliasUrl: null
    }
  }

  componentDidMount() {
    this.setDownloadUrl();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ aliasUrl: null });
      this.setDownloadUrl();
    }
  }

  async setDownloadUrl() {
    if (!this.props.imageUrl) return;

    const storage = firebase.storage();
    const ref = storage.ref(this.props.imageUrl);
    const aliasUrl = await ref.getDownloadURL();

    this.setState({ aliasUrl });
  }

  renderLoading() {
    return (
      <div className="">
        <CircularProgress />;
      </div>
    );
  }

  render() {
    if (!this.state.aliasUrl) this.renderLoading();

    if (!this.props.imageUrl) {
      return (
        <div
          className={"avatar " + (this.props.className || "")}
          style={{
            backgroundImage: `url(${DefaultAvatar})`,
            backgroundColor: "#fff",
          }}
        />
      );
    } else if (this.props.isStatementImage) {
      return <img src={this.state.aliasUrl} style={{width: 60, height: 60, borderRadius: 17 }}/>;
    } else {
      return (
        <div
          className={"avatar " + (this.props.className || "")}
          style={{
            backgroundImage: `url(${this.state.aliasUrl})`,
          }}
        />
      );
    }

  };
}

export default Avatar;
