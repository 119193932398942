import React from "react";
import { Provider } from "react-redux";
import { store } from "../redux/app-redux";
import { HelmetProvider } from "react-helmet-async";
import App from "./App.js";

// provide redux store to App
export default () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  );
};
