export const reorder = (array, entityToBeMoved, moveToAfter, oldIndex) => {
  const originalPos = oldIndex ? oldIndex : array.indexOf(entityToBeMoved);

  if (moveToAfter < 0 || moveToAfter > array.length) {  
    console.log('outside the array');
    return array;
  }

  if (originalPos === moveToAfter || originalPos === (moveToAfter - 1)) {
    console.log ('same position');
    return array;
  }
  
  if (originalPos > moveToAfter) {
    array.splice(originalPos, 1);
    array.splice(moveToAfter, 0, entityToBeMoved);
    return array;
  };
  
  array.splice(originalPos, 1);
  array.splice((moveToAfter - 1), 0, entityToBeMoved);
  return array;
}