import React, { useState, useEffect, useToggle } from "react";
import Modal from "@material-ui/core/Modal";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { StyledButton } from "../../../../components/StyledComponents/StyledButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as TicketIcon } from "../../../../assets/icons/ticket.svg";
import cancel from "../../../../assets/icons/Paywall/cancel.svg";
import arrowLeft from "../../../../assets/icons/Arrows/arrowLeft.svg";
import arrowRight from "../../../../assets/icons/Arrows/arrowRight.svg";
import closeIcon from "../../../../assets/icons/Paywall/close.svg";
import PassIdDialog from "../PassIdDialog";
import PaymentStep from "../PassIdDialog/components/PaymentStep";
import firebase from "firebase/app";
import { Schedule } from "@material-ui/icons";
import { filter } from "lodash";

const StyledModal = styled(Modal)`
  transition: opacity 300ms ease-in-out;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.4);
  overflow: auto;
`;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 14px 20px 14px !important;
  width: 316px;
  height: auto;
  border-radius: 17px;
  left: unset;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  background: #fbfbfb;

  @media (max-height: 450px) {
    transform: translateY(-30%);
  }
`;
const ModalInfo = styled.h4`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.004em;
  color: #292663;
  margin: 0;
  margin-top: 1.25rem;
`;
const ModalText = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6875rem;
  text-align: center;
  letter-spacing: 0.02em;
  color: #160f3e;
  padding:0.625rem;
  margin:0;
  margin-top:1.25rem;

  ${(props) => {
    switch (props.size) {
      case "important":
        return css`
          font-size: 1.375rem;
          line-height: 1.6875rem;
          text-align: left;
          padding: 0;
        `;
      default:
        break;
    }
  }}
  }
`;

const ModalAlias = styled(ModalInfo)`
  margin: 0;
  margin-top: 2rem;
`;
const DescriptionTextWrapper = styled.div`
  width: 288px;
  left: 0px;
  top: 190px;
  border-bottom: 1px solid #a1a1a1;
`;
const DescriptionText = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #160f3e;
  margin: 0;
  margin-top: 1.25rem;
  padding-bottom: 0.875rem;
`;
const ModalPrice = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.004em;
  color: #160f3e;
  margin: 0;
  margin-top: 1.25rem;
`;
const ModalWrapper = styled.div`
  margin-left: auto;
`;
const ShowStart = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #625dc1;
  margin: 0;

  ${(props) => {
    switch (props.variant) {
      case "hour":
        return css`
          letter-spacing: 0.004em;
          color: #ef4e23;
        `;
      default:
        break;
    }
  }}
`;
const NavWrapper = styled.div`
  display: flex;
  flex-director: row;
  margin: 0;
  margin-top: 1.25rem;
  margin-bottom: 0;
`;
const NavButton = styled(StyledButton)`
  width: 120px;
  height: 36px;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  margin: 0;

  ${(props) => {
    switch (props.direction) {
      case "left":
        return css`
          margin-right: auto;
        `;
      case "right":
        return css`
          margin-left: auto;
        `;
      default:
        break;
    }
  }}
  }
`;
const TicketButton = styled(StyledButton)`
  margin-left: 0;
`;
const ChooseShowButton = styled(StyledButton)`
  margin: 0;
  margin-top: 1.25rem;
  width: 214px;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;

  p {
    padding: 0;
    margin: 0;
  }
`;
export const PreshowModal = ({
  showsPressed,
  handleClick,
  organizer,
  shows,
  stageId,
  setShowPayScreen,
  setSelectedSegment,
  mobile,
  firebase: { firestore },
}) => {
  //ShowInfo is the description and button logic.
  const ShowsInfo = ({ shows }) => {
    let sortedShows = shows;
    sortedShows.sort(
      (a, b) => parseFloat(a.startAt.seconds) - parseFloat(b.startAt.seconds)
    );
    //using index to track what page we are on.
    const [index, setIndex] = useState(0);
    //max is the amount of shows - 1 so that index has a limit.
    const max = shows.length - 1;
    const pageUp = () => {
      setIndex(index + 1);
    };
    const pageDown = () => {
      setIndex(index - 1);
    };

    const handlePay = () => {
      setSelectedSegment(shows[index]);
      setShowPayScreen(true);
    };
    const NextButton = () => (
      <NavButton
        direction="right"
        id="nextButton"
        variety="primary"
        onClick={pageUp}
      >
        <img src={arrowRight} style={{ height: 32, width: 32 }}></img>
      </NavButton>
    );
    const PrevButton = () => (
      <NavButton
        direction="left"
        id="prevButton"
        variety="secondary"
        onClick={pageDown}
      >
        <img src={arrowLeft} style={{ height: 32, width: 32 }}></img>
      </NavButton>
    );

    function navButtons() {
      //show both buttons if #ofshows isn't 1, that index isn't maxed, and that index isn't 0
      if (max != 0 && index < max && index != 0) {
        return (
          <NavWrapper>
            <PrevButton />
            <NextButton />
          </NavWrapper>
        );
        //if there are more than 1 shows, and page is showing the first show, only show the next button
      } else if (max > 0 && index == 0) {
        return (
          <NavWrapper>
            <NextButton />
          </NavWrapper>
        );

        //if index == number of shows, only show prev button
      } else if (index == max && max != 0) {
        return (
          <NavWrapper>
            <PrevButton />
          </NavWrapper>
        );
        //if only 1 show, don't show any nav buttons
      } else if (max == 0) {
        return null;
      }
    }

    const time = new Date(shows[index]?.startAt.seconds * 1000);
    const timeOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const timeString = time.toString().match(/\(([^)]+)\)/)[1];

    //if there are no shows
    if (shows.length === 0) {
      return null;
    }
    console.log(shows);
    return (
      <>
        <ModalText>Scheduled</ModalText>
        <ShowStart>{time.toLocaleDateString("en-us", timeOptions)}</ShowStart>
        <ShowStart variant="hour">
          {time.toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </ShowStart>
        <ShowStart variant="hour">{timeString}</ShowStart>
        {sortedShows[index]["paid"] ? (
          <>
            {console.log(sortedShows)}
            <ModalText size="important">
              You already have access to this show.
            </ModalText>
          </>
        ) : (
          <>
            <ChooseShowButton variety="special" onClick={handlePay}>
              Choose this show
            </ChooseShowButton>
            {/* Check to see if there is a pay what you can or not */}
            {sortedShows[index].paywallData.amount == 0 ? (
              <ModalPrice>Pay what you wish</ModalPrice>
            ) : (
              <ModalPrice>
                {sortedShows[index].paywallData.currencySymbol}
                {sortedShows[index].paywallData.amount}
              </ModalPrice>
            )}
          </>
        )}
        <DescriptionTextWrapper>
          <DescriptionText>
            {sortedShows[index].paywallData.description}
          </DescriptionText>
        </DescriptionTextWrapper>

        {navButtons()}
      </>
    );
  };
  return (
    <StyledModal
      className="modalOverlay"
      open={showsPressed}
      onClose={handleClick}
    >
      <ModalContainer className="modalContainer">
        <ModalWrapper>
          <img
            className="close-icon"
            alt="Close Dialog"
            onClick={handleClick}
            src={closeIcon}
            style={{
              position: "absolute",
              top: mobile ? 9 : 20,
              right: mobile ? 9 : 20,
              width: 32,
              height: 32,
              background: "#CBC9EA",
              borderRadius: "50%",
            }}
          />
        </ModalWrapper>
        <ModalAlias className="modalAlias">{organizer.alias.name}</ModalAlias>
        {shows.length === 0 ? (
          <>
            <ModalInfo>No shows scheduled at this time</ModalInfo>
            <Button
              style={{
                width: "120px",
                marginLeft: "0.25rem",
                marginRight: "2.5rem",
                marginTop: 20,
              }}
              variant="contained"
              className="btn btn-tertiary"
              onClick={handleClick}
            >
              <img src={cancel} style={{ height: 24, width: 24 }}></img>
            </Button>
          </>
        ) : (
          <>
            <ModalInfo>presents</ModalInfo>
            <ShowsInfo shows={shows} firebase={firebase} />
          </>
        )}
      </ModalContainer>
    </StyledModal>
  );
};

export const ShowsButton = ({
  stage,
  eventgoer,
  firebase: { firestore },
  firebase,
  stagehall,
  mobile,
}) => {
  //YOU MUST DECLARE FIREBASE AND FIRESTORE LIKE THIS. ^^^
  const { id, title, starId } = stage;
  const [showsPressed, setShowsPressed] = useState(false);
  const [shows, setShows] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState();
  const [showPayScreen, setShowPayScreen] = useState(false);
  const organizer = stage.organizer;
  const handleClick = () => {
    setShowsPressed(!showsPressed);
  };
  const segmentMediaTitles = (segment) => {
    if (!segment) return;

    return segment.mediaIds.map((id) => {
      return <div key={id}>{stagehall.getMedia(id).title}</div>;
    });
  };
  useEffect(() => {
    const stageHall = firestore.collection("stagehalls").doc(id);
    const getShows = async () =>
      await stageHall
        .get()
        .then((doc) => {
          if (doc.exists) {
            //get mediaSegments
            const mediaSegments = doc.data().mediaSegments;
            //turn mediaSegments to array
            let segmentsArray = Object.values(mediaSegments);
            //filter for only paywall segments and future shows pass to show state

            segmentsArray = segmentsArray.filter((show) => {
              const now = Date.now() / 1000; //
              if (show.endAt.seconds >= now) {
                return show.paymentRequired;
              }
              //sort by date.
            });

            setShows(segmentsArray);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    // console.log("getting shows");
    getShows();
  }, []);
  useEffect(() => {
    if (shows.length != 0) {
      shows.forEach((show) => {
        const saleRef = firestore
          .collection("ec")
          .doc(stage.id)
          .collection("sales")
          .where("eventgoerId", "==", eventgoer.id);

        const getSales = async () =>
          await saleRef
            .get()
            .then((documents) => {
              documents.forEach((doc) => {
                //if doc exists, check to see if paywallIDs match with show ids, if so, paid = true
                const docMetaData = doc.data().metadata;
                if (docMetaData.paywall_id === show.paywallData.id) {
                  show["paid"] = true;
                }
              });
            })
            .catch((err) => {
              console.log(err);
            });
        getSales();
      });
    }
  }, [shows]);
  const handlePayDialog = () => {
    setShowPayScreen(false);
  };
  if (showPayScreen) {
    return (
      <PassIdDialog
        open={showPayScreen}
        onClose={handlePayDialog}
        passIdLink={`${window.location.href}?myUniquePassID=${eventgoer.id}`}
        stage={stage}
        mediaSegment={selectedSegment}
        segmentMediaTitles={segmentMediaTitles(selectedSegment)}
        eventgoer={eventgoer}
        step={"2.1-payment"}
        firebase={firebase}
      />
    );
  }
  return (
    <>
      <TicketButton
        variety="special"
        startIcon={<TicketIcon>tickets</TicketIcon>}
        onClick={handleClick}
      >
        Shows
      </TicketButton>
      <PreshowModal
        showsPressed={showsPressed}
        handleClick={handleClick}
        organizer={organizer}
        shows={shows}
        setShowPayScreen={setShowPayScreen}
        setSelectedSegment={setSelectedSegment}
        firebase={firebase}
        mobile={mobile}
        stageId={stage.id}
      />
    </>
  );
};
