import React from "react";
import * as queryString from "query-string";
import { withFirebase } from "../services/Firebase"
import bcrypt from "bcryptjs";

class UnsubscribeScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "Loading...",
    };
  }

  componentDidMount() {
    // check params for token
    const token = this.getTokenFromParams();
    const eid = this.getEventgoerIdFromParams();

    // authenticate token
    this.authenticateToken(token, eid).then((isValid) => {
      if (isValid) {
        // unsubscribe
        this.props.firebase.firestore
          .collection("eventgoersContact")
          .doc(eid)
          .update({ notificationsOn: false })
          .then(this.onSuccess)
          .catch(this.onFail);
      } else {
        this.onFail();
      }
    });
  }

  onFail = () => {
    this.setState({ message: "Failed to unsubscribe." });
  }

  onSuccess = () => {
    this.setState({ message: "You will now no longer receive updates from this Stage" });
  }

  getTokenFromParams() {
    return queryString.parse(this.props.location.search).token;
  }

  getEventgoerIdFromParams() {
    return queryString.parse(this.props.location.search).eid;
  }

  authenticateToken(token, eventgoerId) {
    return bcrypt.compare(eventgoerId + "unsubscribe", token);
  }

  render() {
    return <h2 style={{
      color: "#fff",
      textAlign: "center"
    }}>{this.state.message}</h2>;
  }
}

export default withFirebase(UnsubscribeScreen);
