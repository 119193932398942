export const deletePost = (
  postIds,
  data,
  oldIndex,
  setData,
  setPostIds,
  setOpen,
  db,
  demoEventfeed,
  currentChannel,
  channels,
  setChannels,
  channelsOrder,
  channelsIndexes,
  setPostObjects
) => {
  const editedData = [...data.slice(0, oldIndex), ...data.slice(oldIndex + 1)];
  setData(editedData);

  const editedPostIds = [
    ...postIds.slice(0, oldIndex),
    ...postIds.slice(oldIndex + 1),
  ];
  setPostIds(editedPostIds);

  let channelsUpdate = { ...channels };
  channelsUpdate[currentChannel] = editedPostIds;
  setChannels(channelsUpdate);

  const channelIndex = channelsIndexes.findIndex(
    (channel) => channel === currentChannel
  );
  const channelIndex2 = channelsIndexes[channelIndex];

  db.collection("eventfeeds")
    .doc(demoEventfeed)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        const postObjects = doc.data().eventfeedUnpub[channelIndex2].posts;

        const editedPostObjects = [
          ...postObjects.slice(0, oldIndex),
          ...postObjects.slice(oldIndex + 1),
        ];

        let update = {};
        update[`eventfeedUnpub.${channelIndex2}.posts`] = editedPostObjects;
        db.collection("eventfeeds").doc(demoEventfeed).update(update);

        setPostObjects(editedPostObjects);
      }
    });

  setOpen(false);
};
