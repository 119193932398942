import React, { useEffect } from 'react';
import ReactPlayer from 'react-player/youtube'
import { schedule, clear } from '../utils/scheduler';

const Fallback = ({ imageUrl, duration, onEnded, nextVideoId }) => {
  useEffect(() => {
    // trigger onEnded after timeout period
    schedule(
      "Fallback" + nextVideoId,
      onEnded,
      duration,
    );
    return () => {
      clear("Fallback" + nextVideoId)
    }
  }, [nextVideoId, onEnded, duration])

  return (
    <div className="Stagehall_fallbackWrapper">
      <ReactPlayer
        className="Stagehall_player"
        playIcon={<p></p>}
        playing={false}
        url={"https://www.youtube.com"}
        light={imageUrl}
        width="100%"
        height="100%"
      />
    </div>
  );
}

export default Fallback;
