import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    memo,
    lazy,
    Suspense,
} from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./Eventfeed.scss";
import styled from "styled-components";
import "./Froala.scss";
import { Avatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import cx from "classnames";
import Modal from "../../components/Modal";
import { withFirebase } from "../../services/Firebase";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Button from "@material-ui/core/Button";
import CollapsibleContainer from "./components/CollapsibleContainer";
import ChannelContainer from "./components/ChannelContainer/ChannelContainer";
import DonorChannelContainer from "./components/ChannelContainer/DonorChannelContainer";
import Post from "./actions/posts/Post";
import { createNewPost } from "./actions/posts/NewPost";
import { reorderPosts } from "./actions/posts/ReorderPost";
import { deletePost } from "./actions/posts/DeletePost";
import { editPost } from "./actions/posts/EditPost";
import { getPosts } from "./actions/posts/GetPosts";
import { updatePosts } from "./actions/posts/UpdatePosts";
import { createNewChannel } from "./actions/channels/NewChannel";
import { deleteChannel } from "./actions/channels/DeleteChannel";
import { reorderChannels } from "./actions/channels/ReorderChannel";
import { editChannel } from "./actions/channels/EditChannel";
import { toggleHidePost } from "./actions/posts/HidePost";
import { toggleNotifyPost } from "./actions/posts/NotifyPost";
import { publish } from "./actions/shared/Publish";
import { ReactComponent as MenuIcon } from "../../assets/icons/Stage/menu.svg";
import { ReactComponent as ArrowBackIcon } from "../../assets/icons/Stage/arrow-back.svg";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import * as queryString from "query-string";
import firebaseUtil from "firebase/app";
import LazyPost from "./components/LazyPost/lazypost.component";
import DonorPosts from "./components/DonorPosts/DonorPosts";
import { v4 as uuidv4 } from "uuid";
import { StyledButton } from "../../components/StyledComponents/StyledButton";
import { ReactComponent as CheerIcon } from "../../assets/icons/cheer.svg";
import DonationDialog from "../StagehallScreen/components/DonationDialog/DonationDialog";

const StyledSkeleton = styled(Skeleton)`
    margin: auto;
    margin-bottom: 18px;
    border-radius: 17px;
    height: 262px;
    width: 266px;
    transform: scale(1, 1);
`;

const ErrorPost = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 30px;
`;

const DonateButton = styled(StyledButton)`
    margin: 0 auto 18px;
`;

const TextEditor = lazy(() => import("./components/TextEditor/TextEditor"));

function EventfeedScreen({
    id,
    isBackstage,
    currentUser,
    eventfeed,
    isPublishing,
    stage,
    location,
    firebase,
    donations,
    donationsOn,
    actions,
    eventfeedChannel,
    mobile,
    eventgoer,
}) {
    const { firestore, storage } = firebase;
    const demoEventfeed = id;

    // useState so we can use React State in function

    // Text input field. Used when creating and updating
    const [postPlainText, setPostHtml] = useState("");

    const [newChannel, setNewChannel] = useState("");
    const [editChannelName, setEditChannel] = useState("");

    // Posts array
    const [data, setData] = useState([]);
    // Post IDs array. TODO: map to data
    const [postIds, setPostIds] = useState([]);

    const [postObjects, setPostObjects] = useState([]);

    // Reordering and updating
    const [oldIndex, setOldIndex] = useState(0);
    // Reordering only.
    const [newIndex, setNewIndex] = useState(0);

    // If dialog box is open
    const [open, setOpen] = React.useState(false);
    // Type of dialog
    const [dialog, setDialog] = React.useState("reorder");

    // Channels object
    const [channels, setChannels] = useState({});
    // Channel scroll position object
    const [channelScrollPositions, setChannelScrollPositions] = useState({});
    // User selected channel
    // const [currentChannel, setCurrentChannel] = useState(0);
    // If fetching posts from firebase
    const [isFetchingPosts, setIsFetchingPosts] = useState(false);
    // Display connection error
    const [connectionError, setConnectionError] = useState(false);

    // Channels order
    const [channelsOrder, setChannelsOrder] = useState([]);
    const [channelsIndexes, setChannelsIndexes] = useState([]);
    const [channelsCount, setChannelsCount] = useState(0);

    // First time
    const [notVisited, setNotVisited] = useState(false);

    // Do not refresh if current user is updating
    const [isUpdating, setIsUpdating] = useState(false);

    // postParamId
    const [postParamId, setPostParamId] = useState("");

    // Scroll to post ref
    const postRef = useRef();
    // Scroll container ref
    const postsContainerRef = useRef();

    // eventfeedData listener
    const [eventfeedData, setEventfeedData] = useState({});

    // backstage starAccount username
    const [starUsername, setStarUsername] = useState("");

    // current user editing
    const [userEditing, setUserEditing] = useState("");

    // channel bar state on mobile
    const [channelsExpanded, setChannelsExpanded] = useState(false);
    const [channelsInTransition, setChannelsInTransition] = useState(false);

    // live update objects
    const [channelsRecentlyUpdated, setChannelsRecentlyUpdated] = useState({});
    const [postsRecentlyUpdated, setPostsRecentlyUpdated] = useState({});

    //donation modal
    const [donateModalOpen, setDonateModalOpen] = useState(false);

    const handleDonateModal = () => {
        setDonateModalOpen(!donateModalOpen);
    };

    const DONOR_CHANNEL_INDEX = "DONOR_CHANNEL_INDEX";

    // Read data from firestore
    useEffect(() => {
        if (!notVisited && !isPublishing) {
            if (isBackstage) {
                firestore
                    .collection("starAccounts")
                    .doc(currentUser.uid)
                    .get()
                    .then((doc) => setStarUsername(doc.data().username));
            }

            setPostParamId(queryString.parse(location.search).post);
            const channel = queryString.parse(location.search).channel;

            getPosts(
                firestore,
                demoEventfeed,
                setChannelsCount,
                setChannelsOrder,
                setChannelsIndexes,
                setChannels,
                actions.setEventfeedChannel,
                setPostIds,
                setData,
                setPostObjects,
                isBackstage,
                eventfeed,
                channel,
                postRef,
                postsContainerRef,
                setChannelScrollPositions,
                currentUser,
                firebaseUtil
            );
        }

        if (isPublishing) {
            sendUpdateandWait();
            publish(
                firestore,
                id,
                firebase,
                currentUser,
                setPostObjects,
                postObjects,
                stage
            );
        }

        setEventfeedData(eventfeed);

        if (
            (isBackstage &&
                eventfeedData.eventfeedUnpub &&
                eventfeedData.eventfeedUnpub !== eventfeed.eventfeedUnpub &&
                !isUpdating) ||
            (!isBackstage &&
                eventfeedData.eventfeedPub &&
                eventfeedData.eventfeedPub.dateString !==
                    eventfeed.eventfeedPub.dateString)
        ) {
            const channel = queryString.parse(location.search).channel;
            updatePosts(
                firestore,
                demoEventfeed,
                setChannelsCount,
                setChannelsOrder,
                setChannelsIndexes,
                setChannels,
                actions.setEventfeedChannel,
                setPostIds,
                setData,
                setPostObjects,
                isBackstage,
                eventfeed,
                channel,
                postRef,
                eventfeedData,
                setChannelsRecentlyUpdated,
                channelsIndexes,
                postIds,
                dialog,
                oldIndex,
                setOldIndex,
                channelsRecentlyUpdated,
                postsRecentlyUpdated,
                setPostsRecentlyUpdated
            );
        }

        setNotVisited(true);

        // Set it so that it only happens on init rather than every update, and if isPublishing changes
    }, [
        isPublishing,
        currentUser,
        demoEventfeed,
        eventfeed,
        id,
        isBackstage,
        notVisited,
        postObjects,
        stage,
        location.search,
        channelsIndexes,
        channelsRecentlyUpdated,
        dialog,
        eventfeedData,
        firebase,
        firestore,
        isUpdating,
        oldIndex,
        postIds,
        postsRecentlyUpdated,
    ]);

    const generateNewPost = () => {
        const newPost = new Post(postPlainText);

        sendUpdateandWait();
        createNewPost(
            firestore,
            demoEventfeed,
            newPost,
            eventfeedChannel,
            channels,
            setChannels,
            channelsOrder,
            channelsIndexes,
            setPostIds,
            postIds,
            setPostObjects,
            postObjects
        );

        setData([...data, newPost.data]);
        setPostHtml("");

        handleCloseDialog();
    };

    const handleOpenReorder = (idx) => {
        const currentUserEditing = checkPostEditing(idx);

        if (currentUserEditing) {
            setUserEditing(currentUserEditing);
            setDialog("userEditing");
            setOpen(true);
        } else {
            sendUpdateandWait();
            setUserUpdatingPost(idx);
            setOldIndex(idx);
            setNewIndex(idx);
            setDialog("reorder");
            setOpen(true);
        }
    };

    const handleReorder = async () => {
        sendUpdateandWait();
        const reorderedPosts = await reorderPosts(
            firestore,
            demoEventfeed,
            postIds,
            data,
            oldIndex,
            newIndex,
            eventfeedChannel,
            channels,
            channelsOrder,
            channelsIndexes,
            setPostObjects
        );

        let update = { ...channels };
        update[eventfeedChannel] = reorderedPosts.postIds;
        setChannels(update);

        setData(reorderedPosts.data);
        setPostIds(reorderedPosts.postIds);
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        const eventfeedRef = firestore.collection("eventfeeds").doc(id);

        let update = {};

        if (dialog === "reorder" || dialog === "delete" || dialog === "edit") {
            update[`usersEditing.posts.${postIds[oldIndex]}`] =
                firebaseUtil.firestore.FieldValue.delete();
        }

        if (
            dialog === "edit" ||
            dialog === "new" ||
            dialog === "editChannel" ||
            dialog === "delete-channel" ||
            dialog === "reorderChannel"
        ) {
            update[`usersEditing.channels.${eventfeedChannel}`] =
                firebaseUtil.firestore.FieldValue.delete();
        }

        eventfeedRef.update(update);

        setPostHtml("");
        setOpen(false);
    };

    const renderReorder = () => {
        return (
            <div style={{ padding: 20 }}>
                Move Post {oldIndex + 1} after{" "}
                <input
                    type='text'
                    placeholder=''
                    onChange={(e) => setNewIndex(parseInt(e.target.value))}
                />{" "}
                of {data.length} <button onClick={handleReorder}>OK</button>
                <button onClick={handleCloseDialog}>Cancel</button>
            </div>
        );
    };

    const handleOpenEdit = (post, idx) => {
        const currentUserEditing = checkPostEditing(idx);

        if (currentUserEditing) {
            setUserEditing(currentUserEditing);
            setDialog("userEditing");
            setOpen(true);
        } else {
            setOldIndex(idx);
            setPostHtml(post.html);
            sendUpdateandWait();
            setUserUpdatingPost(idx);
            setUserUpdatingChannel();
            setDialog("edit");
            setOpen(true);
        }
    };

    const checkChannelEditing = (idx) => {
        return eventfeedData.usersEditing.channels[eventfeedChannel];
    };

    const checkPostEditing = (idx) => {
        const postId = postIds[idx];
        return eventfeedData.usersEditing.posts[postId];
    };

    const setUserUpdatingChannel = () => {
        const eventfeedRef = firestore.collection("eventfeeds").doc(id);
        let update = {};
        update[`usersEditing.channels.${eventfeedChannel}`] = starUsername;
        eventfeedRef.update(update);
    };

    const setUserUpdatingPost = (idx) => {
        const postId = postIds[idx];
        const eventfeedRef = firestore.collection("eventfeeds").doc(id);
        let update = {};
        update[`usersEditing.posts.${postId}`] = starUsername;
        eventfeedRef.update(update);
    };

    const handleOpenNew = () => {
        sendUpdateandWait();
        setUserUpdatingChannel();
        setDialog("new");
        setOpen(true);
    };

    const handleEdit = () => {
        sendUpdateandWait();
        editPost(
            Post,
            postPlainText,
            postIds,
            oldIndex,
            data,
            firestore,
            setData,
            setPostIds,
            channels,
            eventfeedChannel,
            setChannels,
            channelsOrder,
            channelsIndexes,
            demoEventfeed,
            setPostHtml,
            setPostObjects
        );

        handleCloseDialog();
    };

    const beforeImageUpload = async function (images) {
        const filePath = `stages/${id}/photos/${Date.now()}`;
        const fileRef = storage.ref(filePath);
        await fileRef.put(images[0]);

        const downloadURL = await fileRef.getDownloadURL();
        return this.image.insert(downloadURL, null, null, this.image.get());
    };

    const renderEditor = (props) => {
        const fallback = (
            <div style={{ width: "370px", height: "330px" }}></div>
        );

        return (
            <Suspense fallback={fallback}>
                <TextEditor
                    model={postPlainText}
                    onModelChange={setPostHtml}
                    config={{
                        events: {
                            "image.beforeUpload": beforeImageUpload,
                        },
                    }}
                    {...props}
                />
            </Suspense>
        );
    };

    const renderEdit = () => {
        return (
            <div>
                {renderEditor()}
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleEdit}
                >
                    OK
                </Button>
                <Button variant='contained' onClick={handleCloseDialog}>
                    Cancel
                </Button>
            </div>
        );
    };

    const renderNew = () => {
        return (
            <div>
                {renderEditor()}
                <Button
                    variant='contained'
                    color='primary'
                    onClick={generateNewPost}
                >
                    OK
                </Button>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleCloseDialog}
                >
                    Cancel
                </Button>
            </div>
        );
    };

    const renderDelete = () => {
        return (
            <div style={{ padding: 20 }}>
                Are you sure?
                <button onClick={handleDelete}>Yes</button>
                <button onClick={handleCloseDialog}>No</button>
            </div>
        );
    };

    const handleOpenDelete = (idx) => {
        const currentUserEditing = checkPostEditing(idx);

        if (currentUserEditing) {
            setUserEditing(currentUserEditing);
            setDialog("userEditing");
            setOpen(true);
        } else {
            sendUpdateandWait();
            setUserUpdatingPost(idx);
            setOldIndex(idx);
            setDialog("delete");
            setOpen(true);
        }
    };

    const handleDelete = () => {
        sendUpdateandWait();
        deletePost(
            postIds,
            data,
            oldIndex,
            setData,
            setPostIds,
            setOpen,
            firestore,
            demoEventfeed,
            eventfeedChannel,
            channels,
            setChannels,
            channelsOrder,
            channelsIndexes,
            setPostObjects
        );
    };

    const changeChannel = useCallback(
        (channelIdx) => {

            let channelScrollUpdate = channelScrollPositions;
            channelScrollUpdate[eventfeedChannel] =
                postsContainerRef.current.scrollTop;
            setChannelScrollPositions(channelScrollUpdate);
            localStorage.setItem(
                "storedScrolls",
                JSON.stringify(channelScrollUpdate)
            );

            if (typeof channelIdx === "number") {
                //for all channels except donor channel
                const eventfeedRef = firestore.collection("eventfeeds").doc(id);
                // console.log("firestore", firestore);

                eventfeedRef.get().then(function (doc) {
                    // console.log("eventfeedRef.get() doc", doc);
                    if (doc.exists) {
                        const channelIndex = channelsIndexes[channelIdx];
                        const newPostObjects = isBackstage
                            ? doc.data().eventfeedUnpub[channelIndex].posts
                            : eventfeed.eventfeedPub[channelIndex].posts;

                        const postIdArray = channels[channelIndex];
                        let itemRefs = postIdArray.map(async (id) => {
                            return firestore.collection("posts").doc(id).get();
                        });

                        actions.setEventfeedChannel(channelIndex);
                        setIsFetchingPosts(true);

                        Promise.all(itemRefs)
                            .then((docs) => {
                                let items = docs.map((doc, idx) => {
                                    let newPostObject = doc.data();
                                    newPostObject.id = doc.id;
                                    newPostObject.recentlyUpdated =
                                        postsRecentlyUpdated[doc.id];
                                    return newPostObject;
                                });
                                setPostIds(postIdArray);
                                setData(items);
                                setPostObjects(newPostObjects);

                                const channelScrollPosition =
                                    channelScrollPositions[
                                        channelsIndexes[channelIdx]
                                    ];
                                if (channelScrollPosition) {
                                    postsContainerRef.current.scrollTop =
                                        channelScrollPosition;
                                } else {
                                    postsContainerRef.current.scrollTop = 0;
                                }
                            })
                            .then(() => {
                                setIsFetchingPosts(false);
                                connectionError && setConnectionError(false);
                            })
                            .catch((error) => {
                                console.log("oops!!", error);
                                postsContainerRef.current.scrollTop = 0;
                                setConnectionError(true);
                            });
                        localStorage.setItem(
                            "localStorageChannel",
                            channelIndex
                        );
                    } else {
                        console.log("No such document!");
                    }
                });
            } else {
                actions.setEventfeedChannel(DONOR_CHANNEL_INDEX);
            }

            setChannelsExpanded(false);
        },
        [
            channelScrollPositions,
            channels,
            channelsIndexes,
            connectionError,
            eventfeedChannel,
            eventfeed.eventfeedPub,
            firestore,
            id,
            isBackstage,
            postsRecentlyUpdated,
        ]
    );

    const handleNewChannel = (e) => {
        e.preventDefault();

        sendUpdateandWait();
        createNewChannel(
            channels,
            newChannel,
            channelsOrder,
            setChannels,
            setChannelsOrder,
            setChannelsIndexes,
            channelsIndexes,
            channelsCount,
            firestore,
            demoEventfeed,
            firebase,
            setNewChannel,
            setChannelsCount,
            actions.setEventfeedChannel,
            setPostIds,
            setData,
            setPostObjects
        );
    };

    const handleDeleteChannel = (e) => {
        e.preventDefault();

        sendUpdateandWait();
        deleteChannel(
            channels,
            eventfeedChannel,
            setChannels,
            channelsOrder,
            setChannelsOrder,
            firestore,
            demoEventfeed,
            setChannelsIndexes,
            channelsIndexes,
            changeChannel
        );
        handleCloseDialog();
    };

    const handleOpenDeleteChannel = () => {
        const currentUserEditing = checkChannelEditing();

        if (currentUserEditing) {
            setUserEditing(currentUserEditing);
            setDialog("userEditing");
            setOpen(true);
        } else {
            sendUpdateandWait();
            setUserUpdatingChannel();
            setDialog("delete-channel");
            setOpen(true);
        }
    };

    const renderDeleteChannel = () => {
        return (
            <div style={{ padding: 20 }}>
                Are you sure?
                <button onClick={handleDeleteChannel}>Yes</button>
                <button onClick={handleCloseDialog}>No</button>
            </div>
        );
    };

    const renderUserEditing = () => {
        return (
            <div style={{ padding: 20 }}>
                <div>{userEditing} is using this right now.</div>
                <button onClick={handleCloseDialog}>OK</button>
            </div>
        );
    };

    const handleOpenReorderChannel = (channel) => {
        const currentUserEditing = checkChannelEditing();

        if (currentUserEditing) {
            setUserEditing(currentUserEditing);
            setDialog("userEditing");
            setOpen(true);
        } else {
            const oldChannelIndex = channelsIndexes.findIndex(
                (el) => el === eventfeedChannel
            );
            setOldIndex(oldChannelIndex);
            setNewIndex(oldChannelIndex);

            sendUpdateandWait();
            setUserUpdatingChannel();
            setDialog("reorderChannel");
            setOpen(true);
        }
    };

    const renderReorderChannel = () => {
        const oldChannelIndex = channelsIndexes.findIndex(
            (el) => el === eventfeedChannel
        );
        return (
            <div style={{ padding: 20 }}>
                Move Channel {oldChannelIndex + 1} after{" "}
                <input
                    type='text'
                    placeholder=''
                    onChange={(e) => setNewIndex(parseInt(e.target.value))}
                />{" "}
                of {channelsOrder.length}
                <button onClick={handleReorderChannels}>OK</button>
                <button onClick={handleCloseDialog}>Cancel</button>
            </div>
        );
    };

    const handleReorderChannels = () => {
        sendUpdateandWait();
        reorderChannels(
            channelsOrder,
            oldIndex,
            newIndex,
            setChannelsOrder,
            firestore,
            demoEventfeed,
            setChannelsIndexes,
            channelsIndexes
        );

        handleCloseDialog();
    };

    const handleOpenEditChannel = (channel) => {
        const currentUserEditing = checkChannelEditing();

        if (currentUserEditing) {
            setUserEditing(currentUserEditing);
            setDialog("userEditing");
            setOpen(true);
        } else {
            sendUpdateandWait();
            setUserUpdatingChannel();
            setEditChannel(channel);
            setDialog("editChannel");
            setOpen(true);
        }
    };

    const handleEditChannel = () => {
        sendUpdateandWait();
        editChannel(
            editChannelName,
            eventfeedChannel,
            firestore,
            demoEventfeed,
            postIds,
            channels,
            setChannels,
            channelsOrder,
            setChannelsOrder,
            channelsIndexes,
            setEditChannel,
            setChannelsIndexes,
            setChannelsCount,
            actions.setEventfeedChannel,
            postObjects
        );

        handleCloseDialog();
    };

    const renderEditChannel = () => {
        return (
            <div style={{ padding: 20 }}>
                <input
                    type='text'
                    value={editChannelName}
                    onChange={(e) => setEditChannel(e.target.value)}
                />
                <button onClick={handleEditChannel}>OK</button>
                <button onClick={handleCloseDialog}>Cancel</button>
            </div>
        );
    };

    const handleHidePost = (idx) => {
        sendUpdateandWait();
        toggleHidePost(
            firestore,
            demoEventfeed,
            channelsOrder,
            eventfeedChannel,
            channelsIndexes,
            postObjects,
            idx,
            setPostObjects
        );
    };

    const handleNotifyPost = (idx) => {
        sendUpdateandWait();

        toggleNotifyPost(
            firestore,
            demoEventfeed,
            channelsOrder,
            eventfeedChannel,
            channelsIndexes,
            postObjects,
            idx,
            setPostObjects
        );
    };

    const renderChannels = () => {
        return channelsOrder.map((channelName, channelIdx) => {
            let recentlyUpdated = false;

            if (channelsRecentlyUpdated[channelsIndexes[channelIdx]]) {
                recentlyUpdated =
                    channelsRecentlyUpdated[channelsIndexes[channelIdx]];
            }

            return (
                <ChannelContainer
                    key={channelIdx}
                    channelName={channelName}
                    channelIdx={channelIdx}
                    recentlyUpdated={recentlyUpdated}
                    isCurrentChannel={
                        channelsIndexes[channelIdx] === eventfeedChannel
                    }
                    changeChannel={changeChannel}
                    isBackstage={isBackstage}
                    handleOpenReorderChannel={handleOpenReorderChannel}
                    handleOpenEditChannel={handleOpenEditChannel}
                    handleOpenDeleteChannel={handleOpenDeleteChannel}
                    currentChannel={eventfeedChannel}
                />
            );
        });
    };

    const renderDonorChannel = () => {
        return (
            <DonorChannelContainer
                channelName={"Our donors"}
                channelIdx={DONOR_CHANNEL_INDEX}
                recentlyUpdated={false}
                isBackstage={isBackstage}
                isCurrentChannel={eventfeedChannel === DONOR_CHANNEL_INDEX}
                changeChannel={changeChannel}
            />
        );
    };

    const sendUpdateandWait = () => {
        setIsUpdating(true);

        // Wait one second before you can accept live update changes
        setTimeout(() => {
            setIsUpdating(false);
        }, 1000);
    };

    const handleOverflowChange = (itemId, isShowOverflow) => {
        const storedLocalOverflows = localStorage.getItem("storedOverflows");
        let storedOverflows = storedLocalOverflows
            ? JSON.parse(storedLocalOverflows)
            : {};
        storedOverflows[itemId] = isShowOverflow;
        localStorage.setItem(
            "storedOverflows",
            JSON.stringify(storedOverflows)
        );
    };

    const toggleChannelsExpanded = (bool) => {
        setChannelsInTransition(true);

        setTimeout(() => {
            setChannelsExpanded(bool);
        }, 200);

        setTimeout(() => {
            setChannelsInTransition(false);
        }, 500);
    };

    const renderSkeletonChannel = (number) => {
        let skeletons = [];
        for (let i = 0; i < number; i++) {
            skeletons.push(uuidv4());
        }
        return skeletons.map((key) => (
            <StyledSkeleton
                key={key}
                animation='wave'
                variant='rect'
                height='262px'
                width='266px'
            />
        ));
    };

    const renderErrorPost = () => {
        return (
            <ErrorPost>
                <SyncProblemIcon
                    style={{ fontSize: 50, marginBottom: "20px" }}
                />
                <span>
                    Oops! We're having trouble connecting. Try refreshing.
                </span>
            </ErrorPost>
        );
    };

    const tabletAndUp = useMediaQuery((theme) =>
        theme.breakpoints.up("tablet")
    );

    /**
     * Subscribes to Firestore "leaderboards" doc for the stage id and sets donationsOn in redux store on updates. Also changes channel to index 0 when donations are toggled off.
     * Sets donors channel in redux.
     * @returns {function} unsubscribe listener
     */

    useEffect(() => {
        //listener for leaderboards
        const docRef = firestore.collection("leaderboards").doc(stage.id);

        const unsubscribe = docRef.onSnapshot((doc) => {
            if (doc.exists) {
                const { donationsOn } = doc.data();
                if (eventfeedChannel === DONOR_CHANNEL_INDEX && !donationsOn) {
                    changeChannel(0);
                }
                actions.setDonationsOn(donationsOn);
            } else {
                docRef.set({
                    donationsOn: false,
                });
            }
            actions.setDonorsChannel(DONOR_CHANNEL_INDEX);
        });

        return () => unsubscribe();
    }, [
        actions,
        changeChannel,
        eventfeedChannel,
        firestore,
        stage,
    ]);

    return (
        <div className='EventfeedScreen box'>
            <Modal
                isOpen={open}
                onClose={handleCloseDialog}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                    },
                }}
            >
                {dialog === "edit" && isBackstage && renderEdit()}
                {dialog === "reorder" && renderReorder()}
                {dialog === "new" && renderNew()}
                {dialog === "delete" && renderDelete()}
                {dialog === "delete-channel" && renderDeleteChannel()}
                {dialog === "reorderChannel" && renderReorderChannel()}
                {dialog === "editChannel" && renderEditChannel()}
                {dialog === "userEditing" && renderUserEditing()}
            </Modal>
            <DonationDialog
                handleClose={handleDonateModal}
                open={donateModalOpen}
                eventgoer={eventgoer}
                mobile={mobile}
                onClose={handleDonateModal}
                organizer={stage.organizer}
                stage={stage}
            />
            <div className='EventfeedScreen-inner'>
                <div className='EventfeedScreen-channels-wrapper'>
                    <div
                        className={cx("EventfeedScreen-channels-background", {
                            contracted: !tabletAndUp && !channelsExpanded,
                        })}
                    />
                    <div
                        className={cx("EventfeedScreen-channels box", {
                            contracted: !tabletAndUp && !channelsExpanded,
                            "EventfeedScreen-channels-transitioning":
                                channelsInTransition,
                        })}
                    >
                        {!tabletAndUp &&
                            (channelsExpanded ? (
                                <div className='EventfeedScreen-expanderIconWrap'>
                                    <ArrowBackIcon
                                        className='mobile-only'
                                        onClick={() =>
                                            toggleChannelsExpanded(false)
                                        }
                                    />
                                </div>
                            ) : (
                                <div className='EventfeedScreen-expanderIconWrap'>
                                    <MenuIcon
                                        className='mobile-only'
                                        onClick={() =>
                                            toggleChannelsExpanded(true)
                                        }
                                    />
                                </div>
                            ))}
                        <div className='EventfeedScreen-channels-inner'>
                            {isBackstage && tabletAndUp && (
                                <form onSubmit={handleNewChannel}>
                                    <input
                                        type='text'
                                        value={newChannel}
                                        onChange={(e) =>
                                            setNewChannel(e.target.value)
                                        }
                                        placeholder='New Channel'
                                        style={{ width: "8vw" }}
                                    />
                                    <input type='submit' value='Submit' />
                                </form>
                            )}

                            {renderChannels()}
                            {donationsOn && renderDonorChannel()}
                        </div>
                    </div>
                </div>

                <div
                    className='EventfeedScreen-posts'
                    ref={postsContainerRef}
                    onScroll={() => {
                        let channelScrollUpdate = channelScrollPositions;
                        channelScrollUpdate[eventfeedChannel] =
                            postsContainerRef.current.scrollTop;
                        setChannelScrollPositions(channelScrollUpdate);
                        localStorage.setItem(
                            "storedScrolls",
                            JSON.stringify(channelScrollUpdate)
                        );
                    }}
                >
                    {isBackstage && eventfeedChannel !== DONOR_CHANNEL_INDEX && (
                        <button onClick={handleOpenNew}>New Post</button>
                    )}
                    {!isBackstage && mobile && stage.organizer && donationsOn ? (
                        <DonateButton
                            variety='special'
                            endIcon={<CheerIcon>donate</CheerIcon>}
                            onClick={handleDonateModal}
                        >
                            Donate
                        </DonateButton>
                    ) : null}
                    {eventfeedChannel === DONOR_CHANNEL_INDEX ? (
                        donationsOn ? (
                            <DonorPosts donations={donations} />
                        ) : null
                    ) : //if any channel besides donors
                    !isFetchingPosts ? (
                        data
                            .filter(
                                (item, idx) =>
                                    isBackstage ||
                                    (postObjects[idx] && !postObjects[idx].hide)
                            )
                            .map((item, idx) => (
                                <LazyPost
                                    key={item.id + String(idx)}
                                    containerRef={postsContainerRef.current}
                                >
                                    <div
                                        key={item.html + String(idx)}
                                        className='EventfeedScreen-post'
                                    >
                                        {item.id === postParamId && (
                                            <div ref={postRef}></div>
                                        )}

                                        <CollapsibleContainer
                                            key={item.html + String(idx)}
                                            className='EventfeedScreen-fr-view rounded'
                                            initialHeight={260}
                                            onOverflowChange={
                                                handleOverflowChange
                                            }
                                            itemId={item.id}
                                            itemProvenance={item.provenance}
                                            recentlyUpdated={
                                                item.recentlyUpdated
                                            }
                                        >
                                            <FroalaEditorView
                                                model={item.html}
                                            />
                                        </CollapsibleContainer>

                                        {isBackstage && tabletAndUp && (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        handleOpenReorder(idx)
                                                    }
                                                >
                                                    reorder
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        handleOpenEdit(
                                                            item,
                                                            idx
                                                        )
                                                    }
                                                >
                                                    edit
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        handleOpenDelete(idx)
                                                    }
                                                >
                                                    delete
                                                </button>
                                                <div>
                                                    <button
                                                        onClick={() => {
                                                            handleHidePost(idx);
                                                        }}
                                                    >
                                                        hide
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            handleNotifyPost(
                                                                idx
                                                            );
                                                        }}
                                                    >
                                                        notify
                                                    </button>
                                                </div>
                                                <div
                                                    style={{ display: "flex" }}
                                                >
                                                    <Avatar>{idx + 1}</Avatar>
                                                    {postObjects[idx] &&
                                                        postObjects[idx]
                                                            .hide && (
                                                            <Avatar>H</Avatar>
                                                        )}
                                                    {postObjects[idx] &&
                                                        postObjects[idx]
                                                            .notify && (
                                                            <Avatar>N</Avatar>
                                                        )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </LazyPost>
                            ))
                    ) : !connectionError ? (
                        renderSkeletonChannel(10)
                    ) : (
                        renderErrorPost()
                    )}
                </div>
            </div>
        </div>
    );
}

EventfeedScreen.propTypes = {
    id: PropTypes.string.isRequired,
    isBackstage: PropTypes.bool.isRequired,
};

export default memo(withFirebase(EventfeedScreen));
