import React, { useState, useEffect } from "react";
import kontomoWordMark from "../../assets/images/Kontomo_logo_H_w_blk_wordmark.png";
import "./Statement.scss";
import Avatar from "../../components/Avatar";
import moment from "moment";

function StatementScreen(props) {
  const statementType = props.match.params.statementType;

  const [sales, setSales] = useState([]);
  const [stage, setStage] = useState({});
  const [starUsername, setStarUsername] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [showStatementRangePicker, setShowStatementRangePicker] =
    useState(false);
  const [currency, setCurrency] = useState("usd");
  const currencyDecimalRatios = { jpy: 1, usd: 100 };
  const currencyDecimalRatio = currencyDecimalRatios[currency] || 100;

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  useEffect(() => {
    document.body.style.overflow = "scroll";

    const currentUserId = props.currentUserId;
    const starIdParam = props.match.params.starId;
    const isDifferentStarId = currentUserId !== starIdParam;

    // TODO: Kick user out if the stage owner starId is not the same

    // Comment the below out after testing
    // if (isDifferentStarId)
    //   window.location.replace(window.location.origin + "/404");

    const stageIdParam = props.match.params.stageId;
    const firebase = props.firebase;

    let statementStartDate = 0;
    let statementEndDate = Math.round(endDate.getTime() / 1000);

    if (props.isRange) {
      statementStartDate = props.match.params.startDate;
      statementEndDate = props.match.params.endDate;
      setEndDate(new Date(statementEndDate * 1000));
    }

    // Kick out user if the stage ec document does not exist
    // const ecRef = firebase.firestore.collection("ec").doc(stageIdParam);
    // ecRef.get().then(function (doc) {
    //   if (!doc.data()) window.location.replace(window.location.origin + "/404");
    // });

    const stageRef = firebase.firestore.collection("stages").doc(stageIdParam);
    stageRef
      .get()
      .then(function (doc) {
        const isModerator = [
          "xH7rQW1U02cr0gvQJKUrPwIqoKk2",
          "jdEQVnSgmuQ5kusSIYwpXeaILzI3",
          "K1BTWwfzL7RXPd2AG2CNbIq2vW73",
          "3rvGqlpxJ9YaTkuXd9jX3RF7rQH3",
          "H7AQyfjy4JNRRE3oa9voMRJisJu2",
          "h8JGbX6XH9ThjYwHfufzLEPeb6i1",
          "juPjFBsWUqS8PTMcFce5N52MI2k1",
        ].includes(currentUserId);

        setShowStatementRangePicker(isModerator);

        console.log("doc data", doc.data());
        console.log("currentUserId", currentUserId);

        if (doc.data().starId !== currentUserId && !isModerator) {
          console.log("starId on stage does not match");
          window.location.replace(window.location.origin + "/404");
        }

        setStage(doc.data());

        const userRef = firebase.firestore
          .collection("starAccounts")
          .doc(doc.data().starId);
        userRef.get().then((starDoc) => {
          console.log("starDoc", starDoc.data());
          setStarUsername(starDoc.data().username);
        });
      })
      .catch((e) => {
        console.error("oops", e);
        window.location.replace(window.location.origin + "/404");
      });

    firebase.firestore
      .collection("ec")
      .doc(stageIdParam)
      .collection("sales")
      .where("type", "==", statementType)
      .where("created", ">=", parseInt(statementStartDate))
      .where("created", "<=", parseInt(statementEndDate))
      .get()
      .then(async function (querySnapshot) {
        let salesArray = [];

        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          salesArray.push(doc.data());
        });

        const notUSDCurrency = salesArray.find(
          (sale) => sale.currency !== "usd"
        );
        if (notUSDCurrency) {
          setCurrency(notUSDCurrency.currency);
        }

        const newSales = await Promise.all(
          salesArray
            .sort((a, b) => a.created - b.created)
            .map(async (sale) => {
              let newSale = sale;

              if (statementType === "sale") {
                await firebase.firestore
                  .collection("ec")
                  .doc(stageIdParam)
                  .collection("paywalls")
                  .doc(sale.paywallId)
                  .get()
                  .then((doc) => {
                    const paywall = doc.data();
                    newSale.description = paywall.description;
                  });
              }

              return newSale;
            })
        );

        setSales(newSales);
      });
  }, []);

  const renderStatementRangePicker = () => {
    return (
      <div style={{ marginBottom: 23 }}>
        <div style={{ display: "flex" }}>
          <input
            type='datetime-local'
            value={moment(customStartDate).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) => {
              setCustomStartDate(
                new Date(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))
              );
            }}
          />
          <input
            type='datetime-local'
            value={moment(customEndDate).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) => {
              setCustomEndDate(
                new Date(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))
              );
            }}
          />
        </div>
        {customStartDate && customEndDate ? (
          <a
            href={
              window.location.href +
              "/" +
              Math.round(customStartDate / 1000) +
              "/" +
              Math.round(customEndDate / 1000)
            }
          >
            Statement Link
          </a>
        ) : (
          <div>Set Start and End Date for Statement Link</div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div
        id='statementToPrint'
        style={{
          backgroundColor: "#FFF8EA",
          width: "210mm",
          marginLeft: "auto",
          marginRight: "auto",
          //   minHeight: "297mm" is the actual paper dimension, but printing space is too large and goes to two lines
          minHeight: "270mm",
        }}
      >
        <div style={{ paddingTop: 22, paddingLeft: 22, paddingRight: 22 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 25.5,
            }}
          >
            <div>
              <img src={kontomoWordMark} />
              <div style={{ fontSize: 9 }}>
                <div style={{ marginBottom: 7, marginTop: 7 }}>
                  7 rye ridge plaza #307, rye brook, new york 10573-2822
                </div>
                <div style={{ marginBottom: 7 }}>https://kontomo.com</div>
                <div>friend@kontomo.com</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <div style={{ fontSize: 24, marginBottom: 10 }}>
                Stage {statementType === "donation" && "DONATION"} Payout
                Statement
              </div>
              {!props.isRange && (
                <div style={{ fontSize: 9, marginBottom: 6 }}>
                  Total {statementType === "sale" ? "Sales" : "Donations"}
                </div>
              )}
              <div style={{ fontSize: 9 }}>{endDate.toLocaleDateString()}</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: 20.5,
            }}
          >
            <div style={{ fontSize: 24, marginRight: 13 }}>Payout to:</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                imageUrl={
                  stage.organizer && stage.organizer.alias.avatarStoragePaths.md
                }
                isStatementImage={true}
              />
              {/* <img
                src={kontomoIcon}
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 17,
                  marginRight: 13,
                }}
              /> */}
              <div style={{ fontSize: 9, marginLeft: 13 }}>
                <div style={{ marginBottom: 2 }}>
                  {stage.organizer && stage.organizer.alias.name}
                </div>
                <div style={{ marginBottom: 2 }}>
                  Star Id: {stage.starId && stage.starId}
                </div>
                <div style={{ marginBottom: 2 }}>
                  {stage.organizer && stage.organizer.address}
                </div>
                <div style={{ marginBottom: 2 }}>
                  {stage.organizer && stage.organizer.email}
                </div>
                <div>
                  {stage.stagePath && "backstage.kontomo.com" + stage.stagePath}
                </div>
              </div>
            </div>
          </div>
          <div style={{ fontSize: 14, marginBottom: 6 }}>
            {starUsername} organizing as{" "}
            {stage.organizer && stage.organizer.alias.name}
          </div>
          <div style={{ fontSize: 12, color: "#EF4E23", marginBottom: 8 }}>
            for {stage.title} at {stage.dateTimeLocation}
          </div>
          <div style={{ fontSize: 10, fontWeight: "bold", color: "#C3161C" }}>
            between {/* Use the first sale's date as lastStatementDate */}
            {sales.length !== 0
              ? new Date(sales[0].created * 1000).toLocaleDateString()
              : endDate.toLocaleDateString()}{" "}
            and {endDate.toLocaleDateString()}
          </div>
          <div style={{ fontSize: 14, marginTop: 23, marginBottom: 23 }}>
            <strong>Total payout:</strong>{" "}
            {formatter.format(
              sales.reduce(
                (acc, cv) => acc + cv.itemNet / currencyDecimalRatio,
                0
              )
            )}
          </div>
          {showStatementRangePicker &&
            !props.isRange &&
            renderStatementRangePicker()}
        </div>
        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <table
            style={{
              fontSize: 10,
              width: "100%",
              borderCollapse: "collapse",
              marginBottom: 12,
            }}
          >
            <thead>
              <tr style={{ borderBottom: "3px solid #292663" }}>
                <th style={{ textAlign: "start" }}>
                  {statementType === "sale" ? "Eventgoer ID" : "Donor Name"}
                </th>
                <th style={{ textAlign: "start" }}>
                  {statementType === "sale" ? "Payment Date" : "Donation Date"}
                </th>
                <th style={{ textAlign: "start" }}>Details</th>
                <th style={{ textAlign: "start" }}>Amount</th>
                <th style={{ textAlign: "start" }}>
                  Service Fee{statementType === "donation" ? " (6%)" : null}
                </th>
                <th style={{ textAlign: "start" }}>Net</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr style={{ borderBottom: "1px solid #ACAAC2" }}>
                <td className="statement-row">eventgoerId</td>
                <td className="statement-row">paymentDateTime</td>
                <td className="statement-row">
                  <div>paywallId</div>
                  <div>description</div>
                </td>
                <td className="statement-row">amount</td>
                <td className="statement-row">
                  <div>chargeRule</div>
                  <div>serviceCharge</div>
                </td>
                <td className="statement-row">itemNet</td>
              </tr> */}
              {sales.map((sale) => (
                <tr
                  key={sale.id}
                  id={sale.id}
                  style={{ borderBottom: "1px solid #ACAAC2" }}
                >
                  <td className='statement-row'>
                    {statementType === "sale"
                      ? sale.eventgoerId
                      : sale.displayName || "Anonymous"}
                  </td>
                  <td className='statement-row'>
                    {sale.created &&
                      moment(sale.created * 1000)
                        .utcOffset(
                          sale.timezoneOffset ? sale.timezoneOffset : -5,
                          false
                        )
                        .format("M/D/YYYY h:mm:ss (UTC ZZ)")}
                  </td>
                  {statementType === "sale" ? (
                    <td className='statement-row'>
                      <div>Paywall ID: {sale.paywallId}</div>
                      <div>Sales ID: {sale.id}</div>
                      <div>Description: {sale.description}</div>
                    </td>
                  ) : (
                    <td className='statement-row'>
                      <div>Email: {sale.email || "private"}</div>
                      <div>Donation ID: {sale.id}</div>
                    </td>
                  )}
                  <td className='statement-row'>
                    {formatter.format(sale.amount / currencyDecimalRatio)}
                  </td>
                  <td className='statement-row'>
                    <div>
                      {formatter.format(
                        (sale.amount - sale.itemNet) / currencyDecimalRatio
                      )}
                    </div>
                    {statementType === "sale" ? (
                      <div>{sale.chargeRule}</div>
                    ) : null}
                  </td>
                  <td className='statement-row'>
                    {formatter.format(sale.itemNet / currencyDecimalRatio)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ color: "#C3161C", marginBottom: 8 }}>
            Taxes are the responsibility of {starUsername} organizing as{" "}
            {stage.organizer && stage.organizer.alias.name}
          </div>
          <div style={{ fontSize: 9 }}>
            Star Id: {stage.starId && stage.starId}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatementScreen;
