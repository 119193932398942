import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "../../services/Firebase";
import BackstageScreen from "./BackstageScreen";
import actions from "./actions";

const mapStateToProps = ({ user }, { firebase }) => {
  return {
    currentUser: user,
    firebase
  };
};

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(actions, dispatch) };
};

const enhance = compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(BackstageScreen);
