import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/auth";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import * as _ from "lodash";
import ProtectedRoute from "../../components/ProtectedRoute.js";
import TrapScreen from "../Trap/TrapScreen";
import StatementScreen from "../StatementScreen/StatementScreen.js";
import StageUsageScreen from "../StageUsageScreen/StageUsageScreen.js";
import "./styles.scss";

const Stage = lazy(() => import('../Stage'));
const Auth = lazy(() => import('../AuthScreen'));
const StarProfile = lazy(() => import('../StarProfileScreen'));

class BackstageScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      loading: true,
    };
  }
  componentDidMount() {
    this.removeAuthListener = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.actions.updateUser({ uid: user.uid, email: user.email })

        this.setState({ isLoggedIn: true });
      } else {
        this.setState({ isLoggedIn: false });
      }

      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    _.isFunction(this.removeAuthListener) && this.removeAuthListener();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const userUpdated = !_.isEqual(
      this.props.currentUser,
      nextProps.currentUser
    );
    const stateChanged = this.state !== nextState;

    return stateChanged || userUpdated;
  }

  renderLoading() {
    return <TrapScreen />;
  }

  render() {
    if (this.state.loading) return this.renderLoading();

    return (
      <div className="BackstageScreen">
        <Suspense fallback={<TrapScreen />}>
          <Switch>
            <ProtectedRoute
              path="/_signup"
              component={(props) => <Auth {...props} type="signUp" />}
              canAccess={!this.state.isLoggedIn}
              errorMessage="Already logged in!"
            />

            <ProtectedRoute
              path="/signin"
              component={(props) => <Auth {...props} type="signIn" />}
              canAccess={!this.state.isLoggedIn}
              errorMessage="Already logged in!"
            />

            <ProtectedRoute
              path="/star/:id"
              component={StarProfile}
              canAccess={this.state.isLoggedIn}
            />

            <ProtectedRoute
              path="/statements/:statementType/:starId/:stageId/:startDate/:endDate"
              component={(props) => <StatementScreen {...props} isBackstage isRange mobile={this.props.mobile} currentUserId={this.props.currentUser.uid} firebase={this.props.firebase} />}
              canAccess={this.state.isLoggedIn}
            />

            <ProtectedRoute
              path="/statements/:statementType/:starId/:stageId/"
              component={(props) => <StatementScreen {...props} isBackstage mobile={this.props.mobile} currentUserId={this.props.currentUser.uid} firebase={this.props.firebase} />}
              canAccess={this.state.isLoggedIn}
            />

            <ProtectedRoute
              path="/stageusage/:stageId"
              component={(props) => <StageUsageScreen {...props} isBackstage={true} mobile={this.props.mobile} currentUserId={this.props.currentUser.uid} firebase={this.props.firebase} />}
              canAccess={this.state.isLoggedIn}
            />

            <ProtectedRoute
              path="/:stageSlug"
              component={(props) => <Stage {...props} isBackstage={true} mobile={this.props.mobile} />}
              canAccess={this.state.isLoggedIn}
            />

            <Route path="/">
              <Redirect
                to={
                  this.state.isLoggedIn
                    ? `/star/${this.props.currentUser.uid}`
                    : "/signin"
                }
              />
            </Route>
          </Switch>
        </Suspense>
      </div>
    );
  }
}

BackstageScreen.propTypes = {
  actions: PropTypes.shape({
    updateUser: PropTypes.func.isRequired,
  }),
  currentUser: PropTypes.object,
  firebase: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired
};

export default BackstageScreen;
