import firebase from "firebase/app";
import "firebase/firestore";
import autoId from "../../utils/autoId";
import moment from "moment";

class API {
  constructor(firebase) {
    this.firestore = firebase.firestore;
  }

  addMedia = (data, segmentId, stageId) => {
    const id = autoId();

    return this._getStageHallDBRef(stageId)
      .set(
        {
          media: {
            [id]: { id, ...data },
          },
          mediaSegments: {
            [segmentId]: {
              mediaIds: firebase.firestore.FieldValue.arrayUnion(id),
            },
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      )
      .then(() => console.log("Document successfully updated!"))
      .catch(_onFail("addMedia failed"));
  };

  addMediaSegment = (data, stageId) => {
    const id = `${moment(data.startAt).format()}_${moment(data.endAt).format()}`;

    return this._getStageHallDBRef(stageId)
      .set(
        {
          mediaSegments: {
            [id]: {
              ...data,
              id,
              subSegments: [],
              mediaIds: [],
            },
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      )
      .then(() => console.log("Media segment successfully added!"))
      .catch(_onFail("addMediaSegment failed"));
  };

  updateMediaSegment = (data, stageId) => {
    return this._getStageHallDBRef(stageId)
      .set(
        {
          mediaSegments: {
            [data.id]: data,
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      )
      .then(() => console.log("Media segment successfully updated!"))
      .catch(_onFail("updateMediaSegment failed"));
  };

  destroyMediaSegment = (segmentId, stageId) => {
    return this._getStageHallDBRef(stageId)
      .set(
        {
          mediaSegments: {
            [segmentId]: firebase.firestore.FieldValue.delete(),
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      )
      .then(() => console.log("Media segment successfully updated!"))
      .catch(_onFail("updateMediaSegment failed"));
  };


  recordUnmutings = (sessionId, unmuted) => {
    const firestoreRef = this.firestore
      .collection("sessions")
      .doc(sessionId);

    return firestoreRef
      .set(
        {
          unmutingHistory: firebase.firestore.FieldValue.arrayUnion({
            unmuted,
            timestamp: firebase.firestore.Timestamp.now(),
          }),
        },
        { merge: true }
      )
      .catch(_onFail("recordUnmutings() - failed"));
  };

  _getStageHallDBRef = (stageId) => {
    return this.firestore.collection("stagehalls").doc(stageId);
  };
}

const _onFail = (message) => {
  return (error) => {
    console.error(message, error);
  };
};

export default (firebase) => new API(firebase);
