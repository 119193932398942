import React from "react";
import firebase from "firebase/app";
import "firebase/storage";
import bcrypt from "bcryptjs";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import PaymentStep from "./components/PaymentStep";
import Modal from "../../../../components/Modal";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import { ThemeProvider } from "@material-ui/styles";
import Avatar from "../../../../components/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import copy from "copy-to-clipboard";
import "./styles.scss";
import emailIcon from "../../../../assets/icons/Paywall/emailIcon.png";
import checkCircleIcon from "../../../../assets/icons/Paywall/check-circle.svg";
import cancel from "../../../../assets/icons/Paywall/cancel.svg";
import closeIcon from "../../../../assets/icons/Paywall/close.svg";
import sendingMail from "../../../../assets/icons/Paywall/sending-mail.svg";
import refresh from "../../../../assets/icons/Paywall/refresh.svg";
import { event } from "firebase-functions/lib/providers/analytics";

const theme = {
  palette: {
    primary: {
      light: "#fff",
      main: "#acaac2",
      dark: "#fff",
      contrastText: "#fff",
    },
  },
};

class PassIdDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: this.props.step || 1,
      passIdSent: false,
      email: "",
      notificationsOn: true,
      saleId: "",
    };
    this.setSaleId = this.setSaleId.bind(this);
  }
  setSaleId(id) {
    this.setState({ saleId: id });
  }

  get paymentRequired() {
    return (this.props.mediaSegment || {}).paymentRequired;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ step: 1 });
    }
  }

  nextStep = () => {
    if (this.paymentRequired) {
      switch (this.state.step) {
        case 1:
          return this.setState({ step: "1.1-payment" });
        case "2.1-payment":
          return this.setState({ step: 3 });
        default:
          return this.setState({ step: this.state.step + 1 });
      }
    }

    this.setState({ step: this.state.step + 1 });
  };

  sendEmail = async () => {
    this.nextStep();

    const {
      eventgoer,
      stage,
      passIdLink,
      firebase: { firestore },
    } = this.props;

    // get sale information from firestore using saleID
    const saleDocument = firestore
      .collection("ec")
      .doc(eventgoer.stageId)
      .collection("sales")
      .doc(this.state.saleId);
    const saleInformation = await saleDocument.get();
    const saleData = saleInformation.data();
    const payWhatYouWishAmount = saleData.amount;

    //for USD only, will need to refactor for other currencies if that becomes a feature
    const payWhatYouWishAmountFormatted = (payWhatYouWishAmount / 100).toFixed(
      2
    );

    const { email, notificationsOn } = this.state;

    const eventgoersContactRef = firestore
      .collection("eventgoersContact")
      .doc(eventgoer.id);

    await eventgoersContactRef.update({
      email,
      notificationsOn: notificationsOn,
    });

    const hash = await bcrypt.hash(eventgoer.id + "unsubscribe", 8);

    let aliasUrl =
      "https://kontomo.com/wp-content/uploads/2019/12/Kontomo_logo_w_wordmark_on_transparent_bgd.png";

    if (stage.organizer && stage.organizer.alias) {
      const storage = firebase.storage();
      const ref = storage.ref(stage.organizer.alias.avatarStoragePaths.md);
      aliasUrl = await ref.getDownloadURL();
    }

    const { amount, currencySymbol, description, currencyAbv, payWhatYouWish } =
      this.props.mediaSegment.paywallData;

    const formattedOrganizerName = stage.organizer
      ? stage.organizer.alias.name.slice(0, 7).toUpperCase()
      : "";
    const formattedStageTitle = stage.title.slice(0, 10).toUpperCase();
    const truncatedOrganizerAndStageTitle = `${formattedOrganizerName}${
      formattedOrganizerName && " "
    }${formattedStageTitle}`;

    // send email
    const mailData = {
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      to: email,
      template: {
        name: "receiptTemplate",
        data: {
          privateLink: passIdLink,
          unsubscribeLink: `${window.location.origin}/unsubscribe?token=${hash}&eid=${eventgoer.id}`,
          email: email,
          stageTitle: stage.title,
          stageDateLocation: (stage.organizer && stage.organizer.address) || "",
          organizer: (stage.organizer && stage.organizer.alias.name) || "",
          organizerImageLink: aliasUrl,
          amountStr:
            currencySymbol +
            (payWhatYouWish === true ? payWhatYouWishAmountFormatted : amount) +
            ` (${currencyAbv})`,
          paywallDescription: description,
          paymentDateTime: new Date().toLocaleString(),
          truncatedOrganizerAndStageTitle,
          queriedStageUrl: passIdLink,
          stageId: eventgoer.stageId,
          eventgoerId: eventgoer.id,
          salesId: this.state.saleId,
        },
      },
    };

    return firestore
      .collection("mail")
      .doc("sales_receipt-" + Date.now())
      .set(mailData)
      .then(this.afterEmailSend);
  };

  afterEmailSend = async () => {
    this.setState({ passIdSent: true });
    await new Promise((resolve) => {
      setTimeout(() => {
        this.nextStep();
        resolve();
      }, 3000);
    });

    setTimeout(() => {
      this.redirectToLink();
    }, 3000);
  };

  handleChange = ({ currentTarget: { name, value, type, checked } }) => {
    this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  copyLink = () => {
    copy(this.props.passIdLink);
  };

  redirectToLink = () => {
    window.location.href = this.props.passIdLink;
  };

  onClose = () => {
    this.setState({ step: 1 });
    this.props.onClose();
  };

  renderStepOne() {
    if (this.paymentRequired) return this.renderStepOneWithPaywall();

    return (
      <>
        <h1>FREE with Pass ID to view LIVE</h1>

        <p className="text-ui">
          By tapping Yes!, you agree to the Kontomo{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://kontomo.com/terms-of-use/"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://kontomo.com/privacy-policy/"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Privacy Policy
          </a>
        </p>

        <div>
          <Button
            style={{ marginTop: "0.5rem" }}
            variant="contained"
            className="btn"
            onClick={this.nextStep}
          >
            Yes!
          </Button>

          <Button
            variant="contained"
            className="btn btn-tertiary"
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }

  renderStepOneWithPaywall() {
    // UX wasn't finalized at time of writing this render function,
    // at some point should probably combine with renderStepOne()

    const { amount, currencySymbol, description, payWhatYouWish } =
      this.props.mediaSegment.paywallData;
    const mobile = this.props.mobile;

    return (
      <div style={{ width: 288 }}>
        <div
          style={{ width: "auto", display: "inline-block", textAlign: "right" }}
        >
          <img
            className="close-icon"
            alt="Close Dialog"
            onClick={this.props.onClose}
            src={closeIcon}
            style={{
              position: "absolute",
              top: mobile ? 9 : 20,
              right: mobile ? 9 : 20,
              width: 32,
              height: 32,
              background: "#CBC9EA",
              borderRadius: "50%",
            }}
          />
        </div>
        <div>
          {!payWhatYouWish ? (
            <h1>{currencySymbol + amount}</h1>
          ) : (
            <h1>Pay what you wish</h1>
          )}
        </div>
        <div
          className="PassIdDialog-paywallDescription"
          style={{
            fontSize: mobile ? 19 : 22,
            lineHeight: mobile ? "22px" : "26px",
          }}
        >
          {description}
        </div>
        <hr />

        <div>
          <Button
            style={{
              width: "120px",
              marginLeft: "0.25rem",
              marginRight: "2.5rem",
              marginTop: 20,
            }}
            variant="contained"
            className="btn btn-tertiary"
            onClick={this.props.onClose}
          >
            <img src={cancel} style={{ height: 24, width: 24 }}></img>
          </Button>

          <Button
            style={{
              width: "120px",
              marginRight: "0.25rem",
              marginleft: "2.5rem",
              marginTop: 20,
            }}
            variant="contained"
            className="btn"
            onClick={() => this.setState({ step: "2.1-payment" })}
          >
            <img src={checkCircleIcon} style={{ height: 24, width: 24 }}></img>
          </Button>
        </div>
      </div>
    );
  }

  renderStepTwo() {
    return (
      <>
        <h1>Get my Pass ID link</h1>
        <form onSubmit={this.sendEmail}>
          <TextField
            className="PassIdDialog-emailField"
            color="primary"
            type="email"
            name="email"
            onChange={this.handleChange}
            value={this.state.email}
            label="Send my Pass ID to this email"
            InputLabelProps={{ className: "InputLabel" }}
            placeholder="email@address.com*"
            autoFocus
            fullWidth
          />

          <div className="text-large">
            This email is NOT shared with the organizer or artists.
          </div>

          <FormControlLabel
            color="secondary"
            className="Checkbox-label"
            type="checkbox"
            name="notificationsOn"
            onChange={this.handleChange}
            value="notificationsOn"
            control={
              <Checkbox
                className="Checkbox"
                color="primary"
                checked={this.state.notificationsOn}
              />
            }
            label="Get updates for this stage"
            labelPlacement="end"
          />

          <p className="text-large">Turn updates on/off at any time.</p>

          <Button
            variant="contained"
            className="btn"
            type="submit"
            disabled={!this.state.email.match(/\w+@\w+\.\w+/)}
          >
            Email me my Pass ID link
          </Button>
        </form>
      </>
    );
  }

  truncateEmail = (emailAddress) => {
    if (emailAddress.length > 20) {
      const lastPeriodIndex = emailAddress.lastIndexOf(".");
      let extension = emailAddress.slice(lastPeriodIndex);
      let localAndDomain = emailAddress.slice(0, lastPeriodIndex);

      if (localAndDomain.length > 15) {
        let truncatedLocalAndDomain = emailAddress.slice(0, 12) + "... ";
        return truncatedLocalAndDomain + extension;
      }

      return localAndDomain + extension;
    } else {
      return emailAddress;
    }
  };

  renderStepThree() {
    const mobile = this.props.mobile;

    return (
      <>
        <h1 style={{ marginTop: 0 }}>Sending receipt email</h1>
        <p className="text-large">
          This email is NOT shared with the organizer or artists.
        </p>
        <hr />
        <img
          src={sendingMail}
          style={{ height: 86, width: 86, marginTop: 18 }}
        />
      </>
    );
  }

  renderStepFour() {
    const { stage } = this.props;

    return (
      <>
        <div style={{ textAlign: "center", margin: "auto" }}>
          <Avatar
            imageUrl={
              stage.organizer && stage.organizer.alias.avatarStoragePaths.md
            }
          />
        </div>
        <p className="text-large">Reloading the Stage with your Pass ID</p>
        <hr className="horizontal" />
        <div className="text-large">
          <p>One moment...</p>
        </div>
        <div style={{ textAlign: "center", margin: "auto" }}>
          <img src={refresh} style={{ height: 86, width: 86 }} />
        </div>
      </>
    );
  }

  renderPaymentStepTwo = () => {
    return (
      <PaymentStep
        eventgoer={this.props.eventgoer}
        paywallData={this.props.mediaSegment.paywallData}
        onEventgoersContactChange={(data) => this.setState({ ...data })}
        onSubmit={this.sendEmail}
        mobile={this.props.mobile}
        onClose={this.props.onClose}
        setSaleId={this.setSaleId}
      />
    );
  };

  renderContents = () => {
    switch (this.state.step) {
      case 1:
        return this.renderStepOne();
      case 2:
        return this.renderStepTwo();
      case "2.1-payment":
        return this.renderPaymentStepTwo();
      case 3:
        return this.renderStepThree();
      case 4:
        return this.renderStepFour();
      default:
        throw new Error("PassIdDialog - No step!");
    }
  };

  render() {
    return (
      <ThemeProvider theme={(outerTheme) => _.merge(outerTheme, theme)}>
        <Modal
          className={`Modal PassIdDialog PassIdDialog-step${this.state.step}`}
          onRequestClose={this.onClose}
          shouldCloseOnOverlayClick={false}
          isOpen={this.props.open}
        >
          {this.renderContents()}
        </Modal>
      </ThemeProvider>
    );
  }
}

export default PassIdDialog;
