import React from "react";

import ribbon1Exit from "../../assets/images/ribbons/ribbon1Exit.svg";
import ribbon1Entry from "../../assets/images/ribbons/ribbon1Entry.svg";
import ribbon1Loop from "../../assets/images/ribbons/ribbon1Loop.svg";
import ribbon2Bendout from "../../assets/images/ribbons/ribbon2Bendout.svg";
import ribbon2Entry from "../../assets/images/ribbons/ribbon2Entry.svg";
import "./styles.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function TrapScreen() {

  let screenMetadata = {};

  if (window.location.pathname === "/404") {
    screenMetadata.headline = "404 Not Found";
    screenMetadata.subheadline = "No doors behind this curtain!";
    screenMetadata.overlay = "#C3161C";
    screenMetadata.background =
      "linear-gradient(41.06deg, #160F3E, #292663 43.57%, #432A74 79.91%, #432A74)";
  } else if (window.location.pathname === "/logout") {
    screenMetadata.headline = "Logged out!";
    screenMetadata.subheadline =
      "Your Unique Pass ID is being used in another session!";
    screenMetadata.overlay = "#160F3E";
    screenMetadata.background =
      "linear-gradient(41.06deg, #160F3E, #292663 43.57%, #432A74 79.91%, #432A74)";
  } else {
    //   Loading Screen
    screenMetadata.overlay = "transparent";
    screenMetadata.background = "160F3E";
  }

  return (
    <div
      style={{
        // If it is a loading screen put it in absolute position
        position: screenMetadata.overlay === "transparent" ? "absolute" : "relative",
        background: screenMetadata.background,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {screenMetadata.overlay === "transparent" && (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={75} style={{ zIndex: 1, color: "#fff" }} />
        </div>
      )}
      <img
        src={ribbon2Bendout}
        alt="Ribbon2 Bendout"
        style={{
          position: "absolute",
          top: -650,
          left: -530,
        }}
      />
      <img
        src={ribbon2Entry}
        alt="Ribbon2 Entry"
        style={{
          position: "absolute",
          top: 80,
          left: -150,
        }}
      />
      <img
        src={ribbon1Exit}
        alt="Ribbon1 Exit"
        style={{
          position: "absolute",
          bottom: -200,
          right: -500,
        }}
      />
      <img
        src={ribbon1Entry}
        alt="Ribbon1 Entry"
        style={{
          position: "absolute",
          bottom: 100,
          right: 150,
        }}
      />
      <img
        src={ribbon1Loop}
        alt="Ribbon1 Loop"
        style={{
          position: "absolute",
          bottom: -270,
          right: -80,
        }}
      />
      <div id="trap-headline">{screenMetadata.headline}</div>
      <div id="trap-subheadline">
        {screenMetadata.headline === "Logged out!" ? (
          <span>
            Your <strong>Unique Pass ID</strong> is being used in another
            session!
          </span>
        ) : (
          screenMetadata.subheadline
        )}
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: screenMetadata.overlay,
          mixBlendMode: "overlay",
        }}
      />
    </div>
  );
}
