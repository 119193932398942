const mobileRegex = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/
const tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i

export function isMobileOnly() {
  const { userAgent } = navigator;
  if (userAgent) {
    if (mobileRegex.test(userAgent) && !tabletRegex.test(userAgent)) {
          return true;
    }
  }
  return false;
}

export function isTabletOnly() {
  const { userAgent } = navigator;
  if (userAgent) {
    if (tabletRegex.test(userAgent) && !mobileRegex.test(userAgent)) {
        return true;
    }
  }
  return false;
}
