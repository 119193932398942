import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const prodConfig = {
  apiKey: "AIzaSyCQvUlhk6527w4j3YbavcPbP8UBqemywaQ",
  authDomain: "kontomo-v01.firebaseapp.com",
  databaseURL: "https://kontomo-v01.firebaseio.com",
  projectId: "kontomo-v01",
  storageBucket: "kontomo-v01.appspot.com",
  messagingSenderId: "601064151186",
  appId: "1:601064151186:web:be40f5dfd98c492bf22e4b",
  measurementId: "G-KHE55XYB6V",
};

const testConfig = {
  apiKey: "AIzaSyB_Isrmk-VWA7lAW3-HlelDvDLT0JLjySQ",
  authDomain: "kontomo-test-5a03f.firebaseapp.com",
  databaseURL: "https://kontomo-test-5a03f.firebaseio.com",
  projectId: "kontomo-test-5a03f",
  storageBucket: "kontomo-test-5a03f.appspot.com",
  messagingSenderId: "303028705626",
  appId: "1:303028705626:web:31a5cdc0a0cbcab7d0bb63",
  measurementId: "G-6ZPL251DPR",
};

const config =
  process.env.REACT_APP_KONTOMO_ENV === "development" ? testConfig : prodConfig;
// console.log(process.env.REACT_APP_KONTOMO_ENV)
export default class Fire {
  constructor() {
    this.init();

    this.event = "messages";
    this.firestore = firebase.firestore();
    this.database = firebase.database();
  }

  init = () => {
    if (!firebase.apps.length) {
      //avoid re-initializing
      // firebase.initializeApp({
      //   apiKey: "AIzaSyCQvUlhk6527w4j3YbavcPbP8UBqemywaQ",
      //   authDomain: "kontomo-v01.firebaseapp.com",
      //   databaseURL: "https://kontomo-v01.firebaseio.com",
      //   projectId: "kontomo-v01",
      //   storageBucket: "kontomo-v01.appspot.com",
      //   messagingSenderId: "601064151186",
      //   appId: "1:601064151186:web:be40f5dfd98c492bf22e4b",
      //   measurementId: "G-KHE55XYB6V"
      // });

      firebase.initializeApp(config);
    }
  };

  get uid() {
    return (firebase.auth().currentUser || {}).uid;
  }

  get ref() {
    return firebase.database().ref(this.event);
  }

  parse = (snapshot) => {
    const {
      timestamp: numberStamp,
      text,
      user,
      createdAt,
      richText,
      image,
    } = snapshot.val();
    const { key: _id } = snapshot;
    const timestamp = new Date(numberStamp);
    const message = {
      id: _id,
      timestamp,
      text,
      user,
      createdAt,
      richText,
      image,
    };
    return message;
  };

  parseCustom = (snapshot) => {
    return snapshot.val();
  };

  set = (event) => (this.event = event);

  on = (callback) =>
    this.ref.limitToLast(100).on("child_added", (snapshot) => {
      return callback(this.parse(snapshot));
    });

  onCustom = (callback) =>
    this.ref
      .limitToLast(100)
      .on("value", (snapshot) => callback(this.parseCustom(snapshot)));

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  get createdAt() {
    return new Date().toISOString();
  }
  // send the message to the Backend
  send = (messages) => {
    for (let i = 0; i < messages.length; i++) {
      const { text, user } = messages[i];
      const message = {
        text,
        user,
        timestamp: this.timestamp,
        createdAt: this.createdAt,
      };
      this.append(message);
    }
  };

  sendReaction = (messages) => {
    for (let i = 0; i < messages.length; i++) {
      const { text, user, image } = messages[i];
      const message = {
        text,
        user,
        timestamp: this.timestamp,
        createdAt: this.createdAt,
        image,
      };
      this.append(message);
    }
  };

  sendSection = (section, sectionLength) => {
    const { richText, text, user, image } = section;
    const newSection = {
      richText,
      text,
      image,
      user,
      timestamp: this.timestamp,
      createdAt: this.createdAt,
    };
    this.append(newSection);

    const oldpath = this.event;

    this.event = this.event.split("/")[0] + "/ord";

    this.ref.on("value", (snap) => {
      let list = snap.val() || [];

      if (list.length === sectionLength) {
        list.push(list.length);
      }

      this.event = this.event.split("/")[0] + "/ord";
      this.ref.set(list);

      return list;
    });

    this.event = oldpath;
  };

  updateSection = (section, sectionId) => {
    const { richText, text, user, image } = section;
    const newSection = {
      richText,
      text,
      image,
      user,
      timestamp: this.timestamp,
      createdAt: this.createdAt,
    };

    const oldpath = this.event;

    this.event = this.event + "/" + sectionId;

    this.ref.set(newSection);

    this.event = oldpath;
  };

  deleteSection = (sectionId, obj, length, editIdx) => {
    const oldpath = this.event;
    this.event = this.event + "/" + sectionId;

    this.ref.remove();

    this.event = this.event.split("/")[0] + "/ord";

    this.ref.on("value", (snap) => {
      let list = snap.val();

      let newList = list;

      if (length - list.length === 0) {
        newList = newList
          .filter((idx) => {
            return idx !== editIdx;
          })
          .map((idx) => {
            if (idx > editIdx) {
              return idx - 1;
            } else {
              return idx;
            }
          });
      }

      this.event = this.event.split("/")[0] + "/ord";

      this.ref.set(newList);

      this.ref.off();

      return newList;
    });

    this.event = oldpath;
  };

  sendImage = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();

    const stamp = new Date();

    const storageRef = firebase
      .storage()
      .ref()
      .child("images/" + stamp.toISOString().slice(0, 16).replace(/-/g, ""));

    const newUrl = storageRef.put(blob).then(() => {
      const test = storageRef.getDownloadURL();
      return test;
    });

    return newUrl;
  };

  sendPostChat = (message) => {
    const { text, richText, user } = message;
    const newSection = {
      text,
      richText,
      user,
      timestamp: this.timestamp,
      createdAt: this.createdAt,
    };

    const oldpath = this.event;
    this.event = this.event.split("/")[0] + "/messages";

    this.append(newSection);
    this.event = oldpath;
  };

  append = (message) => {
    this.ref.push(message);
  };

  order = (ord) => {
    const oldpath = this.event;

    this.event = this.event.split("/")[0] + "/ord";
    this.ref.set(ord);
    this.event = oldpath;
  };

  // close the connection to the Backend
  off() {
    this.ref.off("value");
    this.ref.off();
  }
}
