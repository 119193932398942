import React from 'react';
import Stage from './Stage';
import { Switch, Route } from "react-router-dom";

const StageScreen = (props) => {
  return (
    <div className="StageScreen">
      <Switch>
        <Route
          path="/:stageSlug"
          render={(routeProps) => <Stage {...routeProps} {...props} isBackstage={false} />}
        />

        <Route path="/">
          {() => {
            window.location.replace(
              window.location.origin.replace("stage", "backstage")
            );
          }}
        </Route>
      </Switch>
    </div>
  );
};

export default StageScreen;
