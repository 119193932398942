import firebase from "firebase/app";

export default class Post {
  constructor(html, provenance) {
    this.html = html;
    this.provenance = provenance;
  }

  // Getter
  get data() {

    const user = firebase.auth().currentUser;
    let uid;

    if (user != null) {
      uid = user.uid;
    }

    return {
      author: uid,
      provenance: this.provenance ? this.provenance : null,
      html: this.html,
      sharedWith: [],
      editedWith: [],
      dateCreated: firebase.firestore.Timestamp.now(),
      postHTML: null,
      incipit: null,
      keywords: [],
      thumbnailImage: null,
      discreetHTML: null,
      aliasAvatar: null,
      sponsorPost: false,
      immutablePost: false,
      hidden: false,
      conditionalHiddenPost: false,
      conditionalCoveredPost: false,
      coverColor: null,
      conditions: null,
      bkmdCount: 0,
    };
  }
}
