import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { ReactComponent as VolumeMute } from "../../../assets/icons/Stagehall/volume-mute.svg";
import { ReactComponent as VolumeMax } from "../../../assets/icons/Stagehall/volume-max.svg";

function VolumeSlider({ onChange, defaultVolume, maxVolume }) {
  // const classes = useStyles();
  const [value, setValue] = React.useState(defaultVolume);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="VolumeSlider">
      <Grid container spacing={2}>
        <Grid item className="VolumeSlider-icon-wrapper">
          <VolumeMute onClick={() => handleChange(null, 0)}/>
        </Grid>
        <Grid item xs>
          <Slider className={"VolumeSlider-slider"} value={value} onChange={handleChange} max={maxVolume} />
        </Grid>
        <Grid item className="VolumeSlider-icon-wrapper">
          <VolumeMax onClick={() => handleChange(null, maxVolume)}/>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(VolumeSlider);
