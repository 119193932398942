import React, { useState, useEffect, ReactElement } from "react";

import _ from "lodash";

import { CSVLink } from "react-csv";

import "./styles.scss";

/**
 * <description>The StageUsageScreen outputs payment and eventgoer session data for a stage. </description>
 * <p>Known issues:</p>
 * <ul>
 *  <li>Incomplete sales docs in firestore cause component to crash as it reaches undefined properties.</li>
 * </ul>
 * @component
 * @param {object} props - receives props passed in from parent component ProtectedRoute.js
 * @returns {ReactElement} 
 */

function StageUsageScreen(props) {
  const [sales, setSales] = useState([]);
  const [eventgoers, setEventgoers] = useState({});
  const [paywalls, setPaywalls] = useState({});
  const [sessions, setSessions] = useState([]);

  useEffect(
    /**
     * <h4>calls fetchData():</h4>
     * <ul>
     *  <li>Redirects to 404 if user is not a moderator. Hard-coded list of moderators.</li>
     *  <li>Gets paywalls, sale, eventgoers, and sessions from Firebase query for stage id.</li>
     * </ul>
     * @memberof StageUsageScreen
     * @function
     * @name useEffect
     */
    () => {
      const currentUserId = props.currentUserId;

      const isModerator = [
        "xH7rQW1U02cr0gvQJKUrPwIqoKk2",
        "jdEQVnSgmuQ5kusSIYwpXeaILzI3",
        "K1BTWwfzL7RXPd2AG2CNbIq2vW73",
        "3rvGqlpxJ9YaTkuXd9jX3RF7rQH3",
        "H7AQyfjy4JNRRE3oa9voMRJisJu2",
        "h8JGbX6XH9ThjYwHfufzLEPeb6i1",
        "juPjFBsWUqS8PTMcFce5N52MI2k1",
      ].includes(currentUserId);

      if (!isModerator) {
        console.log("starId on stage does not match");
        window.location.replace(window.location.origin + "/404");
      }

      document.body.style.overflow = "scroll";

      const stageIdParam = props.match.params.stageId;
      const firebase = props.firebase;

      async function fetchData() {
        let paywalls = {};

        await firebase.firestore
          .collection("ec")
          .doc(stageIdParam)
          .collection("paywalls")
          .get()
          .then(async function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              paywalls[doc.id] = doc.data();
            });

            setPaywalls(paywalls);
          });

        let salesArray = [];

        await firebase.firestore
          .collection("ec")
          .doc(stageIdParam)
          .collection("sales")
          .get()
          .then(async function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              console.log('data', doc.data())
              let sale = doc.data();
              sale.date = sale.paymentDateTime;
              sale.isSaleRow = true;
              sale.startTimestamp = sale.paymentDateTime.toDate().getTime();
              sale.tableStartTimestamp = sale.paymentDateTime
                .toDate()
                .toString();
              sale.tableStartDate = new Date(
                sale.paymentDateTime.toDate()
              ).toLocaleDateString();
              if (sale.type === "donation") {
                console.log('donation found!')
                sale.tableId = `sale: ${sale.id}` +
                "\n" +
                `donation`;
              } else {
                sale.tableId =
                  `sale: ${sale.id}` +
                  "\n" +
                  `paywall: ${sale.metadata.paywall_id}` +
                  "\n" +
                  paywalls[sale.metadata.paywall_id].description;
              }
              salesArray.push(sale);
            });

            setSales(salesArray);
          });

        let eventgoersObject = {};

        await firebase.firestore
          .collection("eventgoers")
          .where("stageId", "==", stageIdParam)
          .get()
          .then(async function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              eventgoersObject[doc.id] = doc.data();
            });

            setEventgoers(eventgoersObject);
          });

        await firebase.firestore
          .collection("sessions")
          .where("stageId", "==", stageIdParam)
          .get()
          .then(async function (querySnapshot) {
            let sessionsArray = [];

            querySnapshot.forEach(function (doc) {
              let session = doc.data();

              session.tableId = session.id;

              session.tableCookie =
                eventgoersObject[session.eventgoerId] &&
                eventgoersObject[session.eventgoerId].cookieOK &&
                "True";

              session.tableConfirmed =
                eventgoersObject[session.eventgoerId] &&
                eventgoersObject[session.eventgoerId].confirmed &&
                "True";

              const tableSessions =
                session.unmutingHistory &&
                session.unmutingHistory.map((toggle, idx) => (
                  <div>
                    {idx % 2 === 0 &&
                      toggle.unmuted &&
                      `${
                        toggle.timestamp._seconds
                          ? new Date(
                              toggle.timestamp._seconds * 1000
                            ).toString()
                          : toggle.timestamp.toDate().toString()
                      }${
                        session.unmutingHistory[idx + 1]
                          ? ` for ${fancyTimeFormat(
                              (session.unmutingHistory[idx + 1].timestamp
                                ._seconds
                                ? session.unmutingHistory[idx + 1].timestamp
                                    ._seconds
                                : Math.floor(
                                    session.unmutingHistory[idx + 1].timestamp
                                  )) -
                                (toggle.timestamp._seconds
                                  ? toggle.timestamp._seconds
                                  : Math.floor(toggle.timestamp))
                            )}`
                          : ""
                      }`}
                    {idx !== session.unmutingHistory.length - 1 &&
                      idx !== session.unmutingHistory.length - 2 && (
                        <div style={{ height: 16 }} />
                      )}
                  </div>
                ));
              session.tableSessions = tableSessions;

              const tableSessionsString =
                session.unmutingHistory &&
                session.unmutingHistory
                  .map((toggle, idx) => {
                    return idx % 2 === 0 && toggle.unmuted
                      ? `${
                          toggle.timestamp._seconds
                            ? new Date(
                                toggle.timestamp._seconds * 1000
                              ).toString()
                            : toggle.timestamp.toDate().toString()
                        }${
                          session.unmutingHistory[idx + 1]
                            ? ` for ${fancyTimeFormat(
                                (session.unmutingHistory[idx + 1].timestamp
                                  ._seconds
                                  ? session.unmutingHistory[idx + 1].timestamp
                                      ._seconds
                                  : Math.floor(
                                      session.unmutingHistory[idx + 1].timestamp
                                    )) -
                                  (toggle.timestamp._seconds
                                    ? toggle.timestamp._seconds
                                    : Math.floor(toggle.timestamp))
                              )}`
                            : ""
                        }`
                      : "";
                  })
                  .join("\n");

              session.tableSessionsString = tableSessionsString;

              if (!session.startTimestamp && !session.endTimestamp) {
                // Default tableStartTimeStamp to previous iteration

                // created and update date in the session document may be
                // delayed possibly because of cloud function lag session date-time
                // should be more accurate in the eventgoer document
                session.tableStartTimestamp = session.isSaleRow
                  ? session.paymentDateTime.toDate().toString()
                  : eventgoersObject[session.eventgoerId] &&
                    eventgoersObject[session.eventgoerId].sessions &&
                    eventgoersObject[session.eventgoerId].sessions[session.id]
                  ? eventgoersObject[session.eventgoerId] &&
                    eventgoersObject[session.eventgoerId].sessions[
                      session.id
                    ].date
                      .toDate()
                      .toString()
                  : session.date.toDate().toString();

                session.tableStartDate = session.isSaleRow
                  ? session.paymentDateTime.toDate().toLocaleDateString()
                  : eventgoersObject[session.eventgoerId] &&
                    eventgoersObject[session.eventgoerId].sessions &&
                    eventgoersObject[session.eventgoerId].sessions[session.id]
                  ? eventgoersObject[session.eventgoerId] &&
                    eventgoersObject[session.eventgoerId].sessions[
                      session.id
                    ].date
                      .toDate()
                      .toLocaleDateString()
                  : session.date.toDate().toLocaleDateString();

                session.startTimestamp = new Date(
                  session.tableStartTimestamp
                ).getTime();

                firebase.database
                  .ref(`stagehalls/${stageIdParam}/status/${session.id}`)
                  .once("value", (snapshot) => {
                    const rtdbSession = snapshot.val();

                    if (
                      rtdbSession &&
                      rtdbSession.hasOwnProperty("startTimestamp") &&
                      rtdbSession.hasOwnProperty("endTimestamp")
                    ) {
                      session.startTimestamp = rtdbSession.startTimestamp;
                      session.tableStartTimestamp = new Date(
                        rtdbSession.startTimestamp
                      ).toString();
                      session.tableStartDate = new Date(
                        rtdbSession.startTimestamp
                      ).toLocaleDateString();
                      session.endTimestamp = rtdbSession.endTimestamp;
                      session.tableEndTimestamp = new Date(
                        rtdbSession.endTimestamp
                      ).toString();
                      session.tableEndDate = new Date(
                        rtdbSession.endTimestamp
                      ).toLocaleDateString();

                      firebase.firestore
                        .collection("sessions")
                        .doc(session.id)
                        .set(
                          {
                            startTimestamp: rtdbSession.startTimestamp,
                            endTimestamp: rtdbSession.endTimestamp,
                          },
                          { merge: true }
                        );
                    }
                  });
              } else {
                session.tableStartTimestamp = new Date(
                  session.startTimestamp
                ).toString();
                session.tableStartDate = new Date(
                  session.startTimestamp
                ).toLocaleDateString();
                session.tableEndTimestamp = new Date(
                  session.endTimestamp
                ).toString();
                session.tableEndDate = new Date(
                  session.endTimestamp
                ).toLocaleDateString();
              }

              const sessionSale = salesArray.find(
                (sale) =>
                  sale.eventgoerId === session.eventgoerId &&
                  session.date > sale.paymentDateTime
              );

              if (sessionSale) session.sale = sessionSale;

              sessionsArray.push(session);
            });

            sessionsArray = [...sessionsArray, ...salesArray];

            const sortedSessions = _.flatten(
              Object.values(
                _.groupBy(
                  sessionsArray.sort((a, b) => a.date - b.date),
                  "eventgoerId"
                )
              )
            );
            setSessions(sortedSessions);
          });
      }

      fetchData();
    },
    []
  );

  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  console.log("sales", sales);

  return (
    <div
      style={{
        backgroundColor: "#FFF8EA",
        // width: "210mm",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: "270mm",
      }}
      className='stageusage'
    >
      <button
        onClick={() => {
          const sortedSessions = _.flatten(
            Object.values(
              _.groupBy(
                sessions.sort((a, b) => a.date - b.date),
                "eventgoerId"
              )
            )
          );
          setSessions(sortedSessions);
        }}
      >
        Sort by Default
      </button>
      <button
        onClick={() => {
          setSessions([...sessions].sort((a, b) => a.date - b.date));
        }}
      >
        Sort by Date
      </button>
      <button
        onClick={() => {
          setSessions(
            [...sessions].sort((a, b) => {
              return a.eventgoerId === b.eventgoerId
                ? 0
                : sales.find((sale) => sale.eventgoerId === a.eventgoerId)
                ? -1
                : 1;
            })
          );
        }}
      >
        Sort by Paid
      </button>
      <CSVLink
        data={sessions}
        headers={[
          { label: "Eventgoer ID", key: "eventgoerId" },
          { label: "Session ID", key: "id" },
          { label: "Sale?", key: "isSaleRow" },
          { label: "Start Date", key: "tableStartDate" },
          { label: "Start Timestamp", key: "startTimestamp" },
          { label: "Start Datetime", key: "tableStartTimestamp" },
          { label: "End Date", key: "tableEndDate" },
          { label: "End Datetime", key: "tableEndTimestamp" },
          { label: "End Timestamp", key: "endTimestamp" },
          { label: "Cookie?", key: "tableCookie" },
          { label: "Confirmed?", key: "tableConfirmed" },
          { label: "Active Viewing History", key: "tableSessionsString" },
        ]}
        filename={`${
          props.match.params.stageId
        } - ${new Date().toLocaleString()}.csv`}
      >
        Download CSV
      </CSVLink>
      <table style={{ borderCollapse: "collapse" }}>
        <thead style={{ borderBottom: "1px solid black" }}>
          <tr>
            <th style={{ textAlign: "start" }}>Eventgoer ID</th>
            <th style={{ textAlign: "start" }}>Session ID</th>
            <th style={{ textAlign: "start" }}>Start Date</th>
            <th style={{ textAlign: "start" }}>End Date</th>
            {/* <th style={{ textAlign: "start" }}>Paid?</th> */}
            <th style={{ textAlign: "start" }}>Cookie?</th>
            <th style={{ textAlign: "start" }}>Confirmed?</th>
            <th style={{ textAlign: "start" }}>Active Viewing History</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session, idx) => (
            <tr
              style={{
                borderBottom:
                  sessions[idx + 1] &&
                  sessions[idx + 1].eventgoerId !== session.eventgoerId &&
                  "1px solid black",
                fontWeight: session.isSaleRow && "bold",
                backgroundColor: session.isSaleRow && "#d5f3d5",
              }}
            >
              <td>
                <div>{session.isSaleRow && "SALE"}</div>
                {session.eventgoerId}
              </td>
              <td>
                {session.isSaleRow && "sale: "}
                {session.id}
                {session.metadata && session.isSaleRow && (
                  <div>{"paywall: " + session.metadata.paywall_id}</div>
                )}
                {session.metadata && session.metadata.description && session.isSaleRow && (
                  <div>{paywalls[session.metadata.paywall_id].description}</div>
                )}
              </td>
              <td>{session.tableStartTimestamp}</td>
              <td>{session.tableEndTimestamp}</td>
              <td>{session.tableCookie}</td>
              <td>{session.tableConfirmed}</td>
              <td>{session.tableSessions}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StageUsageScreen;
