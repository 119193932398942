// This should probably be combined with the PaymentStep component used elsewhere.

import React, {
  memo,
  useReducer,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { withFirebase } from "../../../../../services/Firebase";
import { connect } from "react-redux";
import { setEventfeedChannel } from "../../../../../redux/app-redux";

import MuiTextField from "@material-ui/core/TextField";
import StripeInput from "./StripeInput";
import CountrySelect from "./CountrySelect";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import NumberFormat from "react-number-format";

import autoId from "../../../../../utils/autoId";
import {
  Elements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import Promise from "promise-polyfill";
import { CircularProgress, TextField } from "@material-ui/core";

// import mastercard from "../../../../../assets/icons/Paywall/white_Mastercard.png";
// import visa from "../../../../../assets/icons/Paywall/white_Visa.png";
import paymentOptions from "../../../../../assets/icons/Paywall/only-stripe.svg";
import checkIcon from "../../../../../assets/icons/Paywall/checkIcon.png";
import closeIcon from "../../../../../assets/icons/Paywall/close.svg";
import heart from "../../../../../assets/icons/Paywall/heart.svg";
import checkCircle from "../../../../../assets/icons/Paywall/check-circle.svg";

import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { ErrorSharp } from "@material-ui/icons";
import { StyledButton } from "../../../../../components/StyledComponents/StyledButton";
import styled from "styled-components";

import { paymentTypes } from "../../../../../services/Firebase/constants";

import bcrypt from "bcryptjs";
import firebaseApp from "firebase/app";
import firebase from 'firebase';

// global themes that overrides the fixed themes in material UI
const theme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: "#CBC9EA",
      disabled: "#160F3E",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        paddingLeft: 10,
        marginBottom: 8,
      },
    },
  },
  props: {
    MuiTextField: {
      InputLabelProps: {
        className: "InputLabel",
        shrink: true,
        required: false,
      },
    },
  },
});

const PayButton = styled(StyledButton)`
  width: 100%;
  margin-left: 0;
`;

//pay what you wish input component that automatically formats currencies
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale='2'
      isNumericString
      prefix='$' //this will need to be refactored to be dynamic; for now, only USD is okay
    />
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentStep = (props) => {

  const stripe = useStripe();
  const elements = useElements();

  const [paymentSubmitting, setPaymentSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ec, setEc] = useState(null);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState("");
  const [payButtonVariety, setPayButtonVariety] = useState("tertiary");
  // const [saleId, setSaleId] = useState(null);
  // handle form errors
  const [errors, setErrors] = useState({});

  // handle user input
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      notificationsOn: true,
      publicDonation: true,
      shareEmail: true,
      name: "",
      country: "",
      email: "",
      termsAgreed: false,
    }
  );

  const mobile = props.mobile;
  // const setSaleId = props.setSaleId;

  const cardFieldsComplete = useRef({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  //should become programmatic when user can change currency
  const currency = {
    symbol: "$",
    abbreviation: "USD",
  };

  const checkFormCompletion = useCallback(() => {
    if (
      values > 2 &&
      userInput.name.length &&
      userInput.country.length &&
      userInput.email.length &&
      userInput.termsAgreed &&
      !Object.values(cardFieldsComplete.current).includes(false)
    ) {
      setPayButtonVariety("primary");
    } else {
      setPayButtonVariety("tertiary");
    }
  }, [userInput.country.length, userInput.email.length, userInput.name.length, userInput.termsAgreed, values]);

  // load ec on render
  useEffect(() => {
    props.firebase.firestore
      .collection("ec")
      .doc(props.eventgoer.stageId)
      .get()
      .then((ecSnap) => {
        setEc(ecSnap.data());
        setLoading(false);
      });
  }, [props.eventgoer.stageId, props.firebase.firestore]);

  // call on submit once submitted is set to true
  useEffect(() => {
    submitted && setTimeout(props.onSubmit, 3000);
  }, [submitted, props.onSubmit]);

  // check whether form is complete
  useEffect(() => {
    checkFormCompletion();
  }, [checkFormCompletion, userInput]);

  async function createEventgoersPayInfo() {
    const { email, name, country } = userInput;

    await props.firebase.firestore
      .collection("eventgoersPayInfo")
      .doc(props.eventgoer.id)
      .set({
        id: props.eventgoer.id,
        stageId: props.eventgoer.stageId,
        email,
        name,
        country,
      });

    return new Promise((resolve, reject) => {
      const unsubscribe = props.firebase.firestore
        .collection("eventgoersPayInfo")
        .doc(props.eventgoer.id)
        .onSnapshot((snap) => {
          const data = snap.data()
          if (data.error) {
            unsubscribe();
            reject(data.error)
          } else if (snap.data().stripeCustomerId) {
            unsubscribe();
            resolve(snap.data());
          }
        });
    });
  }

  async function createSale(eventgoersPayInfo) {
    const id = autoId(25);

    const chargeRule = "flat 6% of donation amount";
    const chargeRuleAlgo = "return (a*0.06);";

    const saleRef = props.firebase.firestore
      .collection("ec")
      .doc(eventgoersPayInfo.stageId)
      .collection("sales")
      .doc(id);
    const timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;

    /**
     * add document to sales collection
     * data will be converted into a Stripe PaymentIntent object
     */

    await saleRef.set({
      type: paymentTypes.DONATION,
      id,
      amount: userInput.donateAmount,
      currency: currency.abbreviation,
      stripeCustomerId: eventgoersPayInfo.stripeCustomerId,
      eventgoerId: eventgoersPayInfo.id,
      paywallId: `don_${props.eventgoer.stageId}`,
      displayName:
        typeof userInput.displayName === "string"
          ? userInput.displayName
          : userInput.name,
      email: userInput.shareEmail ? userInput.email : "",
      publicDonation: userInput.publicDonation,
      notificationsOn: userInput.notificationsOn,
      chargeRuleAlgo,
      chargeRule,
      timezoneOffset,
    });

    return new Promise((resolve, reject) => {
      const unsubscribe = saleRef.onSnapshot((snap) => {
        if (snap.data().paymentIntentId) {
          unsubscribe();
          // return a stripe Payment Intent
          resolve(snap.data());
        }
      });

      setTimeout(reject, 30000);
    });
  }

function confirmCardPayment (sale, eventgoersPayInfo) {
    const { country, email, name } = eventgoersPayInfo;

    const cardNumberElement = elements.getElement(CardNumberElement);

    return stripe.confirmCardPayment(sale.client_secret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          address: {
            country: country,
          },
          email: email,
          name: name,
        },
      },
    });
  }

  function handleChange(event) {
    const {
      currentTarget: { name, value, type, checked },
    } = event;
    setUserInput({ [name]: type === "checkbox" ? checked : value });
  }

  function handleCountryChange(e, country) {
    setUserInput({ country: country ? country.code : null });
  }

  function handleStripeElementChange(stripeChangeObj) {
    const { elementType, complete } = stripeChangeObj;
    cardFieldsComplete.current[elementType] = complete;
    checkFormCompletion();
  }

  function handleAmount(event) {
    const donateAmount = event.target.value;
    setValues(donateAmount);

    //must be in cents for the stripe charge object, so multiply user input by 100
    //this is also written for USD only, will need to refactor for other currency in the future
    const donateAmountInCents = Math.round(donateAmount * 100);
    setUserInput({
      donateAmount: donateAmountInCents.toString(),
    });
  }

  function updateLeaderboard(stageId, sale) {
    const donationsRef = props.firebase.firestore
      .collection("leaderboards")
      .doc(stageId)
      .collection("donations");

    donationsRef
      .doc(sale.id)
      .set({
        showName: sale.displayName.length > 0,
        name: sale.displayName,
        showAmount: sale.publicDonation,
        donationDate: sale.paymentDateTime,
        amount: sale.amount,
      })
      // .then(() => console.log("donation doc created"))
      .catch((e) => console.error(e));
  }

  function onPaymentConfirmed(
    { error, paymentIntent },
    sale,
    eventgoersPayInfo
  ) {
    if (error) {
      if (error.message) {
        setMessage(
          "There was an issue with your payment. You have not been charged. Please try again"
        );
      }

      // Delete sale created from createSale(eventgoersPayInfo);
      const saleRef = props.firebase.firestore
        .collection("ec")
        .doc(eventgoersPayInfo.stageId)
        .collection("sales")
        .doc(sale.id);
      saleRef.delete();
      // Allow for resubmitting by changing to false
      setPaymentSubmitting(false);
    } else {
      const saleRef = props.firebase.firestore
        .collection("ec")
        .doc(eventgoersPayInfo.stageId)
        .collection("sales")
        .doc(sale.id);

      saleRef.update({
        ...paymentIntent,
        id: sale.id,
        paymentSuccess: true,
        paidAmount: sale.amount,
      });
      setPaymentSubmitting(false);
      setSubmitted(true);
      sendEmail(sale.id);

      updateLeaderboard(eventgoersPayInfo.stageId, sale);
      props.setEventfeedChannel(props.donorsChannel);
    }
  }

  const sendEmail = async (saleId) => {

    const {
      eventgoer,
      stage,
      firebase: { firestore },
    } = props;

    const passIdLink = `${window.location.href}?myUniquePassID=${eventgoer.id}`

    // get sale information from firestore using saleID
    const saleDocument = firestore
      .collection("ec")
      .doc(eventgoer.stageId)
      .collection("sales")
      .doc(saleId);
    const saleInformation = await saleDocument.get();
    const saleData = saleInformation.data();
    const donateAmount = saleData.amount;

    //for USD only, will need to refactor for other currencies if that becomes a feature
    const donateAmountFormatted = (donateAmount / 100).toFixed(
      2
    );

    const { email, notificationsOn } = userInput;

    const eventgoersContactRef = firestore
      .collection("eventgoersContact")
      .doc(eventgoer.id);

    await eventgoersContactRef.update({
      email,
      notificationsOn,
    });

    const hash = await bcrypt.hash(eventgoer.id + "unsubscribe", 8);

    let aliasUrl =
      "https://kontomo.com/wp-content/uploads/2019/12/Kontomo_logo_w_wordmark_on_transparent_bgd.png";

    if (stage.organizer && stage.organizer.alias) {
      const storage = firebaseApp.storage();
      const ref = storage.ref(stage.organizer.alias.avatarStoragePaths.md);
      aliasUrl = await ref.getDownloadURL();
    }

    const formattedOrganizerName = stage.organizer
      ? stage.organizer.alias.name.slice(0, 7).toUpperCase()
      : "";
    const formattedStageTitle = stage.title.slice(0, 10).toUpperCase();
    const truncatedOrganizerAndStageTitle = `${formattedOrganizerName}${
      formattedOrganizerName && " "
    }${formattedStageTitle}`;

    // send email
    const mailData = {
  
      // timestamp: new firestore.Timestamp(),
      timestamp: firebaseApp.firestore.FieldValue.serverTimestamp(),
      to: email,
      template: {
        name: "receiptTemplate",
        data: {
          privateLink: passIdLink,
          unsubscribeLink: `${window.location.origin}/unsubscribe?token=${hash}&eid=${eventgoer.id}`,
          email: email,
          stageTitle: stage.title,
          stageDateLocation: (stage.organizer && stage.organizer.address) || "",
          organizer: (stage.organizer && stage.organizer.alias.name) || "",
          organizerImageLink: aliasUrl,
          amountStr:
            currency.symbol +
            donateAmountFormatted +
            ` (${currency.abbreviation})`,
          paywallDescription: 'Thank you for your generous donation!',
          paymentDateTime: new Date().toLocaleString(),
          truncatedOrganizerAndStageTitle,
          queriedStageUrl: passIdLink,
          stageId: eventgoer.stageId,
          eventgoerId: eventgoer.id,
          salesId: saleId,
        },
      },
    };

    return firestore
      .collection("mail")
      .doc("sales_receipt-" + Date.now())
      .set(mailData)
      .then(() => console.log('mail doc created'));
  };

  async function handleSubmit(event) {
    event.preventDefault();

    //stop form submission if pwyw value is less than $2.00
    if (values && parseFloat(values) <= 2.0) {
      setErrors({ ...errors, ...{ donateAmount: true } });
      return;
    }

    if (paymentSubmitting) return;

    // Stripe.js has not loaded yet
    if (!stripe || !elements) return;
    setPaymentSubmitting(true);

    props.onEventgoersContactChange({
      email: userInput.email,
      notificationsOn: userInput.notificationsOn,
      //maybe add stageId here so it can be stored in the document
    });

    let eventgoersPayInfo;
    try {
      eventgoersPayInfo = await createEventgoersPayInfo();
    } catch (e) {
      setMessage(
        "There was an issue with your payment. You have not been charged. Please check your payment info and try again."
      );
      setPaymentSubmitting(false);
      return;
    }

    const sale = await createSale(eventgoersPayInfo);

    // setSaleId(sale.id);

    confirmCardPayment(sale, eventgoersPayInfo)
      .then((res) => {
        onPaymentConfirmed(res, sale, eventgoersPayInfo)
      })
      .catch((e) => {
        setMessage("There was an issue with your payment. You have not been charged. Please check your payment info and try again.");
      });
  }

  const stripeOptions = {
    iconStyle: "solid",
    style: {
      base: {
        // iconColor: '#c4f0ff',
        color: "#292663",
        fontWeight: 500,
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        // fontSmoothing: 'antialiased',
        ":-webkit-autofill": { color: "#292663", borderRadius: 17 },
        "::placeholder": { color: "rgba(98, 93, 193, 1)" },
      },
      invalid: {
        // iconColor: '#ffc7ee',
        color: "#red",
      },
    },
    disabled: paymentSubmitting,
  };

  const renderForm = () => {
    return (
      <div>
        <div></div>
        {/* <form
          
          style={{ maxHeight: "100%", bottom: "4rem" }}
        > */}
        <ThemeProvider theme={theme}>
          <MuiTextField
            label='Cardholder Name'
            name='name'
            value={userInput.name || ""}
            onChange={handleChange}
            color='primary'
            fullWidth
            placeholder='Cardholder Name'
            required
            disabled={paymentSubmitting}
          />

          <MuiTextField
            label='Card number'
            name='ccnumber'
            value={userInput.ccnumber || ""}
            onChange={handleStripeElementChange}
            color='primary'
            required
            fullWidth
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                options: stripeOptions,
                component: CardNumberElement,
              },
            }}
            placeholder='Card number'
            disabled={paymentSubmitting}
          />

          <div style={{ display: "flex" }}>
            <MuiTextField
              label='Expiration'
              name='ccexp'
              value={userInput.ccexp || ""}
              onChange={handleStripeElementChange}
              color='primary'
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  options: stripeOptions,
                  component: CardExpiryElement,
                },
              }}
            />

            <div style={{ width: 40 }} />

            <MuiTextField
              label='CVC'
              name='cvc'
              value={userInput.cvc || ""}
              onChange={handleStripeElementChange}
              color='primary'
              fullWidth
              required
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  options: stripeOptions,
                  component: CardCvcElement,
                },
              }}
              placeholder='CVC'
              disabled={paymentSubmitting}
            />
          </div>

          <CountrySelect
            label='Country'
            name='country'
            value={userInput.country || ""}
            InputLabelProps={{
              shrink: true,
              className: "InputLabel",
              required: false,
            }}
            onChange={handleCountryChange}
            color='primary'
            fullWidth
            placeholder='Country'
            required
            disabled={paymentSubmitting}
          />

          <MuiTextField
            label='Email for receipt & link'
            name='email'
            type='email'
            value={userInput.email || ""}
            onChange={handleChange}
            color='primary'
            fullWidth
            placeholder='Email for receipt & Pass ID link'
            error={errors.email}
            required
            disabled={paymentSubmitting}
          />

          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              color='primary'
              className='Checkbox-label'
              type='checkbox'
              name='termsAgreed'
              control={
                <Checkbox
                  required
                  checked={userInput.termsAgreed || false}
                  onChange={handleChange}
                  disabled={paymentSubmitting}
                  className='Checkbox'
                  icon={
                    <FiberManualRecordRoundedIcon
                      style={{
                        transform: "scale(1.43)",
                        color: "#CBC9EA",
                        fontSize: 32,
                      }}
                    />
                  }
                  checkedIcon={
                    <img
                      src={checkCircle}
                      style={{
                        border: "solid 4px #fbae17",
                        borderRadius: 17,
                        background: "#fbae17",
                        height: 32,
                        width: 32,
                      }}
                      alt=''
                    />
                  }
                />
              }
              label={
                <span
                  id='termsAgreed'
                  style={{ fontSize: "14px", color: "#160f3e" }}
                >
                  I have read and agreed to the Kontomo{" "}
                  <a href='https://kontomo.com/payment-terms/' target='blank'>
                    payment terms
                  </a>
                  ,{" "}
                  <a href='https://kontomo.com/terms-of-use/' target='blank'>
                    terms of use
                  </a>{" "}
                  and{" "}
                  <a href='https://kontomo.com/privacy-policy' target='blank'>
                    privacy policy
                  </a>
                </span>
              }
              labelPlacement='end'
            />
          </div>

          <hr />

          <h1 style={{ margin: "20px 0" }}>Options</h1>

          <MuiTextField
            label='Show my name as (optional)'
            name='displayName'
            value={
              typeof userInput.displayName === "string"
                ? userInput.displayName
                : userInput.name
            }
            onChange={handleChange}
            color='primary'
            fullWidth
            placeholder='Show my name as (optional)'
            disabled={paymentSubmitting}
          />

          <FormControlLabel
            color='primary'
            className='Checkbox-label'
            type='checkbox'
            name='publicDonation'
            onChange={handleChange}
            value='publicDonation'
            control={
              <Checkbox
                className='Checkbox'
                disabled={paymentSubmitting}
                checked={userInput.publicDonation}
                icon={
                  <FiberManualRecordRoundedIcon
                    style={{
                      transform: "scale(1.43)",
                      color: "#CBC9EA",
                      fontSize: 32,
                    }}
                  />
                }
                checkedIcon={
                  <img
                    src={checkCircle}
                    style={{
                      border: "solid 4px #fbae17",
                      borderRadius: 17,
                      background: "#fbae17",
                      height: 32,
                      width: 32,
                    }}
                    alt=''
                  />
                }
              />
            }
            label={
              <span style={{ fontWeight: 500, fontSize: 16, color: "#160f3e" }}>
                Show my donation amount to the public
              </span>
            }
            labelPlacement='end'
          />
          <FormControlLabel
            color='primary'
            className='Checkbox-label'
            type='checkbox'
            name='notificationsOn'
            onChange={handleChange}
            value='notificationsOn'
            control={
              <Checkbox
                className='Checkbox'
                disabled={paymentSubmitting}
                checked={userInput.notificationsOn}
                icon={
                  <FiberManualRecordRoundedIcon
                    style={{
                      transform: "scale(1.43)",
                      color: "#CBC9EA",
                      fontSize: 32,
                    }}
                  />
                }
                checkedIcon={
                  <img
                    src={checkCircle}
                    style={{
                      border: "solid 4px #fbae17",
                      borderRadius: 17,
                      background: "#fbae17",
                      height: 32,
                      width: 32,
                    }}
                    alt=''
                  />
                }
              />
            }
            label={
              <span style={{ fontWeight: 500, fontSize: 16, color: "#160f3e" }}>
                Get email updates from this Stage (recommended)
              </span>
            }
            labelPlacement='end'
          />
          <FormControlLabel
            color='primary'
            className='Checkbox-label'
            type='checkbox'
            name='shareEmail'
            onChange={handleChange}
            value='shareEmail'
            control={
              <Checkbox
                className='Checkbox'
                disabled={paymentSubmitting}
                checked={userInput.shareEmail}
                icon={
                  <FiberManualRecordRoundedIcon
                    style={{
                      transform: "scale(1.43)",
                      color: "#CBC9EA",
                      fontSize: 32,
                    }}
                  />
                }
                checkedIcon={
                  <img
                    src={checkCircle}
                    style={{
                      border: "solid 4px #fbae17",
                      borderRadius: 17,
                      background: "#fbae17",
                      height: 32,
                      width: 32,
                    }}
                    alt=''
                  />
                }
              />
            }
            label={
              <span style={{ fontWeight: 500, fontSize: 16, color: "#160f3e" }}>
                Yes, share my email with the Organizer
              </span>
            }
            labelPlacement='end'
          />
        </ThemeProvider>

        <div></div>

        {message && <p style={{ color: "red", fontSize: 14 }}>{message}</p>}

        <PayButton
          type='submit'
          variety={paymentSubmitting ? "tertiary" : payButtonVariety}
          endIcon={
            paymentSubmitting ? (
              <CircularProgress
                thickness={6}
                size={25}
                style={{ color: "#fff" }}
              />
            ) : null
          }
        >
          {paymentSubmitting
            ? "PROCESSING"
            : values
            ? `PAY ${currency.symbol}${values} (${currency.abbreviation})`
            : "PAY"}
        </PayButton>
        {/* </form> */}
        <div></div>
      </div>
    );
  };

  if (loading) {
    return <CircularProgress color='secondary' />;
  }

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        {!paymentSubmitting && (
          <img
            alt='Close Dialog'
            onClick={props.onClose}
            src={closeIcon}
            className='close-icon'
            style={{
              marginTop: 0,
              top: mobile ? 9 : 20,
              width: 32,
              height: 32,
              background: "#CBC9EA",
              borderRadius: "50%",
            }}
          />
        )}
      </div>
      <form onSubmit={handleSubmit}>
        {!submitted && (
          <div>
            <h1 style={{ marginTop: 0, marginBottom: 24 }}>
              {`Support ${props.stage.organizer.alias.name}`}
            </h1>
            <div>
              <hr />
            </div>

            <div style={{ marginTop: 20 }}>
              <ThemeProvider theme={theme}>
                <MuiTextField
                  label='Any amount greater than $2 (USD)'
                  fullWidth
                  color='primary'
                  required
                  placeholder='Any amount > $2 (USD)'
                  name='donateAmount'
                  value={values || ""}
                  variant='filled'
                  onChange={handleAmount}
                  error={!values ? false : values <= 2.0}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    style: {
                      backgroundColor: "#FEEFD1",
                      borderRadius: "17px 17px 0 0",
                      paddingTop: "2rem",
                      color: values <= 2.0 ? "red" : "black",
                    },
                  }}
                  InputLabelProps={{
                    className: "InputLabel",
                    shrink: true,
                    required: false,
                    style: { paddingLeft: 0 },
                  }}
                  disabled={paymentSubmitting}
                />
              </ThemeProvider>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: mobile ? 5 : 10,
                marginBottom: mobile ? 5 : 10,
                marginTop: 20,
              }}
            >
              <img src={paymentOptions} style={{ marginBottom: 10 }} />
            </div>
          </div>
        )}

        {!submitted && renderForm()}
      </form>
      {submitted && (
        <div>
          <h1>Payment Successful</h1>
          <div className='text-large'>Thank you for your generous support!</div>
          <hr style={{ width: 288, marginTop: 14 }} />
          <div style={{ margin: "auto", textAlign: "center", marginTop: 28 }}>
            <img src={heart} style={{ width: 86, height: 86 }} alt='' />
          </div>
        </div>
      )}
    </div>
  );
};

const withElements = (Component) => {
  return (props) => (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  );
};

const mapStateToProps = (state) => {
  return {
    donorsChannel: state.donorsChannel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEventfeedChannel: (channel) => dispatch(setEventfeedChannel(channel)),
  };
};

export default withFirebase(
  withElements(memo(connect(mapStateToProps, mapDispatchToProps)(PaymentStep)))
);
