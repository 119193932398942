import firebase from "firebase/app";
import "firebase/firestore";

class API {
  constructor(firebase) {
    this.firestore = firebase.firestore;
  }

  getSession = (sessionId) => {
    return this.sessionRef(sessionId).get().catch(_onFail("getSession failed"));
  };

  createSession = (sessionId, eventgoerId, stageId) => {
    return this.sessionRef(sessionId)
      .set({
        id: sessionId,
        eventgoerId,
        stageId,
        isOnline: true,

        // similar to isOnline except it only gets toggled off
        // when a succeding session is created
        isLinked: true,

        date: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(_onSuccess("createSession success"))
      .catch(_onFail("createSession failed"));
  };

  updateSession = (sessionId, data) => {
    return this.sessionRef(sessionId)
      .update(data)
      .then(_onSuccess("updateSession success"))
      .catch(_onFail("updateSession failed"));
  };

  getEventgoerBySessionId = async (sessionId) => {
    const snapshot = await this.firestore
      .collection("eventgoers")
      .where("sessionId", "==", sessionId)
      .get();

    return !snapshot.empty && snapshot.docs[0];
  };

  getEventgoerById = (id) => {
    return this.firestore.collection("eventgoers").doc(id).get();
  };

  createEventgoer = (sessionId, stageId) => {
    const eventgoerRef = this.firestore.collection("eventgoers").doc(sessionId);

    const data = {
      id: sessionId, //will not match later sessions
      sessionId,
      stageId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    return eventgoerRef
      .set(data)
      .then(_onSuccess("createEventgoer success"))
      .then(() => data)
      .catch(_onFail("createEventgoer failed"));
  };

  updateEventgoer = (eventgoerId, data) => {
    return this.firestore
      .collection("eventgoers")
      .doc(eventgoerId)
      .set(data, { merge: true })
      .then(_onSuccess("updateEventgoer success"))
      .catch(_onFail("updateEventgoer failed"));
  };

  linkEventgoerSessionId = (eventgoer, sessionId) => {
    if (eventgoer.sessionId === sessionId) return;

    const batch = this.firestore.batch();
    batch.update(this.eventgoerRef(eventgoer.id), { sessionId });
    batch.update(this.sessionRef(eventgoer.sessionId), { isLinked: false });
    return batch.commit().catch(_onFail("linkEventgoerSessionId failed"));
  };

  sessionRef = (sessionId) => {
    return this.firestore.collection("sessions").doc(sessionId);
  };

  eventgoerRef = (eventgoerId) => {
    return this.firestore.collection("eventgoers").doc(eventgoerId);
  };

  getStageRefFromSlug = (slug) => {
    return this.getSlugQueryRef(slug)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const docSnapshot = querySnapshot.docs[0];
          return docSnapshot.ref.parent.parent;
        } else {
          console.error("No stage exists for this slug");
          // Redirect to 404
          window.location.replace(window.location.origin + "/404")
          return null;
        }
      });
  };

  getSlugQueryRef = (slug) => {
    return this.firestore.collectionGroup("slugs").where("id", "==", slug);
  };
}

const _onFail = (message) => {
  return (error) => {
    console.error(message, error);
  };
};

const _onSuccess = (message) => {
  return (data) => {
    console.log(message, data || "");
  };
};

export default (firebase) => new API(firebase);
