import React from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";

const DonorPost = styled.div`
  background: linear-gradient(
    224.72deg,
    rgba(255, 255, 255, 0) -15.01%,
    #fbae17 6.14%,
    #ef4e23 57.63%,
    #c3161c 90.27%,
    #432a74 111.87%
  );
  display: block;
  text-align: center;
  border: none;
  & > * {
    margin: 14px 0;
  }
  & :first-child {
    margin-top: 0;
  }
  & :last-child {
    margin-bottom: 0;
  }
`;

const StyledH1 = styled.h1`
  font-style: normal;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.004em;
  color: #FBFBFB;
`;

const BoldH1 = styled(StyledH1)`
  font-weight: 600;
`;

const BlackH1 = styled(StyledH1)`
  color: #000000;
`;

const StyledP = styled.p`
  color: #432A74;
  font-size: 14px;
`;

const DonorPosts = ({ donations }) => {
  if (donations) {
    return donations.sort((a,b) => b.donationDate.seconds - a.donationDate.seconds).map((item) => {
      const date = new Date(item.donationDate.seconds * 1000);
      const dateObj = {
        year: date.getFullYear(),
        month: date.toLocaleString('default', { month: 'long' }),
        day: date.getDate()
      }

      const formatAmount = (num) => {
        const dollars = num/100;
        const numStr = dollars.toString();
        const decimal = numStr.split('.')[1];
        return decimal ? Number(numStr).toFixed(2) : numStr;
      }

      const amount = formatAmount(item.amount)

      return (
        <div key={uuidv4()} className='EventfeedScreen-post'>
          <DonorPost className='EventfeedScreen-fr-view rounded'>
            <BoldH1>{item.showName ? item.name : "Anonymous"}</BoldH1>
            <StyledH1>donated</StyledH1>
            <BlackH1>${item.showAmount ? amount : "***"}</BlackH1>
            <StyledP>{`${dateObj.month} ${dateObj.day}, ${dateObj.year}`}</StyledP>
          </DonorPost>
        </div>
      );
    });
  } else {
    return null;
  }
};

export default DonorPosts;
