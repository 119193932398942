export const editPost = (
  Post,
  postPlainText,
  postIds,
  oldIndex,
  data,
  db,
  setData,
  setPostIds,
  channels,
  currentChannel,
  setChannels,
  channelsOrder,
  channelsIndexes,
  demoEventfeed,
  setPostPlainText,
  setPostObjects
) => {

  //   Only edit if the inputted text is different than the selected post
  if (postPlainText !== data[oldIndex].html) {
    const newPost = new Post(postPlainText, postIds[oldIndex]);

    db.collection("posts")
      .add(newPost.data)
      .then(function (docRef) {
        db.collection("posts").doc(postIds[oldIndex]).update({
          supersededBy: docRef.id,
        });

        const editedData = [
          ...data.slice(0, oldIndex),
          { ...newPost.data, id: docRef.id },
          ...data.slice(oldIndex + 1),
        ];
        setData(editedData);

        const editedPostIds = [
          ...postIds.slice(0, oldIndex),
          docRef.id,
          ...postIds.slice(oldIndex + 1),
        ];
        setPostIds(editedPostIds);

        let editedChannels = { ...channels };
        editedChannels[currentChannel] = editedPostIds;
        setChannels(editedChannels);

        const channelIndex = channelsIndexes.findIndex(
          (channel) => channel === currentChannel
        );
        const channelIndex2 = channelsIndexes[channelIndex];

        db.collection("eventfeeds")
          .doc(demoEventfeed)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              const postObjects = doc.data().eventfeedUnpub[channelIndex2]
                .posts;

              const editedPostObjects = [
                ...postObjects.slice(0, oldIndex),
                {
                  id: docRef.id,
                  hide: postObjects[oldIndex].hide ? true : false,
                  notify: postObjects[oldIndex].notify ? true : false,
                },
                ...postObjects.slice(oldIndex + 1),
              ];

              let update = {};
              update[
                `eventfeedUnpub.${channelIndex2}.posts`
              ] = editedPostObjects;
              db.collection("eventfeeds").doc(demoEventfeed).update(update);

              setPostObjects(editedPostObjects);
            }
          });

      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });

    setPostPlainText("");
  }
};
