import React, { memo } from 'react';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "./styles.scss"

const MediaSegmentForm = ({ mediaSegment, onClose, onSubmit }) => {
  const [state, setState] = React.useState({
    passIdRequired: mediaSegment.passIdRequired || false,
    startAt: mediaSegment.startAt ? moment(mediaSegment.startAt) : moment(),
    endAt: mediaSegment.endAt ? moment(mediaSegment.endAt) : moment().add(1, "hours"),
  });

  const isIncomplete = !(
    state.startAt && state.endAt
  );

  const isInvalid = () => {
    return state.endAt < state.startAt;
  }

  const onOptionsChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleDatetimeChange = (name) => {
    return (date) => {
      setState({ ...state, [name]: date });
    }
  }

  const onSaveClick = () => {
    onSubmit({
      ...mediaSegment,
      ...state,
      startAt: state.startAt.startOf('minute').toDate(),
      endAt: state.endAt.startOf('minute').toDate()
    });
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div>
        <DateTimePicker
          className="MediaSegmentForm-datePicker"
          value={state.startAt}
          onChange={handleDatetimeChange("startAt")}
          name="startAt"
          label="Start"
        />
      </div>
      <div>
        <DateTimePicker
          className="MediaSegmentForm-datePicker"
          value={state.endAt}
          onChange={handleDatetimeChange("endAt")}
          name="endAt"
          label="End"
          minDate={state.startAt}
          minDateMessage="End should be after start"
        />
      </div>

      <br/>

      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Options</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={state.passIdRequired} onChange={onOptionsChange} name="passIdRequired" />}
              label="Pass ID required"
            />
          </FormGroup>
        </FormControl>
      </div>

      <div>
        <Button
          color="primary"
          variant="contained"
          disabled={isIncomplete || isInvalid()}
          onClick={onSaveClick}
        >
          Save
          </Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>

    </MuiPickersUtilsProvider>
  );
}

MediaSegmentForm.defaultProps = {
  mediaSegment: {}
}

export default memo(MediaSegmentForm);
