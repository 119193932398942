import m from "moment";
import _ from "lodash";
import API from "./api";
import Fire from "../../services/Fire";

Fire.shared = new Fire();
// *******************************************************
// - formats stagehall data for cleaner use in Component
// - converts Firebase timestamp objects to Date objects
// - adds helper methods for accessing data and CRUD
// *******************************************************

//  !! in Windows, the schedule calculations return a floating point for timeNow 
//  which should always be a standard timestamp
//  Need to fix at some point?

class ModelStagehall {
  constructor(firebase, currentTimeOffset) {
    this.API = API(firebase);
    this.currentTimeOffset = currentTimeOffset;
  }
  modelStagehall(stagehall) {
    const segments = this._formatMediaSegments(stagehall.mediaSegments);
    const ordered = this._orderedMediaSegments(segments);
    const getActiveSegment = (timeNow) => this._getActiveSegment(ordered, timeNow);

    return {
      ...stagehall,

      // re-formatting
      mediaSegments: segments,

      // helpers
      orderedMediaSegments: ordered,
      getActiveSegment,
      getMedia: (mediaId) => stagehall.media ? stagehall.media[mediaId] : null,
      getActiveVideoData: (timeNow = Date.now()) =>
        this._getActiveVideoData(getActiveSegment(timeNow), timeNow),

      // db calls
      addMedia: this.API.addMedia,
      addMediaSegment: this.API.addMediaSegment,
      updateMediaSegment: this.API.updateMediaSegment,
      destroyMediaSegment: this.API.destroyMediaSegment,
      recordUnmutings: this.API.recordUnmutings,
    };
  }

  _calculateTime = (timeNow) => {
    // returns a closer-to-accurate version of the time given based on firebase's serverOffset API
    // return new Date(timeNow + this.currentTimeOffset);
    // the above return works as expected on Mac but is very problematic in Windows
    // we'll want to try later with another way to try to get accurate time off of the Firebase server
    return new Date();
  }

  _getActiveSegment = (orderedSegments, timeNow = Date.now()) => {
    
    if (!orderedSegments) return null;
    const activeSegment = _.findLast(orderedSegments, ({ startAt, endAt }) => {
      const start = m(startAt);
      const end = m(endAt);
      const isStart = m(this._calculateTime(timeNow)).isSame(start);

      return m(this._calculateTime(timeNow)).isBetween(start, end) || isStart;
    });
    
    if (!activeSegment) return null;

    if (_.isEmpty(activeSegment.subSegments)) {
      return activeSegment;
    } else {
      return this._getActiveSegment(activeSegment.subSegments, timeNow);
    }
  };

  _getActiveVideoData = (activeSegment, timeNow) => {
    if (!activeSegment || !activeSegment.schedule) return null;

    const { schedule, loopDuration, mediaIds } = activeSegment;
    const timeIntoLoop = this._getTimeIntoLoop(
      loopDuration,
      activeSegment.startAt,
      timeNow
    );
    const currentScheduleIndex = this._getCurrentScheduleIndex(
      schedule,
      timeIntoLoop
    );

    const activeVideo = schedule[currentScheduleIndex];
    if (!activeVideo) return;
    const { startAt, endAt, fallback } = activeVideo;
    const activeVideoIndex = _.indexOf(mediaIds, activeVideo.id);
    const nextScheduleItem =
      schedule[(currentScheduleIndex + 1) % schedule.length];
    const nextVideoIndex = this._getNextVideoIndex(
      fallback,
      mediaIds,
      activeVideoIndex,
      nextScheduleItem
    );
    const nextVideoId = mediaIds[nextVideoIndex];
    const timeIntoVideo = timeIntoLoop - startAt;
    const timeUntilNext = endAt - (startAt + timeIntoVideo);

    return {
      index: activeVideoIndex,
      video: activeVideo,
      timeIntoVideo,
      timeUntilNext,
      nextVideoIndex,
      nextVideoId,
      segment: activeSegment
    };
  };

  _getNextVideoIndex = (
    fallback,
    mediaIds,
    activeVideoIndex,
    nextScheduleItem
  ) => {
    if (fallback) {
      return _.indexOf(mediaIds, nextScheduleItem.id);
    } else {
      return (activeVideoIndex + 1) % mediaIds.length;
    }
  };

  _getCurrentScheduleIndex = (schedule, timeIntoLoop) => {
    return schedule.findIndex(({ startAt, endAt }) => {
      return timeIntoLoop >= startAt && timeIntoLoop < endAt;
    });
  };

  _getTimeIntoLoop = (loopDuration, segmentStartAt, timeNow) => {
    const currentTime = m(this._calculateTime(timeNow));
    const startAt = m(segmentStartAt);
    const diff = currentTime.diff(startAt);
    const timeFromStart = m.duration(diff).asSeconds();

    return timeFromStart % loopDuration;
  };

  _orderedMediaSegments = _.memoize((segments) => {
    return _.orderBy(segments, ["startAt"], ["asc"]);
  });

  _formatMediaSegments = (mediaSegments) => {
    const updatedSegments = _.cloneDeep(mediaSegments);

    _.keys(mediaSegments).forEach((segmentId) => {
      const updatedSegment = updatedSegments[segmentId];

      Object.assign(updatedSegment, {
        startAt: updatedSegment.startAt.toDate(),
        endAt: updatedSegment.endAt.toDate(),
        subSegments: this._formatSubSegments(updatedSegment),
        display: {
          startAt: this._toReadableDate(updatedSegment.startAt.toDate()),
          endAt: this._toReadableDate(updatedSegment.endAt.toDate()),
        },
      });
    });

    return updatedSegments;
  };

  _formatSubSegments = (parentSegment) => {
    return parentSegment.subSegments.map((subSeg) => {
      return {
        ...subSeg,
        startAt: subSeg.startAt.toDate(),
        endAt: subSeg.endAt.toDate(),
      };
    });
  };

  _toReadableDate = (date) => {
    return m(date).format("MM/D/YY - h:mm a");
  };
}

export default (stagehall, firebase, currentTimeOffset) => {
  const model = new ModelStagehall(firebase, currentTimeOffset);
  return model.modelStagehall(stagehall);
};
