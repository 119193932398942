import React, { PureComponent } from 'react';
import './styles.scss';

class Banner extends PureComponent {
  render() {
    return (
      <div className="Banner">
        {this.props.children}
      </div>
    )
  }
}

export const BannerRight = (props) => {
  return (
    <div className="BannerRight">
      {props.children}
    </div>
  )
}

export const BannerLeft = (props) => {
  return (
    <div className="BannerLeft">
      {props.children}
    </div>
  )
}

export default Banner;
