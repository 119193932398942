import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";

const ProtectedRoute = ({ component: Component, canAccess, errorMessage, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return canAccess ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                prevLocation: rest.path,
                error: errorMessage || "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
