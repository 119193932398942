import React, { useEffect, useState, memo, useCallback } from 'react';
import * as m from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { clear, schedule } from '../../utils/scheduler';
import _ from 'lodash';
import "./styles.scss";


function NowPlaying({
  stagehall,
  currentSegment,
  nextSegment,
  currentVideo,
  nextVideo,
  videoTimeRemaining,
  nextFallbackDurationMs,
  buffering,
  videoTransitioning,
  showingFallback
}) {

  const [nextTitle, setNextTitle] = useState();
  const [totalTimeRemaining, setTotalTimeRemaining] = useState();
  const [loading, setLoading] = useState(true);
  const [countdownDisplay, _setCountdownDisplay] = useState("");
  const setCountdownDisplay = useCallback(_.throttle(val => _setCountdownDisplay(val), 999), []);

  useEffect(initialize, [currentSegment, nextSegment, nextVideo, videoTimeRemaining]);
  useEffect(initCountdown, [currentVideo, totalTimeRemaining, loading]);

  useEffect(() => {
    setLoading(true);

    schedule("NowPlaying-setLoading", () => {
      setLoading(false);
    }, 1500);

    return () => clear("NowPlaying-setLoading");
  }, [currentVideo]);

  useEffect(() => {
    const isLoading = videoTransitioning || buffering || !totalTimeRemaining || countdownDisplay.includes("NaN") || countdownDisplay === "0 sec.";

    setLoading(isLoading);
  }, [totalTimeRemaining, countdownDisplay, buffering, videoTransitioning]);


  function initCountdown() {
    if (!nextVideo) return;

    const key = "NowPlaying-nextVideoTimer-" + nextVideo.id;
    let timeRemaining = totalTimeRemaining;
    function tick() {
      setCountdownDisplay(formattedTimeRemaining(timeRemaining));
      timeRemaining > 0 && timeRemaining--;
      schedule(key, tick, 1000);
    }

    tick();

    return () => clear(key);
  }

  function initialize() {
    const nextFallbackDurationSec = nextFallbackDurationMs / 1000; // seconds

    if (segmentEndsAfterCurrentVid()) {
      if (nextSegment) {
        // return first vid of next segment
        const nextSegmentVid = stagehall.getMedia(nextSegment.mediaIds[0]);

        if (nextSegmentVid) {
          setNextTitle(nextSegmentVid.title);
          setTotalTimeRemaining(secondsUntilNextSegment(nextSegment));
        }

      } else {
        setNextTitle("");
      }
    } else {
      // return next vid
      setTotalTimeRemaining(videoTimeRemaining + nextFallbackDurationSec);
      setNextTitle(nextVideo.title);
    }
  }

  function segmentEndsAfterCurrentVid() {
    if (!nextVideo || !currentSegment) {
      return true;
    }

    const nextVidDuration = m.duration(nextVideo.duration).asSeconds();
    const endOfNextVideo = m().add(videoTimeRemaining + nextVidDuration, "seconds").toDate();
    return endOfNextVideo > currentSegment.endAt;
  }

  function secondsUntilNextSegment({ startAt }) {
    return m.duration(m(startAt).diff(m())).asSeconds();
  }

  function formattedTimeRemaining(timeRemaining) {
    const minutes = Math.floor(m.duration(timeRemaining, "s").minutes());
    const seconds = m.duration(timeRemaining, "s").seconds();

    if (minutes) {
      return minutes + 1 + " min.";
    } else {
      return (seconds >= 0 ? seconds : 0) + " sec.";
    }
  }

  function render() {
    return (
      <div className="NowPlaying">
        <div className="NowPlaying-upper">
          <h2 className="NowPlaying-header bold text-large">Now showing</h2>
          {!showingFallback && currentVideo && <h2 className="NowPlaying-title bold text-medium">{currentVideo.title}</h2>}
        </div>

        {/* replace false with nextTitle to bring back this component */}
        {false && (
          <div className="NowPlaying-lower">
            <div className="NowPlaying-upNext">
              <h2 className="text-large">Up next</h2>

              {loading ?
                <CircularProgress /> :
                <span className="text-ui">in {countdownDisplay}</span>}
            </div>

            {!loading && <div className="NowPlaying-nextTitle text-medium">{nextTitle}</div>}
          </div>
        )}
      </div>
    );
  }

  if (!nextVideo) {
    return <CircularProgress />;
  } else {
    return render();
  }
}

export default memo(NowPlaying);
