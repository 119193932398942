import { reorder } from "../shared/Reorder";

export const reorderChannels = (
  channelsOrder,
  oldIndex,
  newIndex,
  setChannelsOrder,
  db,
  demoEventfeed,
  setChannelsIndexes,
  channelsIndexes
) => {

  if (newIndex <= 0) {
    console.log("Can't move to first position");
    return;
  }

  const shiftedChannels = reorder(
    [...channelsOrder],
    channelsOrder[oldIndex],
    newIndex,
    oldIndex
  );
  setChannelsOrder(shiftedChannels);

  const shiftedIndexes = reorder(
    [...channelsIndexes],
    channelsIndexes[oldIndex],
    newIndex
  );
  setChannelsIndexes(shiftedIndexes);

  const ref = db.collection("eventfeeds").doc(demoEventfeed);
  ref
    .get()
    .then(function (doc) {
      if (doc.exists) {
        const channelOrder = doc.data().eventfeedUnpub.channelOrder;
        ref.update({
          "eventfeedUnpub.channelOrder": reorder(
            channelOrder,
            channelOrder[oldIndex],
            newIndex
          ),
        });
      } else {
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
};