import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class Toggle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
    };
  }

  componentDidUpdate() {
    this.setState({ checked: this.props.checked });
  }

  onChange = (e) => {
    this.setState({ checked: e.target.checked });
    this.props.onChange(e.target.checked);
  };

  render() {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.checked}
              onChange={this.onChange}
              name={this.props.name}
              color="primary"
            />
          }
          label={this.props.label}
        />
      </FormGroup>
    );
  }
}

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

export default Toggle;
