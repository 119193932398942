import React, { useState, useEffect } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ChannelContainer = ({
  channelName,
  channelIdx,
  recentlyUpdated,
  isBackstage,
  currentChannel,
  handleOpenReorderChannel,
  handleOpenEditChannel,
  handleOpenDeleteChannel,
  isCurrentChannel,
  changeChannel
}) => {
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    if (recentlyUpdated) {
      setHighlighted(true);
      setTimeout(() => setHighlighted(false), 120000);
    } else {
      setHighlighted(false);
    }
  }, [recentlyUpdated]);

  const tabletAndUp = useMediaQuery(theme => theme.breakpoints.up('tablet'));

  return (
    <div
      className="EventfeedScreen-channel rounded"
      key={channelName + channelIdx}
      onClick={() => !isCurrentChannel && changeChannel(channelIdx)}
    >
      <div
        className={
          isCurrentChannel
            ? `EventfeedScreen-channelButton EventfeedScreen-channelButton-selected rounded`
            : `EventfeedScreen-channelButton rounded`
        }
        style={{
          border: highlighted && "3px solid #EF4E23",
          cursor: !isCurrentChannel && "pointer",
        }}
      >
        {channelName}
      </div>
      {tabletAndUp && isBackstage && currentChannel !== 0 && isCurrentChannel && (
        <>
          <button onClick={() => handleOpenReorderChannel(currentChannel)}>
            reorder
          </button>
          <button onClick={() => handleOpenEditChannel(channelName)}>
            edit
          </button>
          <button onClick={handleOpenDeleteChannel}>delete</button>
        </>
      )}
    </div>
  );
};

export default ChannelContainer;
