import moment from "moment";
import worker from "./timeoutWorker.js";
import WebWorker from "./workerSetup";

const maxTimeout = 2147483647;
const timeoutWorker = new WebWorker(worker);
let timeouts = {};

window.timeouts = timeouts;


// listener. Will call the appropriate callback when a message is received
timeoutWorker.addEventListener("message", function (e) {
  const id = e.data.id;
  const callback = (timeouts[id] || {}).callback;

  callback && callback();
});

/**
 * Creates subsegments and sets start and end using moment.duration and posting to WebWorker to run even when window is not in focus. Sets callbacks for onStart and onStop.
 * @requires moment
 * @requires timeoutWorker.js
 * @requires Worker
 * @function
 * @param {array} segments 
 * @param {function} onStart 
 * @param {function} onStop 
 */

export const scheduleSegments = (segments, onStart, onStop) => {
  segments.forEach((segment) => {
    if (segment.isInterrupted) {
      segment.subSegments.forEach((subSeg) => {
        _scheduleSegment(subSeg, onStart, onStop);
      });
    } else {
      _scheduleSegment(segment, onStart, onStop);
    }
  });
};

export const clearTimeouts = () => {
  timeouts = {};
};

const _scheduleSegment = (segment, onStart, onStop) => {
  _scheduleSegmentStart(segment, onStart);
  _scheduleSegmentEnd(segment, onStop);
};

const _scheduleSegmentStart = (segment, onStart) => {
  _clearTimeout(`start-${segment.id}`);
  _clearTimeout(`end-${segment.id}`);

  const msUntilStart = moment
    .duration(moment(segment.startAt).diff(Date.now()))
    .asMilliseconds();

  // segment expired or already active
  if (msUntilStart < 0) return;
  schedule(`start-${segment.id}`, () => onStart(segment), msUntilStart);
};

const _scheduleSegmentEnd = (segment, onStop) => {
  const msUntilEnd = moment
    .duration(moment(segment.endAt).diff(Date.now()))
    .asMilliseconds();

  // delay segment end by half a second
  // this allows another segment to start (if applicable)
  // without a temporary period of there being no current segment
  const endDelay = 500;

  // segment expired or already active
  if (msUntilEnd < 0) return;

  schedule(
    `end-${segment.id}`,
    () => onStop(segment),
    Math.min(msUntilEnd + endDelay, maxTimeout)
  );
};

const _setTimeout = (id, callback, timeout) => {
  timeoutWorker.postMessage({ id, timeout, command: "setTimeout" });
  timeouts[id] = { callback };
};

const _clearTimeout = (id) => {
  timeoutWorker.postMessage({ id, command: "clearTimeout" });
  delete timeouts[id];
};

export const schedule = _setTimeout;
export const clear = _clearTimeout;
