import bcrypt from "bcryptjs";
import truncate from "./Truncate";
import FirebaseModule from 'firebase/app';

export const publish = (
  db,
  id,
  firebase,
  currentUser,
  setPostObjects,
  postObjects,
  stage
) => {
  const eventfeedRef = db.collection("eventfeeds").doc(id);

  eventfeedRef.get().then(async function (doc) {
    if (doc.exists) {
      const unpubChannelOrder = doc.data().eventfeedUnpub.channelOrder;
      // Make blank object if it is the first time publishing
      const pubEventfeedCopy = doc.data().eventfeedPub || {};

      let now = new Date();
      let dateString = now.toString();

      let eventfeedPub = {
        channelOrder: unpubChannelOrder,
        whenPublished: FirebaseModule.firestore.FieldValue.serverTimestamp(),
        dateString,
        whoPublished: currentUser.uid,
      };

      let eventfeedUnpub = { ...doc.data().eventfeedUnpub };

      unpubChannelOrder.forEach((idx) => {
        eventfeedPub[idx] = doc.data().eventfeedUnpub[idx];
        eventfeedUnpub[idx].posts = eventfeedUnpub[idx].posts.map(
          (postObject) => {
            return { ...postObject, ...{ notify: false } };
          }
        );
      });

      let isNotify = false;

      let channels = await Promise.all(
        eventfeedPub.channelOrder.map(async (channelIndex) => {
          const name = doc.data().channelMap.channelNames[channelIndex];

          const posts = await Promise.all(
            eventfeedPub[channelIndex].posts.map(async (postObject) => {
              let newPostObject = {};
              await db
                .collection("posts")
                .doc(postObject.id)
                .get()
                .then(async (doc) => {
                  newPostObject = doc.data();

                  // Add id to postObject data sent in email
                  newPostObject.id = postObject.id;

                  const VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                  const videoMatches = newPostObject.html.match(VID_REGEX);

                  if (videoMatches) {
                    newPostObject.imageUrl =
                      "https://img.youtube.com/vi/" +
                      videoMatches[1] +
                      "/0.jpg";
                  }

                  newPostObject.plainText = new DOMParser().parseFromString(
                    newPostObject.html,
                    "text/html"
                  ).documentElement.textContent;

                  let truncateLength = 150;
                  let extraSpaceLength = newPostObject.html
                    .substr(truncateLength)
                    .indexOf(" ");
                  // if there is no space after, add 0
                  if (extraSpaceLength === -1) extraSpaceLength = 0;

                  newPostObject.html = truncate(
                    newPostObject.html,
                    truncateLength + extraSpaceLength,
                    { keepImageTag: true }
                  );

                });

              if (postObject.notify) {
                newPostObject.notify = true;
                isNotify = true;
              }

              return newPostObject;
            })
          );

          const notifyPosts = posts.filter((post) => post.notify);

          return { name, channelIndex, posts: notifyPosts };
        })
      );

      // Remove any empty channels
      channels = channels.filter((channel) => channel.posts.length > 0);

      setPostObjects(
        postObjects.map((postObject) => {
          return { ...postObject, ...{ notify: false } };
        })
      );

      // Publish to eventfeed.eventfeedPub
      eventfeedRef.update({ eventfeedPub, eventfeedUnpub });
      // Publish currenty published eventfeed to pubHistory
      eventfeedRef.collection("pubHistory").doc(dateString).set(pubEventfeedCopy);

      // if there is nothing to notify, do not email
      if (!isNotify) return;
      // email routine
      const eventgoersByStageRef = db.collection("eventgoersByStage").doc(id);

      eventgoersByStageRef.get().then((doc) => {
        if (doc.exists) {
          const eventgoerIds = doc.data().eventgoerIds;
          let sentEmails = [];

          eventgoerIds.forEach((eventgoerId) => {
            const eventgoerContactRef = db
              .collection("eventgoersContact")
              .doc(eventgoerId);

            eventgoerContactRef.get().then((doc) => {
              const eventgoerDoc = doc.data();

              if (eventgoerDoc.email && eventgoerDoc.notificationsOn) {
                // If this is a duplicate email address do not send
                if (sentEmails.includes(eventgoerDoc.email)) return;
                sentEmails.push(eventgoerDoc.email);

                // Email routine
                const stageRef = db.collection("stages").doc(id);

                stageRef.get().then(async (doc) => {
                  if (doc.exists) {
                    const mailRef = db.collection("mail");

                    const hash = await bcrypt.hash(
                      eventgoerId + "unsubscribe",
                      8
                    );

                    let aliasUrl =
                      "https://kontomo.com/wp-content/uploads/2019/12/Kontomo_logo_w_wordmark_on_transparent_bgd.png";

                    if (stage.organizer && stage.organizer.alias) {
                      const storage = FirebaseModule.storage();
                      const ref = storage.ref(
                        stage.organizer.alias.avatarStoragePaths.md
                      );
                      aliasUrl = await ref.getDownloadURL();
                    }

                    const mailData = {
                      timestamp: FirebaseModule.firestore.FieldValue.serverTimestamp(),

                      to: eventgoerDoc.email,
                      template: {
                        name: "notifyPost",
                        data: {
                          privateLink:
                            window.location.href.replace("backstage", "stage") +
                            "?myUniquePassID=" +
                            eventgoerId,
                          unsubscribeLink: `${window.location.origin.replace("backstage", "stage")}/unsubscribe?token=${hash}&eid=${eventgoerId}`,
                          channels,
                          stageTitle: stage.title,
                          stageDateLocation:
                            stage.dateTimeLocation || "",
                          organizer:
                            (stage.organizer && stage.organizer.alias.name) ||
                            "",
                          organizerImageLink: aliasUrl,
                        },
                      },
                    };

                    // send an email
                    mailRef.doc("notify_eventgoer-" + Date.now()).set(mailData);
                  } else {
                    console.log("No such document!");
                  }
                });
              }
            });
          });
        } else {
          console.log("No such document!");
        }
      });
    } else {
      console.log("No such document!");
    }
  });
};
