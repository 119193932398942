import { ReactElement } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "../../services/Firebase";
import Stagehall from "./Stagehall";
import stagehallActions from "./actions";
import modelStagehall from "./model";

const mapStateToProps = (state, { firebase }) => {
  const {
    eventgoer,
    eventgoersContact,
    stagehall,
    currentStage,
    currentTimeOffset,
    sales,
    donationsOn,
    preSalesEnabled
  } = state;

  return {
    stage: currentStage,
    stagehall: stagehall && modelStagehall(stagehall, firebase, currentTimeOffset),
    eventgoer,
    sales,
    donationsOn,
    preSalesEnabled,
    eventgoersContact,
    currentTimeOffset,
    stagehallRef: firebase.firestore
      .collection("stagehalls")
      .doc(currentStage.id),
    eventgoerCountRef: firebase.database.ref(
      `stagehalls/${currentStage.id}/activeSessionCount`
    ),
    eventgoerStatusRef: firebase.database.ref(
      `stagehalls/${currentStage.id}/status`
    ),
    eventgoersContactRef:
      eventgoer &&
      firebase.firestore.collection("eventgoersContact").doc(eventgoer.id),
    mailRef: firebase.firestore.collection("mail"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(stagehallActions, dispatch) };
};

/**
 * <description><b>Example pattern</b></description>
 * <p>Wrapper pattern for providing component with Redux store and Firebase API.</p>
 * @function
 * @param {function} mapStateToProps
 * @param {function} mapDispatchToProps 
 * @param {function} withFirebase
 * @returns {ReactElement} Stagehall
 */

const enhance = compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Stagehall);
