export default function () {
  const timers = {};

  function fireTimeout(id) {
    this.postMessage({ id });
    delete timers[id];
  }

  this.addEventListener("message", function (e) {
    const data = e.data;

    let timer;
    switch (data.command) {
      case "setTimeout":
        const time = parseInt(data.timeout);
        timer = setTimeout(() => fireTimeout(data.id), time);
        timers[data.id] = timer;
        break;
      case "clearTimeout":
        timer = timers[data.id];
        if (timer) clearTimeout(timer);
        delete timers[data.id];
        break;
      default:
        break;
    }
  });
}
