import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";

const prodConfig = {
  apiKey: "AIzaSyCQvUlhk6527w4j3YbavcPbP8UBqemywaQ",
  authDomain: "kontomo-v01.firebaseapp.com",
  databaseURL: "https://kontomo-v01.firebaseio.com",
  projectId: "kontomo-v01",
  storageBucket: "kontomo-v01.appspot.com",
  messagingSenderId: "601064151186",
  appId: "1:601064151186:web:be40f5dfd98c492bf22e4b",
  measurementId: "G-KHE55XYB6V",
};

const testConfig = {
  apiKey: "AIzaSyB_Isrmk-VWA7lAW3-HlelDvDLT0JLjySQ",
  authDomain: "kontomo-test-5a03f.firebaseapp.com",
  databaseURL: "https://kontomo-test-5a03f.firebaseio.com",
  projectId: "kontomo-test-5a03f",
  storageBucket: "kontomo-test-5a03f.appspot.com",
  messagingSenderId: "303028705626",
  appId: "1:303028705626:web:31a5cdc0a0cbcab7d0bb63",
  measurementId: "G-6ZPL251DPR"
};

const config = process.env.REACT_APP_KONTOMO_ENV === "development" ? testConfig : prodConfig;
class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      // ^ temporary length check until all references to Fire.js point here
      firebase.initializeApp(config);
    }

    const perf = firebase.performance();

    this.database = firebase.database();
    this.firestore = firebase.firestore();
    this.storage = firebase.storage();
  }
}

export default Firebase;
