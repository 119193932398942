import React, { Component } from 'react';
import './MobileMenu.scss';

import { ReactComponent as EventFeedSelected } from '../../../../assets/icons/Stage/eventfeed-selected.svg';
import { ReactComponent as EventFeedUnselected } from '../../../../assets/icons/Stage/eventfeed-unselected.svg';
import { ReactComponent as StageHallSelected } from '../../../../assets/icons/Stage/stagehall-selected.svg';
import { ReactComponent as StageHallUnselected } from '../../../../assets/icons/Stage/stagehall-unselected.svg';
import { ReactComponent as StageHallDisabled } from '../../../../assets/icons/Stage/stagehall-disabled.svg';

class MobileMenu extends Component {
  state = {
    eventFeedSelected: true,
    stageHallSelected: false,
  };

  clickFeedIcon = () => {
    if (this.state.eventFeedSelected === false) {
      this.props.handleIconClick();
      this.setState({
        eventFeedSelected: true,
        stageHallSelected: false,
      });
    }
  };

  clickStageIcon = () => {
    if (this.state.stageHallSelected === false) {
      this.props.handleIconClick();
      this.setState({
        eventFeedSelected: false,
        stageHallSelected: true,
      });
    }
  };
  render() {
    return (
      <div className="mobile-menu">
        {this.state.eventFeedSelected ? (
          <EventFeedSelected onClick={this.clickFeedIcon} />
        ) : (
          <EventFeedUnselected onClick={this.clickFeedIcon} />
        )}
        {this.props.shouldShowIOSWarning ? (
          <StageHallDisabled />
        ) : this.state.stageHallSelected ? (
          <StageHallSelected onClick={this.clickStageIcon} />
        ) : (
          <StageHallUnselected onClick={this.clickStageIcon} />
        )}
      </div>
    );
  }
}

export default MobileMenu;
