import React, { useState } from "react";
import { StyledButton } from "../../../components/StyledComponents/StyledButton";
import { ReactComponent as PeopleViewingIcon } from "../../../assets/icons/Stagehall/peopleViewingIcon.svg";
import { ReactComponent as CheerIcon } from "../../../assets/icons/cheer.svg";
import { ReactComponent as TicketIcon } from "../../../assets/icons/ticket.svg";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";

import { ShowsButton } from "./PresaleModal/PresaleModal";
import styled from "styled-components";
import variables from "../../../assets/styles/variables";
import DonationDialog from "./DonationDialog/DonationDialog";
import Modal from "../../../components/Modal";

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: calc(
    (70vh - ${variables.bannerHeight} - ${variables.stageHeaderHeight}) * 1.77
  );

  & .MuiFormControlLabel-label-50,
  .MuiFormControlLabel-label-70 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.004em;
    -webkit-font-smoothing: antialiased;
  }
`;

const StyledCounter = styled.h4`
  display: flex;
  align-items: center;
`;

const DonateButton = styled(StyledButton)`
  margin-right: 0;
`;

const ToggleModal = styled(Modal)`
  width: 316px;
  top: 50%;
  left: 50%;
  padding: 0;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* right: auto;
    bottom: auto; */
  /* marginRight: -50%; */
  /* transform: "translate(-50%, -50%)", */
`;

const StyledH1 = styled.h1`
  font-weight: 600;
  color: #432a74;
  margin: 20px 14px;
`;

const StyledP = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #160f3e;
  line-height: 140%;
  margin: 24px;
`;

const ConfirmButton = styled(StyledButton)`
  margin: 14px auto;
`;

const StyledLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label-50,
  .MuiFormControlLabel-label-70 {
    color: ${(props) => (props.warning === 1 ? "#a1a1a1" : "inherit")};
  }
`;

const PlayerHeader = ({
  eventgoerCount,
  mobile,
  mediaSegment,
  eventgoer,
  organizer,
  isBackstage,
  donationsOn,
  firebase,
  stageId,
  stage,
  stagehall,
  handlePreSalesEnabled,
}) => {
  const [donateModalOpen, setDonateModalOpen] = useState(false);
  const [toggleModalOpen, setToggleModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("presales");

  const handleDonateModal = () => {
    setDonateModalOpen(!donateModalOpen);
  };

  const handleToggleModal = () => {
    setToggleModalOpen(!toggleModalOpen);
  };

  const updatePreSales = () => {
    const { preSalesEnabled } = stage;

    const stageRef = firebase.firestore.collection("stages").doc(stageId);

    return stageRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          stageRef.update({
            preSalesEnabled: !preSalesEnabled,
          });
        } else {
          stageRef.set({
            preSalesEnabled: !preSalesEnabled,
          });
        }
      })
      .then(() => {
        handlePreSalesEnabled();
      })
      .catch((e) => console.error(e));
  };

  const updateDonationsOn = () => {
    const docRef = firebase.firestore.collection("leaderboards").doc(stageId);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          docRef.update({
            donationsOn: !donationsOn,
          });
        } else {
          docRef.set({
            donationsOn: !donationsOn,
          });
        }
      })
      // .then(() => console.log("success donationsOn"))
      .catch((e) => console.error(e));
  };

  const handleToggle = (event) => {
    event.persist();
    switch (event.target.name) {
      case "donations-switch":
        if (!donationsOn && !stage.organizer) {
          setModalContent("donations");
          handleToggleModal();
        } else {
          updateDonationsOn();
        }
        break;
      case "presale-switch":
        if (stage.preSalesEnabled) {
          updatePreSales();
        } else {
          setModalContent("presales");
          handleToggleModal();
        }
        break;
      default:
        return;
    }
    event.target.blur();
  };

  const handleAcceptPresales = async () => {
    await updatePreSales();
    handleToggleModal();
  };

  const handleAcknowledgeWarning = () => {
    handleToggleModal();
  };

  const renderPreSalesAlert = () => (
    <div>
      <StyledH1>Pre-show sales</StyledH1>
      <StyledP>
        Toggling on pre-show sales enables the SHOWS button in the public-facing
        Stage. The button can be toggled off at any time.
        <br />
        <br />
        Only paywalled shows will be shown in the schedule and made available
        for advance purchase.
      </StyledP>
      <ConfirmButton variety='primary' onClick={handleAcceptPresales}>
        I understand
      </ConfirmButton>
    </div>
  );

  const renderPreSalesWarning = () => (
    <div>
      <StyledH1>Who gets the check?</StyledH1>
      <StyledP>
        You are seeing this message because enabling pre-show sales requires an
        Organizer Alias to be set for this Stage. Please return to the Account
        area and specify an Organizer for this Stage.
        <br />
        <br />
        When enabled, a SHOWS button will appear on the public facing Stage.
        The SHOWS button can be toggled off at any time.
      </StyledP>
      <ConfirmButton variety='primary' onClick={handleAcknowledgeWarning}>
        I understand
      </ConfirmButton>
    </div>
  );

  const renderDonationsWarning = () => (
    <div>
      <StyledH1>Who gets the check?</StyledH1>
      <StyledP>
        You are seeing this message because donations require an Organizer Alias
        to be set for this Stage. Please return to the Account area and specify
        an Organizer for this Stage.
        <br />
        <br />
        When enabled, a DONATE button will appear on the public facing Stage.
        The DONATE button can be toggled off at any time.
      </StyledP>
      <ConfirmButton variety='primary' onClick={handleAcknowledgeWarning}>
        I understand
      </ConfirmButton>
    </div>
  );

  return (
    <FlexDiv>
      {!isBackstage && stage.organizer && stage.preSalesEnabled ? (
        <ShowsButton
          firebase={firebase}
          eventgoer={eventgoer}
          stage={stage}
          stagehall={stagehall}
          mobile={mobile}
        />
      ) : isBackstage ? (
        <StyledLabel
          control={
            <Switch
              checked={stage.preSalesEnabled}
              onChange={handleToggle}
              name='presale-switch'
            />
          }
          label='Pre-show sales on/off'
          warning={!stage.organizer ? 1 : 0}
        />
      ) : (
        <div style={{ width: "158.1px", height: "64px" }} />
      )}
      <StyledCounter className='Stagehall_eventgoerCount'>
        {`${eventgoerCount} `}
        <PeopleViewingIcon style={{ height: 12 }} />
      </StyledCounter>
      {!isBackstage && stage.organizer && donationsOn ? (
        <DonateButton
          variety='special'
          endIcon={<CheerIcon>donate</CheerIcon>}
          onClick={handleDonateModal}
        >
          Donate
        </DonateButton>
      ) : isBackstage ? (
        <StyledLabel
          control={
            <Switch
              checked={donationsOn}
              onChange={handleToggle}
              name='donations-switch'
            />
          }
          label='Donation on/off'
          warning={!stage.organizer ? 1 : 0}
        />
      ) : (
        <div style={{ width: "165.4px", height: "64px" }} />
      )}
      {donateModalOpen ? (
        <DonationDialog
          handleClose={handleDonateModal}
          open={donateModalOpen}
          eventgoer={eventgoer}
          mobile={mobile}
          onClose={handleDonateModal}
          organizer={organizer}
          stage={stage}
        />
      ) : null}
      <ToggleModal isOpen={toggleModalOpen} shouldCloseOnOverlayClick={false}>
        {modalContent === "presales"
          ? (stage.organizer ? renderPreSalesAlert() : renderPreSalesWarning())
          : renderDonationsWarning()}
      </ToggleModal>
    </FlexDiv>
  );
};

export default PlayerHeader;
