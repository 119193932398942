import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Avatar from "../../../../components/Avatar";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/Stage/left-arrow.svg"
import { ReactComponent as RightArrow } from "../../../../assets/icons/Stage/right-arrow.svg";
import cx from "classnames";
import _ from 'lodash';
import "./styles.scss"


class AliasSwitcher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
    }
  }

  get aliases() {
    const aliases = _.toArray(this.props.aliases);
    if (this.props.isOwner && !!this.props.organizer) {
      aliases.unshift(this.props.organizer.alias);
    }
    return aliases;
  }

  calculateIndex(del) {
    const newIndex = this.state.currentIndex + del;
    if (newIndex < 0) {
      return this.aliases.length - 1;
    } else {
      return newIndex % this.aliases.length;
    }
  }

  onArrowClick = (inc) => {
    const nextIdx = this.calculateIndex(inc);
    this.setState({ currentIndex: nextIdx })
    this.props.onChange(this.aliases[nextIdx]);
  }

  renderAliases = () => {
    const currentAlias = this.aliases[this.state.currentIndex];

    return this.aliases.map((alias, idx) => {
      const imageUrl = alias.avatarStoragePaths && alias.avatarStoragePaths.md;

      return (
        <div
          className={cx("AliasSwitcher-alias", {
          "AliasSwitcher-alias-active": alias === currentAlias
          })}
          key={idx}>
          <Avatar imageUrl={imageUrl} />
          <span className="AliasSwitcher-name text-large">{alias.name}</span>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="AliasSwitcher">
        <LeftArrow onClick={() => this.onArrowClick(-1)} />
        {this.renderAliases()}
        <RightArrow onClick={() => this.onArrowClick(1)} />
      </div>
    )
  }
};

AliasSwitcher.propTypes = {
  isOwner: PropTypes.bool,
  aliases: PropTypes.object.isRequired,
  organizer: PropTypes.object,
  onChange: PropTypes.func.isRequired,

}

export default AliasSwitcher;
