import React from 'react';
import { Helmet } from "react-helmet-async";

export default function HelmetHeaders({ stage }) {
  return (
    <Helmet>
      <title>{stage.title}</title>
      <meta property="og:site_name" content={stage.organizer ? `${stage.organizer.alias.name} presents` : "Powered by Kontomo"} />
      <meta property="og:title" content={stage.title} />
      <meta property="og:description" content={stage.description ? stage.description : "Kontomo is an interactive playbill platform that lets artists and organizers deliver deeper emotional value to their audiences. Feel the context that enhances the art!"} />
      <meta property="og:image" content={stage.fallbackImageUrl} />
      <meta property="og:url" content={"https://stage.kontomo.com" + stage.stagePath} />
      <meta property="og:type" content="article" />
      <meta property="fb:app_id" content="3803251956431962" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}
